/**
 * Component for KiwiSaver Section in HomePage and Invest Page 
 */

/**
 * Import dependencies 
 */
import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares';
import '../css/Invest.css';

class KiwiSaver extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    _goToKiwiSaver(event) {
        smartshares._openExternalLink(event.target.parentElement.href, event.target.parentElement.target)
    }

    render() {
        // For Local-Server
        if (!String(this.props.history.location.hash) && String(this.props.history.location.pathname) !== '/invest-now')
            return (
                <div className="space-bottom">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="hero-component hero-component--slideshow slideshow ss480 slice smartkiwi">
                                    <div className="slide" style={{ backgroundImage: "url(/images/slide-bg-3.jpg" }}>
                                        <div className="slide-slice"></div>
                                        <Container>
                                            <Row>
                                                <Col sm="5" md="5" lg="4" className="col-sm-push-1 col-md-push-1 col-lg-push-1">
                                                    <h1 className="slide__heading slide__heading--large">
                                                        <b>
                                                            <span className="brand-title-component">
                                                                <span className="brand-title-component--thin white">KIWISAVER</span>
                                                            </span>
                                                        </b>
                                                    </h1>
                                                    <h4 className="slide__heading">Choose a low fees KiwiSaver provider that will work for you today, and in the future.</h4>
                                                    <p>Smartshares is the manager of the SuperLife KiwiSaver Scheme, a low fees KiwiSaver provider with over 40 investment funds to choose from. Fund options include an extensive range of Smartshares ETFs. Smartshares, through the SuperLife KiwiSaver Scheme, is proud to be one of six government appointed Default KiwiSaver Providers.</p>
                                                    <p>
                                                        <a href="https://www.superlife.co.nz/kiwisaver-home/about-kiwisaver">
                                                            <Button className="btn dark s206-25">
                                                                <span>Find out more</span>
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        else
            return (
                <div className="hero-component hero-component--inline">
                    <div className="partners slide slide-dark" style={{ backgroundImage: "url('../images/slide-bg-3.jpg')" }}>
                        <Container>
                            <Row>
                                <Col md="8" className="col-md-push-2">
                                    <ScrollTo name="#kiwisaver" className="anchor has-sub-nav"></ScrollTo>
                                    <div className="block">
                                        <h2 className="slide__heading slide__heading--large slide__heading --blue">KIWISAVER</h2>
                                        <div>
                                            <p className="light-blue"><b>Invest in Smartshares ETFs through our SuperLife KiwiSaver Scheme</b></p>
                                            <p>SuperLife lets you choose from an extensive range of Smartshares ETFs as investment options for your KiwiSaver savings.</p>
                                            <p>Wholly owned by NZX, SuperLife is a specialist New Zealand provider of KiwiSaver, superannuation and insurance to individuals and businesses.</p>
                                            <p>To obtain a product disclosure statement for the SuperLife KiwiSaver scheme and to learn more about how you can access the full suite of Smartshares ETFs with your KiwiSaver savings, please visit the SuperLife website.</p>
                                            <p>&nbsp;</p>
                                            <p><a href="https://superlife.co.nz/kiwisaver" target="_blank">
                                                <Button className="btn large onlight invest">
                                                    Learn More
                                                </Button>
                                            </a></p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )
    }
}

export default KiwiSaver;