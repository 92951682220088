/**
 * Component for Product Disclosure Statements 
 * documents section in Legal Documents Page.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

class Product_Disclosure_Statements extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    };

    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#product-disclosure-statements" className="anchor has-sub-nav"></ScrollTo>
                    <h2 className="heading light-blue upper">
                        Product disclosure
                        <br></br>
                        statements
                    </h2>
                    <hr></hr>
                    <div id="PDS" className="link-wrapper">
                        {/* <p>
                            <Link to="document-library/product-disclosure/financial-advice-provider-disclosure-statement.pdf" target="_blank" className="pdf">
                                Financial Advice Provider Disclosure Statement
                                <span>(111kb)</span>
                            </Link>
                        </p>
                        <p>
                            <Link to="document-library/product-disclosure/complaints-disclosure-statement.pdf" target="_blank" className="pdf">
                                Complaints Disclosure Statement
                                <span>(134kb)</span>
                            </Link>
                        </p> */}
                        <p>
                            <Link to="document-library/product-disclosure/smartshares-exchange-traded-funds-core-series-product-disclosure-statement1.pdf" target="_blank" className="pdf">
                                Product Disclosure Statement - Smartshares Exchange Traded Funds - Core Series
                                <span>(609kb)</span>
                            </Link>
                        </p>
                        <p>
                            <Link to="document-library/product-disclosure/smartshares-exchange-traded-funds-specialist-series-product-disclosure-statement1.pdf" target="_blank" className="pdf">
                                Product Disclosure Statement - Smartshares Exchange Traded Funds - Specialist Series
                                <span>(1257kb)</span>
                            </Link>
                        </p>
                        <p>
                            <Link to="document-library/product-disclosure/nz-core-equity-trust-product-disclosure-statement2.pdf" target="_blank" className="pdf">
                                Product Disclosure Statement -  NZ Core Equity Trust
                                <span>(407kb)</span>
                            </Link>
                        </p>
                    </div>
            </div>
        )
    }
}

export default Product_Disclosure_Statements;