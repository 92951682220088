/**
 * Main Component consisting the Smartshares Components
 */
import React, {Component} from 'react';
import Footer from './Footer';
import Header from './Header';
import Smartshares from './Smartshares';
import ErrorBoundary from './ErrorBoundary'
import ChatBot from './ChatBot';
import Banner from './Banner';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false}
        new String(props.history.location.pathname) == "/" && localStorage.clear();
    }


    render() {
        return (
            <div>
                <Header {...this.props}></Header>
                <Banner {...this.props}></Banner>
                <Smartshares {...this.props}></Smartshares>
                <Footer {...this.props}></Footer>
                <ChatBot></ChatBot>
            </div>
        )
    }

}

export default Main;