import moment from 'moment';
import * as actions from '../Redux/action';

export function _openExternalLink(link, target) {
    const windowOrigin = window.location.origin;
    window.open(String(link).replace((windowOrigin+"/"), ""), target);
}

export function _setLabelPrefixSuffix(props) {
    let labelPrefix = 'Smartshares';
    let labelSuffix = 'ETF';

    if(moment(props.userSelectedYear).isBefore(moment('2019')) || 
        (moment(props.userSelectedYear).isSame(moment('2019')) && 
            Number(props.userSelectedQuarter) === 1)) {
        labelPrefix = '';
        labelSuffix = 'Fund';
    }

    return {
        labelPrefix,
        labelSuffix
    }
}

export function _setEtfCode(etfCode) {
    localStorage.setItem('etfCode', String(etfCode).toLowerCase());
    actions.setEtfCode(String(etfCode).toLowerCase())
}