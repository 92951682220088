/**App.js is the container for all the components */

/**Import dependencies */
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

/**Import Redux actions to bind with the components*/
import * as actions from '../Redux/action';

/**Import Main*/
import Main from './Main';

function mapStatetoProps(state){
    return{
        pages: state.getPagesReducer.pages,

        images: state.getImagesReducer.images,

        windowWidth: state.windowPropertiesReducer.windowWidth,
        
        userSelectedYear: !state.getUserSelectionsReducer.selectedYear ? "2021" : state.getUserSelectionsReducer.selectedYear,  //GTA-70266
        
        userSelectedQuarter: !state.getUserSelectionsReducer.selectedQuarter ? "1" : state.getUserSelectionsReducer.selectedQuarter, 
        
        documentsDate: state.getUserSelectionsReducer.documentsDate,
        
        // etf: state.getUserSelectionsReducer.selectedQuarter === '1' ? "etf3" 
        //         : state.getUserSelectionsReducer.selectedQuarter === '2' ? "etf4" 
        //         : state.getUserSelectionsReducer.selectedQuarter === '3' ? "etf5" 
        //         : "etf",
        
        fs_existingFiles: !state.existingFilesReducer.fs_existingFiles ? [] : state.existingFilesReducer.fs_existingFiles,

        ar_existingFiles: !state.existingFilesReducer.ar_existingFiles ? [] : state.existingFilesReducer.ar_existingFiles,
        
        nz_existingFiles: !state.existingFilesReducer.nz_existingFiles ? [] : state.existingFilesReducer.nz_existingFiles,
        
        aus_existingFiles: !state.existingFilesReducer.aus_existingFiles ? [] : state.existingFilesReducer.aus_existingFiles,
        
        us_existingFiles:!state.existingFilesReducer.us_existingFiles ? [] : state.existingFilesReducer.us_existingFiles,
        
        ins_existingFiles:!state.existingFilesReducer.ins_existingFiles ? [] : state.existingFilesReducer.ins_existingFiles,
        
        esg_existingFiles:!state.existingFilesReducer.esg_existingFiles ? [] : state.existingFilesReducer.esg_existingFiles,
        
        ths_existingFiles: !state.existingFilesReducer.ths_existingFiles ? [] : state.existingFilesReducer.ths_existingFiles,
        
        ppt_existingFiles: !state.existingFilesReducer.ppt_existingFiles ? [] : state.existingFilesReducer.ppt_existingFiles,
        
        bac_existingFiles: !state.existingFilesReducer.bac_existingFiles ? [] : state.existingFilesReducer.bac_existingFiles,
        
        nz_core_existingFiles: !state.existingFilesReducer.nz_core_existingFiles ? [] : state.existingFilesReducer.nz_core_existingFiles,
        
        etfCode: !state.getEtfReducer.etfCode ? null : state.getEtfReducer.etfCode,
        
        stocks: !state.getStocksReducer.stocks ? [] : state.getStocksReducer.stocks,

        investorReturnsDate: !state.getStocksReducer.investorReturnsDate ? null : state.getStocksReducer.investorReturnsDate, //GTA-69832
        
        holdings: !state.typesOfFundsReducer.holdings ? [] : state.typesOfFundsReducer.holdings,
        
        portfolioCharacteristics: !state.typesOfFundsReducer.portfolioCharacteristics ? [] : state.typesOfFundsReducer.portfolioCharacteristics,
        
        fundPerformanceGraph: !state.typesOfFundsReducer.fundPerformanceGraph? null : state.typesOfFundsReducer.fundPerformanceGraph,
        
        investorReturns: !state.typesOfFundsReducer.investorReturns ? [] : state.typesOfFundsReducer.investorReturns,
        
        dividends: !state.typesOfFundsReducer.dividends ? [] : state.typesOfFundsReducer.dividends,
        
        marketAnnouncements: !state.typesOfFundsReducer.marketAnnouncements ? [] : state.typesOfFundsReducer.marketAnnouncements,
        
        etf: !state.getEtfReducer.etf ? null : state.getEtfReducer.etf,
        
        allEtfs: !state.getEtfReducer.allEtfs ? [] : state.getEtfReducer.allEtfs,
        
        nzExposureEtfs: !state.getEtfReducer.nzExposureEtfs ? [] : state.getEtfReducer.nzExposureEtfs,
        
        subFunds: !state.getEtfReducer.subFunds ? [] : state.getEtfReducer.subFunds,
        
        span: !state.getEtfReducer.span ? null : state.getEtfReducer.span,
        
        shareName: !state.getEtfReducer.shareName ? null : state.getEtfReducer.shareName,
        
        faqs: !state.getFaqsReducer.faqs ? [] : state.getFaqsReducer.faqs,
        
        glossary: !state.getFaqsReducer.glossary ? [] : state.getFaqsReducer.glossary
    }
}

function mapDispatchtoProps(dispatch){
    return bindActionCreators(actions, dispatch)
}

const App = withRouter(connect(mapStatetoProps, mapDispatchtoProps)(Main));

export default App;