/**
 * Component for main menu in the header.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

/**
 * Import components and css
 */
import MegaMenu from './MegaMenu';
import MegaMenu_Legal from './MegaMenu_Legal';
import * as smartshares from './_smartshares';
import '../css/Main.css';
import '../css/MainMenu.css';

class MainMenu extends Component {
    constructor(props){
        super(props);
        this.state = {loading: true}
        this._openExistingInvestorLogin = this._openExistingInvestorLogin.bind(this);
    }

    componentDidMount() {
        (String(this.props.history.location.pathname) === "/") ?
            document.getElementsByClassName('col-main-menu-active').length !== 0 &&
                (document.getElementsByClassName('col-main-menu-active').item(0).className = 'col-main-menu') : 
            localStorage.getItem("selectedMenu") !== null && 
                (document.getElementById(localStorage.getItem("selectedMenu")).className = "col-main-menu-active");
    }

    componentDidUpdate() {
        String(this.props.history.location.pathname) === "/" &&
            document.getElementsByClassName('col-main-menu-active').length !== 0 &&
                (document.getElementsByClassName('col-main-menu-active').item(0).className = 'col-main-menu');

        if(String(this.props.history.location.pathname) !== "/") {
            localStorage.getItem("selectedMenu") && 
                (document.getElementById(localStorage.getItem("selectedMenu")).className = "col-main-menu");

            document.getElementsByClassName("link_mainMenu active").length !== 0 && 
                (document.getElementsByClassName('link_mainMenu active').item(0).parentElement.className = "col-main-menu-active");

            document.getElementsByClassName("col-main-menu-active").length!==0 && 
                localStorage.setItem("selectedMenu", document.getElementsByClassName("col-main-menu-active").item(0).getAttribute("id"));
        }
    }

    _closeMenuBar(event) {
        if(document.getElementsByClassName('menu-activate active').item(0)) {
            document.getElementsByClassName('menu-activate active').item(0).className = 'menu-activate';
            document.getElementsByClassName('mainMenu').item(0).style.right ="-513px";
        }
        else {
            event.target.id === 'types-of-funds' && (document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'none');
            event.target.id === 'legal-documents' && (document.getElementsByClassName('mega-menu-wrapper-legal').item(0).style.display = 'none');
        }
    }

    _openExistingInvestorLogin(event) {
        smartshares._openExternalLink(event.target.href, event.target.target);
    }

    _openMegaMenu(event) {
        event.preventDefault();
        if(!document.getElementsByClassName('menu-activate active').item(0)) {
            event.target.id === 'types-of-funds' && (document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'block');
            event.target.id === 'legal-documents' && (document.getElementsByClassName('mega-menu-wrapper-legal').item(0).style.display = 'block');
        }
        else {
            event.target.id === 'types-of-funds' && (document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'none');
            event.target.id === 'legal-documents' && (document.getElementsByClassName('mega-menu-wrapper-legal').item(0).style.display = 'none');
        }
        
    }

    _closeMegaMenu(event) {
        event.preventDefault();
        event.target.id === 'types-of-funds' && (document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'none');
        event.target.id === 'legal-documents' && (document.getElementsByClassName('mega-menu-wrapper-legal').item(0).style.display = 'none');
    }
    
    render() {
        return (
            <Container fluid className="mainMenu">
                <Row>
                    <Col id="types-of-funds" className="col-main-menu" onMouseOver={e => this._openMegaMenu(e)}  onMouseLeave={e => this._closeMegaMenu(e)}
                        onClick={e => this._closeMenuBar(e)}><NavLink id="types-of-funds" className="link_mainMenu" to="/types-of-funds"
                        activeClassName="active" onClick={e=>ReactGA.pageview('/types-of-funds')}>Types of Funds</NavLink>
                        <MegaMenu {...this.props}></MegaMenu>
                    </Col>
                    <Col id="returns" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                            <NavLink id="returns" className="link_mainMenu" to="/fund-investor-report"
                                activeClassName="active" onClick={e=>ReactGA.pageview('/fund-investor-report')}>Returns
                            </NavLink>
                    </Col>
                    <Col id="about" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                        <NavLink id="about" className="link_mainMenu" to="/about-smartshares" 
                            activeClassName="active" 
                            onClick={e=>ReactGA.pageview('/about-smartshares')}
                            >About
                        </NavLink></Col>
                    <Col id="benefits" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                        <NavLink id="benefits" className="link_mainMenu" to="/benefits"
                            activeClassName="active" onClick={e=>ReactGA.pageview('/benefits')}>Benefits
                        </NavLink></Col>
                    <Col id="invest" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                        <NavLink id="invest" className="link_mainMenu" to="/invest-now"
                            activeClassName="active" onClick={e=>ReactGA.pageview('/invest-now')}>Invest
                        </NavLink></Col>
                    <Col id="contact" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                        <NavLink id="contact" className="link_mainMenu" to="/contact-us"
                            activeClassName="active" onClick={e=>ReactGA.pageview('/contact-us')}>Contact
                        </NavLink></Col>
                    <Col id="legal-documents" className="col-main-menu" onMouseOver={e => this._openMegaMenu(e)}  onMouseLeave={e => this._closeMegaMenu(e)}
                        onClick={e => this._closeMenuBar(e)}>
                        <NavLink id="legal-documents" className="link_mainMenu" to="/legal-documents"
                            activeClassName="active" onClick={e=>ReactGA.pageview('/legal-documents')}>Legal Documents
                        </NavLink>
                        <MegaMenu_Legal></MegaMenu_Legal>
                    </Col>
                    <Col id="existing-investor" className="col-main-menu" onClick={e => this._closeMenuBar(e)}>
                        <a id="existing-investor" className="link_mainMenu" target="_blank"
                            href="https://smartshares.linkinvestorservices.co.nz/forms/rsp" 
                            // onClick={e => this._openExistingInvestorLogin(e)}
                            activeClassName="active" style={{color: "#333333"}}>EXISTING INVESTOR</a></Col>
                    </Row>
            </Container>  
        )
    }
}
export default MainMenu;