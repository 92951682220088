/**
 * Component for Mega Menu of Types Of Funds.
 * Component for menu dropdown when hovered on 
 * Types of Funds in header menu.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react'; 
import { Link } from 'react-router-dom';

class MegaMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            etfCode: null
        };
    }


    _setEtfCode(etfCode) {
        localStorage.setItem('etfCode', etfCode);
        this.state.etfCode !== etfCode && this.setState({etfCode: etfCode});
        document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'none';

    }

    componentDidUpdate(prevProps, prevState){
        this.state.etfCode !== prevState.etfCode && this.props.setEtfCode(this.state.etfCode);
    }

    _closeMegaMenu(event) {
        document.getElementsByClassName('mega-menu-wrapper').item(0).style.display = 'none';
    }

    render() {
        return (
            <div className="mega-menu-wrapper" onMouseLeave={e => this._closeMegaMenu(e)}>
                <div className="inner" >
                    <div className="nav-col col-1">
                        <ul>
                            <li className="heading">
                                {/* <img src="/images/icon-mega-menu-other.png"></img> //Folded Paper Icon*/}
                                <img src="/images/icon-mega-menu-smartlarge.png"></img> {/* 3 buildings */}
                                <h4><Link to="/types-of-funds#core-series" onClick={e => this._closeMegaMenu(e)}>CORE SERIES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/nzg"  onClick={e => this._setEtfCode('nzg')}> 
                                    S&amp;P/NZX 50 ETF
                                    <span> (NZG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/aus" onClick={e => this._setEtfCode('aus')}> 
                                    S&amp;P/ASX 200 ETF
                                    <span> (AUS)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-500" onClick={e => this._setEtfCode('usf')}> 
                                    US 500 ETF
                                    <span> (USF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-500-hedged" onClick={e => this._setEtfCode('ush')}> 
                                    US 500 (NZD HEDGED) ETF
                                    <span> (USH)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/total-world" onClick={e => this._setEtfCode('twf')}> 
                                    TOTAL WORLD ETF
                                    <span> (TWF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/twh" onClick={e => this._setEtfCode('twh')}> 
                                    TOTAL WORLD (NZD HEDGED) ETF
                                    <span> (TWH)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/ngb" onClick={e => this._setEtfCode('ngb')}> 
                                    S&amp;P/NZX NZ GOVERNMENT BOND ETF
                                    <span> (NGB)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/global-government-bond" onClick={e => this._setEtfCode('ggb')}> 
                                    GLOBAL GOVERNMENT BOND ETF
                                    <span> (GGB)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/global-aggregate-bond" onClick={e => this._setEtfCode('agg')}> 
                                    GLOBAL AGGREGATE BOND ETF
                                    <span> (AGG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/nzcashtrust" onClick={e => this._setEtfCode('nzc')}> 
                                    NZ CASH ETF
                                    <span> (NZC)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartlarge.png"></img>
                                <h4><Link to="/types-of-funds#new-zealand-shares" onClick={e => this._closeMegaMenu(e)}>NEW ZEALAND SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/fnz" onClick={e => this._setEtfCode('fnz')}> 
                                    NZ TOP 50 ETF                                    
                                    <span> (FNZ)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/tnz" onClick={e => this._setEtfCode('tnz')}> 
                                    NZ TOP 10 ETF
                                    <span> (TNZ)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/mdz" onClick={e => this._setEtfCode('mdz')}> 
                                    NZ MID CAP ETF
                                    <span> (MDZ)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/nz-dividend" onClick={e => this._setEtfCode('div')}> 
                                    NZ DIVIDEND ETF
                                    <span> (DIV)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/new-zealand-shares/nzg" onClick={e => this._setEtfCode('nzg')}> 
                                    S&amp;P/NZX 50 ETF
                                    <span> (NZG)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartmedium.png"></img>
                                <h4><Link to="/types-of-funds#australian-shares" onClick={e => this._closeMegaMenu(e)}>AUSTRALIAN SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/ozy" onClick={e => this._setEtfCode('ozy')}> 
                                    AUS TOP 20 ETF
                                    <span> (OZY)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/mzy" onClick={e => this._setEtfCode('mzy')}> 
                                    AUS MID CAP ETF
                                    <span> (MZY)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/asd" onClick={e => this._setEtfCode('asd')}> 
                                    AUS DIVIDEND ETF
                                    <span> (ASD)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/au-financials" onClick={e => this._setEtfCode('asf')}> 
                                    AUS FINANCIALS ETF
                                    <span> (ASF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/au-resources" onClick={e => this._setEtfCode('asr')}> 
                                    AUS RESOURCES ETF
                                    <span> (ASR)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/australian-shares/aus" onClick={e => this._setEtfCode('aus')}> 
                                    S&amp;P/ASX 200 ETF
                                    <span> (AUS)</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav-col">
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartsmall.png"></img>
                                <h4><Link to="/types-of-funds#us-shares" onClick={e => this._closeMegaMenu(e)}>US SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-500" onClick={e => this._setEtfCode('usf')}> 
                                    US 500 ETF
                                    <span> (USF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-500-hedged" onClick={e => this._setEtfCode('ush')}> 
                                    US 500 (NZD HEDGED) ETF
                                    <span> (USH)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-large-growth" onClick={e => this._setEtfCode('usg')}> 
                                    US LARGE GROWTH ETF
                                    <span> (USG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-large-value" onClick={e => this._setEtfCode('usv')}> 
                                    US LARGE VALUE ETF
                                    <span> (USV)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-mid-cap" onClick={e => this._setEtfCode('usm')}> 
                                    US MID CAP ETF
                                    <span> (USM)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/us-shares/us-small-cap" onClick={e => this._setEtfCode('uss')}> 
                                    US SMALL CAP ETF
                                    <span> (USS)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/SmartIncome_50.png"></img>
                                <h4><Link to="/types-of-funds#international-shares" onClick={e => this._closeMegaMenu(e)}>INTERNATIONAL SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/asia-pacific" onClick={e => this._setEtfCode('apa')}> 
                                    ASIA PACIFIC ETF
                                    <span> (APA)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/emerging-markets" onClick={e => this._setEtfCode('emf')}> 
                                    EMERGING MARKETS ETF
                                    <span> (EMF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/total-world" onClick={e => this._setEtfCode('twf')}> 
                                    TOTAL WORLD ETF
                                    <span> (TWF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/europe" onClick={e => this._setEtfCode('euf')}> 
                                    EUROPE ETF
                                    <span> (EUF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/international-shares/twh" onClick={e => this._setEtfCode('twh')}> 
                                    TOTAL WORLD (NZD HEDGED) ETF
                                    <span> (TWH)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartdividend.png"></img>
                                <h4><Link to="/types-of-funds#bonds-and-cash" onClick={e => this._closeMegaMenu(e)}>BONDS AND CASH</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/nzbondtrust" onClick={e => this._setEtfCode('nzb')}> 
                                    NZ BOND ETF
                                    <span> (NZB)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/nzcashtrust" onClick={e => this._setEtfCode('nzc')}> 
                                    NZ CASH ETF
                                    <span> (NZC)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/globalbondtrust" onClick={e => this._setEtfCode('gbf')}> 
                                    GLOBAL BOND ETF
                                    <span> (GBF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/global-government-bond" onClick={e => this._setEtfCode('ggb')}> 
                                    GLOBAL GOVERNMENT BOND ETF
                                    <span> (GGB)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/global-aggregate-bond" onClick={e => this._setEtfCode('agg')}> 
                                GLOBAL AGGREGATE BOND ETF
                                    <span> (AGG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/bonds-and-cash/ngb" onClick={e => this._setEtfCode('ngb')}> 
                                    S&amp;P/NZX NZ GOVERNMENT BOND ETF
                                    <span> (NGB)</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="nav-col">
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-flexibility-lge (1).png"></img>
                                <h4><Link to="/types-of-funds#esg-shares" onClick={e => this._closeMegaMenu(e)}>ESG SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/global-equities" onClick={e => this._setEtfCode('esg')}> 
                                    GLOBAL EQUITIES ESG ETF
                                    <span> (ESG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/aue" onClick={e => this._setEtfCode('aue')}> 
                                    AUSTRALIAN EQUITIES ESG ETF
                                    <span> (AUE)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/us-enquities" onClick={e => this._setEtfCode('usa')}> 
                                    US EQUITIES ESG ETF
                                    <span> (USA)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/japan-equities" onClick={e => this._setEtfCode('jpn')}> 
                                    JAPAN EQUITIES ESG ETF
                                    <span> (JPN)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/emerging-markets-equities" onClick={e => this._setEtfCode('emg')}> 
                                    EMERGING MARKETS EQUITIES ESG ETF
                                    <span> (EMG)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/esg-shares/europe-equities" onClick={e => this._setEtfCode('eug')}> 
                                    EUROPE EQUITIES ESG ETF
                                    <span> (EUG)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartsector.png"></img>
                                <h4><Link to="/types-of-funds#thematic-shares" onClick={e => this._closeMegaMenu(e)}>THEMATIC SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/thematic-shares/automation-and-robotics" onClick={e => this._setEtfCode('bot')}> 
                                    AUTOMATION AND ROBOTICS ETF
                                    <span> (BOT)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/thematic-shares/global-infrastructure" onClick={e => this._setEtfCode('inf')}> 
                                    GLOBAL INFRASTRUCTURE ETF
                                    <span> (INF)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/thematic-shares/healthcare-innovation" onClick={e => this._setEtfCode('liv')}> 
                                    HEALTHCARE INNOVATION ETF
                                    <span> (LIV)</span>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-smartsector.png"></img>
                                <h4><Link to="/types-of-funds#property-shares" onClick={e => this._closeMegaMenu(e)}>PROPERTY SHARES</Link></h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/property-shares/nzpropertytrust" onClick={e => this._setEtfCode('npf')}> 
                                    NZ PROPERTY ETF
                                    <span> (NPF)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/property-shares/asp" onClick={e => this._setEtfCode('asp')}> 
                                    AUS PROPERTY ETF
                                    <span> (ASP)</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/types-of-funds/property-shares/gpr" onClick={e => this._setEtfCode('gpr')}> 
                                    GLOBAL PROPERTY ETF
                                    <span> (GPR)</span>
                                    <sup>NEW</sup>
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className="heading">
                                <img src="/images/icon-mega-menu-other.png"></img>
                                <h4><Link to="/types-of-funds/nz-core" onClick={e => this._closeMegaMenu(e)}><strong>NZ CORE</strong></Link>
                                </h4>
                            </li>
                            <li>
                                <Link to="/types-of-funds/nz-core" onClick={e => this._setEtfCode('nz-core')} style={{display: "inline-block"}}> 
                                FIND OUT ABOUT NZCORE
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default MegaMenu;