/**
 * Component for Smartshares ETFs section in About page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Link as ScrollTo} from 'react-scroll';

class SmartsharesETFs extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    }
    render() {
        return (
            <div>
                <Container>
                    <Row style={{ display: "block" }}>
                        <ScrollTo className="anchor has-sub-nav" name="#etfs"></ScrollTo>
                        <Col md="12" className="text-center">
                            <p>&nbsp;</p>
                            <h1 className="dark-blue">Exchange Traded Funds (ETFs) are one of the fastest growing investment products in the world.</h1>
                            <Row className="text-center">
                                <Col md="10" className="col-md-push-1">
                                    <p className="space-bottom"><b></b></p>
                                    <p className="space-bottom">
                                    Smartshares Limited (Smartshares) is a leading investment fund manager in Aotearoa. We serve more than 177,000 investors* from individual investors, Financial Adviser customers, businesses and iwi to Pacific nation groups.
                                    <br />
                                    <br />
                                    Smartshares also works with some of Aotearoa's largest employers to provide investment options as part of their employee benefits.
                                    <br />
                                    <br />
                                    Smartshares has been established since 1996, and is a wholly owned subsidiary of NZX Limited with more than $10B* in funds under management.
                                </p>
                                <Row className="display-inline-flex space-bottom text-left">
                                    <p>
                                        Smartshares offers its customers a range of investment options through the following brands:
                                    </p>
                                    <ul>
                                        <li><a href='/types-of-funds'>Smartshares</a> - easily accessible and affordable exchange traded funds listed on the NZX.</li>
                                        <li><a href='https://www.superlife.co.nz/'>SuperLife</a> - a range of diversified and sector-specific investment funds that can be accessed through five managed investment schemes. 
                                        Smartshares is  one of six government-appointed default KiwiSaver providers and looks after the workplace savings for some of the largest employers in Aotearoa.</li>
                                        <li><a href='https://quaystreet.com/'>QuayStreet</a> - diversified and sector-specific investment funds that can be accessed through two managed investment schemes.</li>

                                    </ul>
                                    <p>*As at 01 October 2023</p>
                                </Row>
                                    <hr></hr>
                                    <h3 className="brand-title-component">
                                        <span className="brand-title-component__thick dark-blue">SMART</span>
                                        <span className="brand-title-component__thin blue">SHARES</span>
                                    </h3>
                                    <p><b>Smartshares is the pioneer of ETFs in New Zealand, launching the first ETF (the NZ Top 10 Fund - TNZ) in 1996.</b></p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
                
        )
    }
}

export default SmartsharesETFs;