/**Componenet for How to get Smartshares section in About Page */

/**Import dependencies */
import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**Import components and css */
import '../css/SubMenu.css';

class HowtogetSmartshares extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false }
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col md="12">
                        <div className="full-width">
                            <div className="wrapper text-center">
                                <div className="text-center smartshares">
                                    <Row className="space-bottom">
                                        <ScrollTo className="anchor has-sub-nav" name="#how-to-get-smartshares"></ScrollTo>
                                        <Col md="12">
                                            <h2 className="big">
                                                How To Get
                                                <span className="brand-title-component">
                                                    <span className="brand-title-component__thick dark-blue"> Smart</span>
                                                    <span className="brand-title-component__thin blue">Shares</span>
                                                </span>
                                            </h2>
                                            <p className="bigger">Choose from one of three easy investment options and take advantage of flexible payment terms.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="10" className="col-md-push-1">
                                            <Row className="display-inline-flex">
                                                <Col sm="4" className="space-bottom">
                                                    <img src="/images/icon-lge-online.gif" />
                                                    <h3 style={{color: "#000000"}}>Online</h3>
                                                    <p>Smartshares accepts direct investments from new investors for as little as $500 per fund. Read the Product Disclosure Statement then apply online.</p>
                                                    <span className="visible-xs">
                                                        <Button className="btn s116-25 onlight">
                                                            <Link to="/invest-now">
                                                                Find out more
                                                            </Link>
                                                        </Button>
                                                    </span>
                                                </Col>
                                                
                                                <Col sm="4" className="space-bottom">
                                                    <img src="/images/icon-lge-participant.gif" />
                                                    <h3 style={{color: "#000000"}}>NZX Participant</h3>
                                                    <p>You can buy units in Smartshares funds through an NZX Participant, just like ordinary shares.</p>
                                                    <span className="visible-xs">
                                                        <Button className="btn s116-25 onlight">
                                                            <Link to="/invest-now">
                                                                Find out more
                                                            </Link>
                                                        </Button>
                                                    </span>
                                                </Col>
                                                
                                                <Col sm="4" className="space-bottom">
                                                    <img src="/images/icon-lge-advisor.gif" />
                                                    <h3 style={{color: "#000000"}}>Authorised Financial Adviser</h3>
                                                    <p>Ask your financial adviser about Smartshares funds, as many financial advisers have arrangements for buying these products either through Smartshares or an NZX Participant.</p>
                                                    <span className="visible-xs">
                                                        <Button className="btn s116-25 onlight">
                                                            <Link to="/invest-now">
                                                                Find out more
                                                            </Link>
                                                        </Button>
                                                    </span>
                                                </Col>
                                            </Row>
                                            
                                            <Row className="hidden-xs">
                                                <Col sm="4" className="space-bottom">
                                                    <Link to="/invest-now#apply-online">
                                                        <Button className="btn s116-25 onlight">
                                                            Find out more
                                                        </Button>
                                                    </Link>
                                                </Col>
                                                
                                                <Col sm="4" className="space-bottom">
                                                    <Link to="/invest-now#participants">
                                                        <Button className="btn s116-25 onlight">
                                                            Find out more
                                                        </Button>
                                                    </Link>
                                                </Col>

                                                <Col sm="4" className="space-bottom">
                                                    <Link to="/invest-now#advisor">
                                                        <Button className="btn s116-25 onlight">
                                                            Find out more
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default HowtogetSmartshares;