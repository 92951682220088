/**
 * Component for Quote in Homepage
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares'
import '../css/HomePage.css'

class Quote_Component extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    _goToMaryHolm(event) {
        smartshares._openExternalLink(event.target.href, event.target.target)
    }    

    render() {
        return (
            <Container>
                <Row>
                    <Col md="10" className="col-md-push-1">
                        <div className="quote-component">
                            <h2 className="quote-component__quote">
					            “Many New Zealand shareholders own shares in just one or a few companies. That’s not clever.”
				            </h2>
                            <div className="quote-component__author">
                                <Link to="http://www.maryholm.com" target="_blank" className="name" onClick={e => this._goToMaryHolm(e)}>Mary Holm </Link>
                                 (BA, MA, MBA, financial columnist and seminar presenter)&nbsp;in
                                <br></br>
                                “Upside Downside - a guide to risk savers and Investors”, 
                                <br></br>
                                published by the Reserve Bank of NZ.
				            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Quote_Component