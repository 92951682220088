/**Table of Dividends Component in ETF */
import React, { Component } from 'react';
import { Col, Container, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import moment from 'moment';

class Dividends_Table extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            dividends: [],
            upcomingDividends: [],
            historicDividends: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        const dividends = props.dividends;
        let upcomingDividends = [];
        let historicDividends = [];

        //Upcoming Dividends
        dividends.filter(dividend => moment(dividend.pay_date).isAfter(moment())).map(dividend => upcomingDividends.push(dividend));

        //Historic Dividends
        dividends.filter(dividend => moment(dividend.pay_date).isBefore(moment())).map(dividend => historicDividends.push(dividend));
        return {
            dividends: dividends,
            upcomingDividends: upcomingDividends,
            historicDividends: historicDividends
        }
    }

    render() {        
        return (
            // Just a Sample
            <Container>
                <Row>
                    <Col md="12">
                        <hr></hr>
                        <div>
                            <h3 className="h1 upper dark-blue">Table of Dividends</h3>
                        </div> 
                        {
                            this.state.upcomingDividends && this.state.upcomingDividends.length !== 0 && 
                                <div>
                                    <div>
                                        <h3 className="upper light-blue">
                                            <b>Upcoming Dividends</b>
                                        </h3>
                                    </div>
                                    <Table striped className="fund-businesses dividends Table">
                                        <thead>
                                            <tr>
                                                <th>EX DIVIDEND</th>
                                                <th>PERIOD</th>
                                                <th>AMOUNT</th>
                                                <th>SUPPL</th>
                                                <th>IMPUTATION</th>
                                                <th>PAYABLE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.upcomingDividends.map(dividend =>                                         
                                                    <tr key={dividend.nzxDividendId}>
                                                        <td>{dividend.exDate}</td>
                                                        <td>{dividend.period}</td>
                                                        <td>{Number(dividend.net_div).toFixed(6)}</td>
                                                        <td>{Number(dividend.supplementary).toFixed(6)}</td>
                                                        <td>{Number(dividend.imputations).toFixed(6)}</td>
                                                        <td>{dividend.pay_date}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                        }
                        {
                            this.state.historicDividends && this.state.historicDividends.length !== 0 && 
                                <div>
                                    <div>
                                        <h3 className="upper light-blue">
                                            <b>Historic Dividends</b>
                                        </h3>
                                    </div>
                                    <Table striped className="fund-businesses dividends-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <OverlayTrigger trigger='hover' placement='right' overlay={
                                                        <Popover id='popover-basic' className='in right'>
                                                            <Popover.Title as='h3'>Ex-dividend date</Popover.Title>
                                                            <Popover.Content>The date when a declared dividend belongs to the seller rather than the buyer of units. If you purchase units on or after the ex-dividend date you will not receive the dividend for that period.</Popover.Content>
                                                        </Popover>
                                                    }>
                                                        <span data-toggle='popover'>EX DIVIDEND</span>
                                                    </OverlayTrigger>
                                                </th>
                                                <th>PERIOD</th>
                                                <th>AMOUNT</th>
                                                <th>
                                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                                        <Popover id='popover-basic' className='in top'>
                                                            <Popover.Title as='h3'>Suppl.</Popover.Title>
                                                            <Popover.Content>Is a supplementary dividend, which is a credit passed on to non-resident investors to offset the effects of double taxation (where the non-resident is subject to both company income tax and non-resident withholding tax).</Popover.Content>
                                                        </Popover>
                                                    }>
                                                        <span data-toggle='popover'>SUPPL.</span>
                                                    </OverlayTrigger>
                                                </th>
                                                <th>
                                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                                        <Popover id='popover-basic' className='in top'>
                                                            <Popover.Title as='h3'>Imputation</Popover.Title>
                                                            <Popover.Content>A credit passed to investors for tax already paid on profits by a company before it pays a dividend. Imputation credits offset the amount of tax that a resident shareholder would otherwise be liable to pay on those dividends and therefore removes the effects of double taxation.</Popover.Content>
                                                        </Popover>
                                                    }>
                                                        <span data-toggle='popover'>IMPUTATION</span>
                                                    </OverlayTrigger>
                                                </th>
                                                <th>
                                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                                        <Popover id='popover-basic' className='in top'>
                                                            <Popover.Title as='h3'>Payable</Popover.Title>
                                                            <Popover.Content>The date a dividend was paid.</Popover.Content>
                                                        </Popover>
                                                    }>
                                                        <span data-toggle='popover'>PAYABLE</span>
                                                    </OverlayTrigger>    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.historicDividends.map(dividend => 
                                                    <tr key={dividend.nzxDividendId}>
                                                        <td>{dividend.exDate}</td>
                                                        <td>{dividend.period}</td>
                                                        <td>{Number(dividend.net_div).toFixed(6)}</td>
                                                        <td>{Number(dividend.supplementary).toFixed(6)}</td>
                                                        <td>{Number(dividend.imputations).toFixed(6)}</td>
                                                        <td>{dividend.pay_date}</td>
                                                    </tr>
                                                )   
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                        }
                        
                    </Col>
                </Row>  
            </Container>
        )
    }
}

export default Dividends_Table;