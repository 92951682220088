/**Australian Shares Component in Types of funds and Legal Documents */

/**Import Dependencies */
import React, {Component} from 'react';
import { Link as ScrollTo } from 'react-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**Import Components and CSS */
import * as smartshares from './_smartshares';

class AUSShares extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files : [
                {
                    code: 'OZY',
                    label: 'AUS Top 20 ',
                    fileName: 'australian_top_20',
                    url: 'ozy'
                },
                {
                    code: 'MZY',
                    label: 'AUS Mid Cap ',
                    fileName: 'australian_mid_cap',
                    url: 'mzy'
                },
                {
                    code: 'ASD',
                    label: 'AUS Dividend ',
                    fileName: 'australian_dividend',
                    url: 'asd'
                },
                {
                    code: 'ASF',
                    label: 'AUS Financials ',
                    fileName: 'australian_financials',
                    url: 'au-financials'
                },
                {
                    code: 'ASR',
                    label: 'AUS Resources ',
                    fileName: 'australian_resources',
                    url: 'au-resources'
                },
                {
                    code: 'AUS',
                    label: 'S&P/ASX 200 ',
                    fileName: 's&p_asx_200',
                    url: 'aus'
                }
            ],
            
            fileAttributes : {
                shares: "AUS",
                folder: "/document-library/fund-updates/aus-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },

            existingFiles:[]
        };
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.aus_existingFiles;
        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', String(etfCode).toLowerCase());
        // this.props.setEtfCode(String(etfCode).toLowerCase());
        smartshares._setEtfCode(etfCode);
    }

    render() {

        // Displays on Type of Funds Screen
        if(String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="col-md-push-7 text-center space-bottom">
                            <img src="../images/smart-med.jpg" alt="smart-med.jpg"></img>
                        </Col>
                        <Col md="7" className="col-md-pull-5 text-center australian-shares">
                            <ScrollTo name="#australian-shares" className="anchor"></ScrollTo>
                            <img src="../images/icon-smart-medium-lge.png" alt="icon-smart-medium-lge.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">AUSTRALIAN SHARES</span>
                            </h3>
                            <p>Our Australian Shares ETFs cover the main Australian indices as well as specific Australian sectors such as Resources and Financials, all through funds listed on the NZX and priced in New Zealand dollars.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue">
                                <b>THERE ARE SIX FUNDS TO CHOOSE FROM:</b>
                            </h3>
                            <p>&nbsp;</p>
                            <Row>
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code}  sm="6">
                                            <p className="fun-movement text-left">
                                                <span className="country aus"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/australian-shares/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>{`${String(file.label).toUpperCase()}ETF (${file.code})`}</b>
                                                </Link>
                                            </p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )
            
        // Displays on Legal Documents Screen
        else if(String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div cid="qs-3" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">Australian shares</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/aus-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${String(file.file.label).replace('AUS', 'Australian')} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            )

        // Other Screens
        else return(<div></div>);
    }
}

export default AUSShares;
