/**PDF Hero Component displays the image at the top of every ETF's PDF version */

/**Import dependencies */
import React, { Component } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';

class PrintableHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            sharesCategory: '',
            image: '',
            etf: null
        };
    }

    static getDerivedStateFromProps(props, state){
        const sharesCategory = props.etf && props.etf.share;
        const image = props.etf && props.etf.image;
        const etf = props.etf && props.etf.etfDetails;

        return{
            sharesCategory : sharesCategory,
            image : image,
            etf : etf
        };
    }

    render(){
        return(
            <div className="hero-component hero-component--carousel">
                <div className="slide">
                <Container>
                        <Row>
                            <Col md="3">
                                <img src={this.state.image}></img>
                                <h3 className="brand-title-component">
                                    <span className="brand-title-component__thick blue">{String(this.state.sharesCategory).toUpperCase()}</span>
                                    <span className="brand-title-component__thin white"></span>
                                </h3>
                                <h3 className="slide__heading light-blue">{`${String(this.state.etf.fund).toUpperCase()} (${this.state.etf.code})`}</h3>
                            </Col>
                            <Col md="8">
                                <div className='light-blue'>
                                    {
                                        this.state.etf.desc.part1
                                    }
                                    {
                                        this.state.etf.desc.part2 && 
                                            <div>
                                                <br></br>
                                                {this.state.etf.desc.part2}
                                            </div>
                                    }
                                    {
                                        this.state.etf.desc.part3 && 
                                        <div>
                                            <br></br>
                                            {this.state.etf.desc.part3}
                                        </div>
                                    }
                                </div>
                            </Col>
                            
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default PrintableHeader;