/**
 * Component for Who We Are section in About page
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

/**
 * Import components ad css
 */
import '../css/SubMenu.css';
import '../css/Main.css';

class WhoWeAre extends Component {
    constructor(props){
        super(props);
        this.state = {loading: true};
    }
    
    render(){
        return(
            <div>
                <Container id="who-we-are">
                    <Row>
                        <ScrollTo className="anchor has-sub-nav" name="#who"></ScrollTo>
                        <Col className="text-center">
                            <h2 className="big dark-blue">WHO WE ARE</h2>
                            <p className="space-bottom">The Smartshares board is profiled below.</p>
                            <Row className="display-inline-flex">
                                <Col sm="6">
                                    <img src="/images/Graham-Law-2021.jpg" height="175"></img>
                                    <h3 className="brand-title-component">
                                        <span className="brand-title-component__thick blue">Graham </span>
                                        <span className="brand-title-component__thin blue">Law</span>
                                    </h3>
                                    <h5 className="upper dark-blue"><b>Chair</b></h5>
                                    <p>
                                        Graham is Chair of the Smartshares board. He is also Chief Financial & Corporate Officer of NZX. He has considerable experience working across the financial and professional service sectors in New Zealand and the United Kingdom. Graham previously worked as Head of Finance at ACC, and prior to this was Managing Director and Chief Financial Officer at AMP Capital Limited. Graham brings expertise in strategic leadership, corporate governance, and risk and financial management.
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <img src="/images/mark-peterson2.jpg" height="175"></img>
                                    <h3 className="brand-title-component">
                                        <span className="brand-title-component__thick blue">Mark </span>
                                        <span className="brand-title-component__thin blue">Peterson</span>
                                    </h3>
                                    <h5 className="upper dark-blue"><b>DIRECTOR</b></h5>
                                    <p>
                                        Mark is a director of Smartshares, and CEO of NZX. Mark has previously worked in a number of senior finance roles including Managing Director ANZ Securities. He has held executive roles with FNZC and The National Bank of NZ, and ANZ Institutional Bank. He is a former Chairman of the NZ Securities Industry Association.                            
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <Row className="display-inline-flex">
                                <Col sm="6">
                                    <img src="/images/Coote_compressed.png" height="175"></img>
                                    <h3 className="brand-title-component">
                                        <span className="brand-title-component__thick blue">Guy </span>
                                        <span className="brand-title-component__thin blue">Elliffe</span>
                                    </h3>
                                    <h5 className="upper dark-blue"><b>INDEPENDENT DIRECTOR</b></h5>
                                    <p>
                                        Guy is the Chairperson of the Smartshares Audit and Risk Committee and an independent director of Smartshares. He is currently Corporate Governance Manager at the Accident Compensation Corporation. Previously he was Head of Equities at AMP Capital Investors (New Zealand) Limited, and he has held a range of senior investment roles in New Zealand and the US.     
                                    </p>
                                </Col>															
                                <Col sm="6">
                                    <img src="/images/John-Williams-compressed.png" height="175"></img>
                                    <h3 className="brand-title-component">
                                        <span className="brand-title-component__thick blue">JOHN </span>
                                        <span className="brand-title-component__thin blue">WILLIAMS</span>
                                    </h3>
                                    <h5 className="upper dark-blue"><b>INDEPENDENT DIRECTOR</b></h5>
                                    <p>
                                        John is the Chairperson of the Smartshares Investment Oversight Committee and an independent director of Smartshares. He was most recently the Investment Manager at Trust Investments Management Limited. Previously he was a Key Client Manager at Vanguard Investments, and has held a range of investment strategy and consulting roles in the New Zealand institutional market.
                                    </p>                        
                                </Col>
                            </Row>
                            <hr></hr>
                            {/* <div className="video-library">
                                <h2 className="upper light-blue">
                                    <b>Video Library</b>
                                </h2>
                                <div className="space-bottom-full">
                                    <Row>
                                        <Col lg="10" lg="push-1">
                                            <div className="carousel video-library slick-initialized slick-slider">
                                                <div className="slick-list draggable" aria-live="polite" tabindex="0">
                                                    <div claaName="slick-tract" style={{opacity: "1", width: "885px", transform: "translate"}}></div>   
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div> */}
                        </Col>
                    </Row>   
                </Container>
            </div>
        )
    }
}

export default WhoWeAre;