/**
 * Component for Smartshares Routing
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

/**
 * Import Components and css
 */
import About from './About';
import Benefits from './Benefits';
import Contact from './Contact';
import HomePage from './HomePage';
import Invest from './Invest';
import LegalDocs from './LegalDocs';
import ETF from './ETF';
import PrivacyPolicy from './Privacy_Policy';
import Returns from './Returns';
import TermsOfUse from './Terms_Of_Use';
import TypesOfFunds from './TypesOfFunds';
import NZCoreFunds from './NZCoreFund';
import SubMenu from './SubMenu';
import Banner from './Banner';
import NZExposure from './NZExposure';
import Adviser from './AdviserFormPage';
import PrizeDrawTC from './PrizeDrawTC';
import SmartsharesNews from './SmartsharesNews';

class Smartshares extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    componentDidMount(){
        // ReactGA.pageview(this.props.history.location.pathname, ['newTracker', 'existingTracker'])
        this.props.startLoadingStocks();
        this.props.setAllEtfs();
    }



    render(){
        return(
            <Switch>
                <Route exact path="/">
                    <HomePage {...this.props}></HomePage>
                </Route>

                {/* <Route exact path="/prize-draw-t-c">
                    <PrizeDrawTC {...this.props}/>
                </Route> */}
                
                <Route exact path="/types-of-funds">
                    <TypesOfFunds {...this.props}></TypesOfFunds>
                </Route>

                <Route exact path="/types-of-funds/:country/:code"
                    render={(params) => {
                        if(!String(params.match.params.country).includes('smart')) {
                            return <ETF {...this.props} {...params}></ETF>
                        }
                        else
                            return <Redirect to={`/types-of-funds/${this.props.shareName}/${this.props.allEtfs.filter(etf=> String(etf.code).toLowerCase() === params.match.params.code).map(etf => etf.url)}`}/>
                        }
                    }>
                </Route>

                <Route exact path ="/types-of-funds/nz-core">
                    <NZCoreFunds {...this.props}></NZCoreFunds>
                </Route>

                <Route exact path="/find-fund/:code"
                    render ={(params) =>
                        <Redirect to={`/types-of-funds/${this.props.shareName}/${this.props.allEtfs.filter(etf=> etf.code === params.match.params.code).map(etf => etf.url)}`}/>
                    }>
                </Route>

                {/* <Route exact path="/types-of-funds/:categor/:code"
                    render ={(params) =>
                        <Redirect to={`/types-of-funds/${this.props.shareName}/${this.props.allEtfs.filter(etf=> etf.code === params.match.params.code).map(etf => etf.url)}`}/>
                    }>
                </Route> */}

                <Route exact path="/fund-investor-report">
                    <Returns {...this.props}></Returns>
                </Route>

                <Route exact path="/about-smartshares">
                    <About {...this.props}></About>
                </Route>

                <Route exact path="/about-smartshares/news">
                    <SmartsharesNews {...this.props}></SmartsharesNews>
                </Route>

                <Route exact path="/benefits">
                    <Benefits {...this.props}></Benefits>
                </Route>

                <Route exact path="/invest-now">
                    <Invest {...this.props}></Invest>
                </Route>

                <Route exact path="/contact-us">
                    <Contact {...this.props}></Contact>
                </Route>

                <Route exact path="/legal-documents">
                    <LegalDocs {...this.props}></LegalDocs>
                </Route>

                {/* <Route path="https://smartshares.linkinvestorservices.co.nz/forms/rsp"><ExistingInvestorLogin></ExistingInvestorLogin> </Route> */}

                <Route exact path="/website-terms-of-use">
                    <TermsOfUse/>
                </Route>
                <Route exact path="/privacy-policy">
                    <PrivacyPolicy/>
                </Route>

                <Route exact path="/new-zealand-exposure">
                {/* <NZExposure {...this.props}/> *//** GTA 92907 - Remove orphan page */}
                    <Redirect to={`/`}/>
                </Route>


                {/* Decommissioned following GTA89715*/ }
                <Route exact path="/adviser">
                    <Redirect to={`/contact-us`}/>
                </Route>

                <Route component={
                            <div className="errorBoundary">
                                <h1 className="errorBoundary">404 Page Not Found</h1>
                                <p className="errorBoundary" style={{fontSize: '13px'}}>The page you requested was not found</p>
                            </div>
                        }>
                </Route>
            </Switch>
        )
    }
}

export default Smartshares;