/**
 * Component for Property shares in Types Of Funds Page
 * and Legal Documents page
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares';

class PropertyShares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files: [
                {
                    code: 'NPF',
                    label: 'NZ Property ',
                    fileName: 'nz_property',
                    url: 'nzpropertytrust'
                },
                {
                    code: 'ASP',
                    label: 'AUS Property ',
                    fileName: 'australian_property',
                    url: 'asp'
                },
                {
                    code: 'GPR',
                    label: 'Global Property ',
                    fileName: 'global_property',
                    url: 'gpr'
                },
            ],
            
            fileAttributes: {
                shares: "PPT",
                folder: "/document-library/fund-updates/property-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },
            
            existingFiles: []     
        };
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.ppt_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', etfCode);
        // this.props.setEtfCode(etfCode);
        smartshares._setEtfCode(etfCode)
    }

    render() {
        // Displays on Types of funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="col-md-push-7 text-center space-bottom">
                            <img src="../images/types-phtoto-comm-property.jpg"></img>
                        </Col>
                        <Col md="7" className="col-md-pull-5 text-center property-shares">
                            <ScrollTo name="#property-shares" className="anchor"></ScrollTo>
                            <img src="../images/SmartSector_Blue.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">PROPERTY SHARES</span>
                            </h3>
                            <p>Our Property ETFs provide exposure to local and global property companies.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue"><b>THERE ARE THREE FUNDS TO CHOOSE FROM:</b></h3>
                            <p>&nbsp;</p>
                            <Row>
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code} sm="6">
                                            <p className="fund movement text-left">
                                                <span className="country row"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/property-shares/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>
                                                        {`${file.label}ETF (${file.code})`}
                                                        {file.code === 'GPR' ? <sup>NEW</sup>:<></>}
                                                    </b>
                                                </Link>
                                            </p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>  
                    </Row>
                </Container>
            );

        // Displays on Legal Documents Screen
        else if(new String(this.props.history.location.pathname).includes("legal-documents"))
            return(
                <div id="qs7" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">Property shares</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/property-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            );

        // Other Screens
        else return (<div></div>);
    }
}

export default PropertyShares;