
/**Header Component */

/**Import dependencies */
import React, {Component} from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';

/**Import Components and css */
import MainMenu from './MainMenu';
import SubMenu from './SubMenu';
import '../css/Main.css';
import images from '../data/images';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    _openCloseMainMenu(event) {
        event.preventDefault();
        if(event.target.className === "menu-activate") {
            event.target.className = "menu-activate active";
            document.getElementsByClassName('mainMenu').item(0).style.right = "-13px";
            ReactGA.event({
                category: 'Hamburger',
                action: 'Toggle',
                label: event.target.className
            }, ['newTracker', 'existingTracker'])
        }

        else {
            event.target.className = "menu-activate";
            document.getElementsByClassName('mainMenu').item(0).style.right = "-513px";
        }
    }

	/** warning **/
    render(){
        const pathname = String(this.props.history.location.pathname);
        return(
            <div className={pathname === '/types-of-funds' || pathname === '/fund-investor-report' || pathname === '/contact-us' ? 'header-component border' : 'header-component'}>
                <Container fluid className="container">
                    <Row>
                        <div className="logo">
                            <Link className="link" to="/">
                                <img className="smartshares" src={images.Smartshares_Logo}  alt="SmartsharesLogo"></img>
                            </Link>
                        </div>
                        <Col className="menu-activate" onClick={e => this._openCloseMainMenu(e)} onBlur={e => this._openCloseMainMenu(e)}></Col>
                        <Col ><MainMenu {...this.props} ></MainMenu></Col>
                    </Row>										
					{/** maintenance warning
					<Row md="12" style={{ margin: "15px 0px", color: "#FF0000", fontSize: "12px" }}>We have upcoming scheduled maintenance from 27 August 2022 to 28 August 2022. During this time our website will be intermittently unavailable. We apologise for any inconvenience caused.</Row>
					**/}
                    {(this.props.location.pathname === "/about-smartshares" || this.props.location.pathname === "/benefits"||
                    this.props.location.pathname === "/invest-now") ? 
                        <SubMenu {...this.props}></SubMenu>:<></>}
                </Container>
            </div>
        )   
    }
}

export default Header;