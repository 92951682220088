/**
 * Component for Types of Funds page.
 */

/**
 * import dependencies
 */
import React, {Component} from 'react';

/**
 * import components and css
 */
import CoreSeriesFunds from './CoreSeriesFunds';
import Headings from './Headings';
import NZShares from './NZShares';
import AUSShares from './AUSShares';
import USShares from './USShares';
import InternationalShares from './InternationaShares';
import BondsCash from './BondsCash';
import ESGShares from './ESGShares';
import ThematicShares from './ThematicShares';
import PropertyShares from './PropertyShares';

import '../css/TypesOfFunds.css'

class TypesOfFunds extends Component {
    constructor(props){
        super(props);
        this.state = {loading: true}
    }

    static getDerivedStateFromProps(props, state) {
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount() {
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate() {
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    render() {
        return(
            <div className="position types-of-funds">
                <Headings {...this.props}></Headings>
                <CoreSeriesFunds {...this.props}></CoreSeriesFunds>
                <NZShares {...this.props} ></NZShares>
                <AUSShares {...this.props}></AUSShares>
                <USShares {...this.props}></USShares>
                <InternationalShares {...this.props}></InternationalShares>
                <BondsCash {...this.props}></BondsCash>
                <ESGShares {...this.props}></ESGShares>
                <ThematicShares {...this.props}></ThematicShares>
                <PropertyShares {...this.props}></PropertyShares>
            </div>
        )
    }
}

export default TypesOfFunds;