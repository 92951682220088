/**FAQS Component in FAQS and Glossary*/

/**Import dependencies */
import React, { Component } from 'react';
import { Col} from 'react-bootstrap';
import {Link as ScrollTo} from 'react-scroll';

class Faqs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            faqs: props.faqs || [],
            searchKey: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        let faqs = [];
        props.faqs.map(faq => faqs.push(faq));

        return {
            faqs: faqs,
            searchKey: props.searchKey
        }
    }

    _showAnswer(event, faqId) {
        if(event.target.id){
            document.getElementById(`q_${faqId}`).lastChild.style.display = "block";
            document.getElementById(`a_${faqId}`).style.maxHeight = `${document.getElementById(`a_${faqId}`).innerHTML.length}px`;
        }
    }

    _closeAnswer(event, faqId) {
        document.getElementById(`q_${faqId}`).lastChild.style.display = "none";
        document.getElementById(`a_${faqId}`).style.maxHeight = "0";
    }

    render() {
        let matchIndex = 0;
        let matchInQuestion = "";
       
        return (
            <Col className="col col1" sm={this.props.staticQA ? "12" : "6"}>                
                {!this.props.staticQA && <h3>FAQS</h3>}
                <div className={this.props.staticQA ? "results text-center" : "results"}>
                    {
                        this.state.faqs && this.state.faqs.length !== 0 ?
                            <ul className={this.props.staticQA ? "list-style-none" : ""}>
                                {
                                    this.state.faqs.map(faq => {
                                        matchIndex = String(faq.question).toLowerCase().indexOf(String(this.state.searchKey).toLowerCase());
                                        matchInQuestion = String(faq.question).substr(matchIndex, String(this.state.searchKey).length);
                                        return (
                                            <li key={`q_${faq.faqId}`}>
                                                <div className="question" id={`q_${faq.faqId}`} onClick={e => this._showAnswer(e, faq.faqId)}>
                                                    {
                                                        String(faq.question).split(matchInQuestion)[0]+" "
                                                    }
                                                    <span className="match">{matchInQuestion}</span>
                                                    {
                                                        String(faq.question).split(matchInQuestion)[1]
                                                    }
                                                    <ScrollTo name="#" className="close" style={{ display: "none"}} onClick={e => this._closeAnswer(e, faq.faqId)}>Close</ScrollTo>
                                                </div>
                                                <div id={`a_${faq.faqId}`} className="answer">{faq.answer}</div>
                                            </li>
                                        )
                                    })
                                }
                            </ul> : <p>No Frequently Asked Questions for that search</p>
                    }
                </div>
            </Col>
        )
    }
}

export default Faqs;