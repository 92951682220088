/**ChatBot Component */

/**Import Dependencies */
import React, {Component} from 'react';
import Olark from 'react-olark-plugin';

class ChatBot extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    render() {
        return (
            <Olark
                siteId={'9731-837-10-2942'}
                systemConfig={{ hb_dark_theme: false}}
                localeConfig={{ chatting_title: 'Now chatting'}}
            />
        )
    }
}

export default ChatBot;