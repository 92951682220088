/**Glossary Component in FAQs and Glossare */

import React, {Component} from 'react';
import {Col} from 'react-bootstrap'

class Glossary extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            glossary:[],
            searchKey: null //to be changed to Related Terms
        }
    }

    static getDerivedStateFromProps(props, state) {
        let glossary = []; //to be changed to Related Terms
        props.glossary.map(gloss => glossary.push(gloss)); //to be changed to Related Terms

        return {
            glossary: glossary, //to be changed to Related Terms
            searchKey: props.searchKey
        }
    }

    render() {
        let matchIndex = 0;
        let matchInGlossary = '' //to be changed to Related Terms
        return (
            <Col className="col col2" sm="6">
                <h3>Glossary</h3>
                <div className="results">
                    <p>No Glossary items for that search</p>
                    <h4>Related Terms</h4> {
                        this.state.glossary && this.state.glossary.map(gloss => {
                            matchIndex = String(gloss.title).toLowerCase().indexOf(String(this.state.searchKey).toLowerCase());
                            matchInGlossary = String(gloss.title).substr(matchIndex, String(this.state.searchKey).length)
                            return (
                                <div>
                                    <div className="question"> 
                                        {String(gloss.title).split(matchInGlossary)[0]}
                                        <span className="match">{matchInGlossary}</span>
                                        {String(gloss.title).split(matchInGlossary)[1]}
                                            {/* rtLARGE: NZ Top 50 (FNZ) */}
                                    </div>
                                    <div className="answer">{gloss.description} </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
            </Col>
        )
    }
}

export default Glossary