/**
 * Component for NZ Exposure.
*/

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NZExposure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            nzExposureEtfs: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let nzExposureEtfs = props.nzExposureEtfs;

        return {
            nzExposureEtfs: nzExposureEtfs
        }
    }

    componentDidMount() {
        this.props.startLoadingNzExposureEtfs();
    }

    _goToEtf(etf) {
        this.props.setCountryOfEtf(etf.code);
    }

    render() {
        return (
            <div className='etf-position'>
                <div className="hero-component">
                    <div className="slide" style={{backgroundImage: 'url(images/bg-participant.jpg)'}}>
                        <Container>
                            <Row>
                                <Col md='8' className="col-md-push-2">
                                    <h1 className="slide__heading slide__heading--large slide__heading--blue">Gain New Zealand market exposure</h1>
                                    <p>Smartshares ETFs offer diversified, low fee exposure to New Zealand equity, debt and cash markets. They are listed on NZX and also available directly <a href="/types-of-funds" className="light-blue">here</a>. NZX’s largest listed companies are market leaders and global challengers in a range of industries such as agriculture, technology and tourism.</p>
                                    <p>&nbsp;</p>
                                    <p><a href="/types-of-funds" className="btn large onlight" target="_blank"><span>View the Smartshares range of ETFs now</span></a></p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container>
                    <div className="text-center smartshares">
                        <Row>
                            <Col className="col-md-8 col-md-push-2 col-lg-6 col-lg-push-3">
                                <h2 className="big light-blue upper">
                                    <b>New Zealand is a great place to invest</b>
                                </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='10' className="col-md-push-1">
                                <Row>
                                    <Col sm='4' className="space-bottom text-left">
                                        <h3>Stable country</h3>
                                        <div>
                                            <p>Stable mixed-member proportional (MMP) government<br></br>
                                                Budget government surplus<br></br>
                                                Progressive investment focus<br></br>
                                                #1 in the world for ease of doing business <sup>1</sup><br></br>
                                                #1 in the world for lack of corruption <sup>2</sup><br></br>
                                                English speaking
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm='4' className="space-bottom text-left">
                                        <h3>Sound macro base</h3>
                                        <div>
                                            <p>Stable interest rate environment: 2.8% (10 years)<br></br>
                                                Inflation: 2%<br></br>
                                                Stable exchange rate US$ to NZ$ of 0.74
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm='4' className="space-bottom text-left">
                                        <h3>Solid growth environment</h3>
                                        <div>
                                            <p>GDP growth: 2.9%<br></br>
                                                Fastest growing population in OECD: 2.1%<br></br>
                                                Low unemployment: 4.5%
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <hr></hr>
                    <div className="text-center">
                        <Row>
                            <Col md='8' className="col-md-push-2">
                                <h2 className="dark-blue upper">
                                    <b>ETFs in the New Zealand market</b>
                                </h2>
                                <br></br>
                                <br></br>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='8' className="col-md-push-2">
                                <Row>
                                    {
                                        this.state.nzExposureEtfs.map(etf => 
                                            <Col className="col-sm-6 col-md-5 col-md-offset-1 text-left">
                                                <p className="fund-movement">
                                                    <span className="country nz"></span>
                                                    <Link className="background-arrow" 
                                                        to={`/types-of-funds/${etf.nzExposureUrlParam1}/${etf.nzExposureUrlParam2}`} onClick={e => this._goToEtf(etf)}>
                                                        <b>{etf.fund} {etf.code}</b>
                                                    </Link>
                                                </p>
                                            </Col>
                                            )
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <hr></hr>
                    <div className="text-center space-bottom">
                        <Row>
                            <Col md='8' className="col-md-push-2">
                                <h2 className="dark-blue upper">
                                    <b>About Smartshares</b>
                                </h2>
                                <p>Smartshares is owned by NZX, operator of New Zealand’s stock exchange. NZX itself is listed on NZX.</p>
                                <br></br>
                                <p><a href="about-smartshares" className="btn large onlight" target="_blank"><span>Find out more about Smartshares.</span></a></p>
                                <br></br>
                            </Col>
                        </Row>
                    </div>
                    <hr></hr>
                    <div className="text-center space-bottom">
                        <Row>
                            <Col md='8' className="col-md-push-2">
                                <Row>
                                    <Col sm='6' className="text-left">
                                        <h2 className="big light-blue upper" style={{marginTop: "0px"}}>
                                            <b>Buy now</b>
                                        </h2>
                                    </Col>
                                    <Col sm="6">
                                        <p>You can buy Smartshares ETFs directly <a href="/invest-now">here</a> or through any local <a href="/investing/find-a-participant">broker</a> in your market.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <hr></hr>
                    <div className="space-bottom">
                        <p className="small space-bottom" style={{fontSize: "70%"}}>
                            <sup>1</sup> World Banks "Doing Business 2019"
                            <br></br>
                            <sup>2</sup> Corruption Perception Index Transparency International 2017
                            <br></br>
                            <br></br>
                        </p>
                    </div>
                </Container>
            </div>
        )
    }
}

export default NZExposure