/**
 * Component for NZX Participants Section 
 * in Invest Page.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';
import ReactGA from 'react-ga';

class NZXParticipants extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
        this._goToNZXParticipants = this._goToNZXParticipants.bind(this);
    }

    _goToNZXParticipants(participant) {
        // smartshares._openExternalLink(participant.currentTarget.href)
        ReactGA.pageview('/Array');
    }

    render() {
        return (
            <div className="hero-component hero-component--inline">
                <div className="partners slide" style={{backgroundImage: "url('../images/bg-participant.jpg')"}}>
                    <Container>
                        <Row>
                            <Col sm="12">
                                <ScrollTo name="#participants" className="anchor has-sub-nav"></ScrollTo>
                                <div className="block">
                                    <img src="../images/icon-lge-participant-lge.png"></img>
                                    <h2 className="slide__heading slide__heading--large slide__heading--blue">NZX PARTICIPANTS</h2>
                                    <div>
                                        You can buy units in Smartshares funds through an NZX Participant such as a broker, just like ordinary shares. NZX Participants are accredited by NZX and are monitored on an ongoing basis for compliance with NZX Rules. Contact one of the following direct to assist with your Smartshares investment purchase.
                                    </div>
                                    <p>&nbsp;</p>
                                </div>
                                <Row>
                                    <Col sm="2" className="partner">
                                        <a href="https://www.asb.co.nz/asb-securities"
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/asb.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="http://www.craigsip.com/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/Craigs-Logo-final-(2).png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="https://www.directbroking.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/direct-booking-logo.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="https://www.forsythbarr.co.nz/" 
                                        target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img width="138" height="71" src="../images/forsyth.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="http://www.hhg.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/hamilton.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="http://www.hobsonwealth.co.nz/home" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/HWPL.png"></img>
                                        </a>
                                    </Col>
                                    <Col sm="2" className="partner">
                                        <a href="https://www.jarden.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img width="138" height="71" src="../images/jarden_direct_stacked.png"></img>
                                        </a>
                                    </Col>
                                    <Col sm="2" className="partner">
                                        <a href="https://www.jbwere.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/jbwere.png"></img>
                                        </a>
                                    </Col>
									{/*
                                    <Col sm="2" className="partner">
                                        <a href="https://www.omf.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/omf.png"></img>
                                        </a>
                                    </Col>
									*/}
                                    <Col sm="2" className="partner">
                                        <a href="https://www.sharesies.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/sharesies_grey_bg.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="http://somersetsmith.co.nz/" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/somerset.png"></img>
                                        </a>
                                    </Col>

                                    <Col sm="2" className="partner">
                                        <a href="http://www.ubs.com/global/en.html" 
                                            target="_blank" 
                                            onClick={e => this._goToNZXParticipants(e)}>
                                            <img src="../images/ubs.png"></img>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default NZXParticipants;