/**Bonds and Cash Component in Types of Funds and Legal Documents */

/**Import Dependencies */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**Import Components and CSS */
import * as smartshares from './_smartshares';

class BondsCash extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files: [
                {
                    code: 'NZB',
                    label: 'NZ Bond ',
                    fileName: 'nz_bond',
                    url: 'nzbondtrust'
                },
                {
                    code: 'NZC',
                    label: 'NZ Cash ',
                    fileName: 'nz_cash',
                    url: 'nzcashtrust'
                },
                {
                    code: 'GBF',
                    label: 'Global Bond ',
                    fileName: 'global_bond',
                    url: 'globalbondtrust'
                },
                {
                    code: 'GGB',
                    label: 'Global Government Bond ',
                    fileName: 'global_government_bond', //TBU
                    url: 'global-government-bond'
                },
                {
                    code: 'AGG',
                    label: 'Global Aggregate Bond ',
                    fileName: 'global_aggregate_bond',
                    url: 'global-aggregate-bond'
                },
                {
                    code: 'NGB',
                    label: 'S&P/NZX NZ Government Bond ',
                    fileName: 's&p_nzx_nz_government_bond',
                    url: 'ngb'
                },
            ],
            
            fileAttributes: {
                shares: "BAC",
                folder: "/document-library/fund-updates/bonds-and-cash",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },
            
            existingFiles: []     
        };
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.bac_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', etfCode);
        // this.props.setEtfCode(etfCode);
        smartshares._setEtfCode(etfCode);
    }

    render() {
        // Displays on Types of Funds Screen 
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="text-center space-bottom">
                            <img src="../images/2-opt.jpg"></img>
                        </Col>
                        <Col md="7" className="text-center bonds-and-cash">
                            <ScrollTo name="#bonds-and-cash" className="anchor"></ScrollTo>
                            <img src="../images/icon-smart-yield-lge.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">BONDS AND CASH</span>
                            </h3>
                            <p>Our Bonds and Cash ETFs offer both actively managed and fully passive ETFs made up of government and corporate bonds, interest bearing assets and other fixed interest securities across New Zealand and global markets.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue"><b>THERE ARE SIX FUNDS TO CHOOSE FROM:</b></h3>
                            <p>&nbsp;</p>
                            <Row>
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code} sm="6">
                                            <p className="fund-movement text-left">
                                                <span className="country nz"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/bonds-and-cash/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>
                                                        {`${file.label}ETF (${file.code})`}
                                                        {file.code === 'GGB' ? <sup>NEW</sup>:<></>}
                                                    </b>
                                                </Link>
                                            </p>
                                        </Col> 
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )
            
        // Displays of Legal Documents Screen
        else if(new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs-8" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">Bonds and cash</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/bonds-and-cash/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            )

        //Other Screens
        else return (<div></div>)
    }
}
export default BondsCash;