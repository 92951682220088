/**
 * Component for Sustainable Section in Benefits Page
 */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

class Sustainable extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    }

    render() {
        return (
            <div className="hero-component hero-component--lean">
                <ScrollTo name="#sustainable" className="anchor has-sub-nav"></ScrollTo>
                <div className="slide" style={{backgroundImage: "url('../images/bg-flexibility.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-flexibility-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large dark-blue">Socially Responsible</h3>
                                <div className="slide__blurb slide__blurb--large dark">
                                    Choose from six funds that address environmental, social, and governance (ESG) factors.                    
                                </div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="single">
                                            <h3 className="slide__heading slide__heading--large dark-blue">Key Benefits</h3>
                                            <p className="dark">Easily gain exposure to environmentally sustainable and socially responsible companies in one transaction.</p>
                                            <p className="dark">All companies are screened against criteria across environmental, social and good governance standards.</p>
                                            <p className="dark">With Smartshares’ six ESG funds, you can focus on global equities in Australia, Japan, Europe, US, emerging markets or the whole world.</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Sustainable;