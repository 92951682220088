/**ETF Component */

/**Import dependencies */
import React, {Component} from 'react';
import ReactGA from 'react-ga';

/**Import components and css */
import Disclaimer from './Disclaimer';
import Dividends_Table from './Dividends_Table';
import FundDetails from './FundDetails';
import Fund_Performance from './Fund_Performance';
import HeroComponent from './HeroComponent';
import InvestButton from './InvestButton';
import Key_Benefits from './Key_Benefits';
import MarketAnnouncements from './MarketAnnouncements';
import Portfolio_Characteristics from './Portifolio_Characteristics';
import PrintableHeader from './PrintableHeader';
import SubFunds from './SubFunds';
import Top_10_Holdings from './Top_10_Holldings';
import * as fundsJson from '../data/funds.json';
import ErrorBoundary from './ErrorBoundary';

import '../css/Etf.css';

class ETF extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true ,
            stock: null,
            holdings: null,
            portfolioCharacteristics: null,
            fundPerformanceGraph: null,
            etf: null,
            etfCode: null,
            investorReturns: [],
            dividends: [],
            marketAnnouncements: [],
            subFunds: [],
            span: '',
            hiddenPrintable: false
        };
    }

    static getDerivedStateFromProps(props, state){
        localStorage.setItem('etfCode', props.etfCode ? String(props.etfCode).toUpperCase() : null);
        // const etfCode = !props.etfCode ? localStorage.getItem('etfCode').toUpperCase() : String(props.etfCode).toUpperCase();
        const etfCode = props.etfCode ? String(props.etfCode).toUpperCase() : null;
        const stock = props.stocks.filter(stock => stock.stock === etfCode).map(stock => stock);
        const holdings = props.holdings;
        const portfolioCharacteristics = props.portfolioCharacteristics;
        const fundPerformanceGraph = props.fundPerformanceGraph;
        const investorReturns = props.investorReturns;
        const dividends = props.dividends;
        const marketAnnouncements = props.marketAnnouncements;
        const etf = props.etf;
        const subFunds = props.subFunds;
        const span = props.span;

        return {
            stock: stock[0],
            holdings: holdings,
            portfolioCharacteristics: portfolioCharacteristics,
            fundPerformanceGraph: fundPerformanceGraph,
            investorReturns: investorReturns,
            dividends: dividends,
            marketAnnouncements: marketAnnouncements,
            etf: etf,
            etfCode: etfCode,
            subFunds: subFunds,
            span: span,

            //State control the generation of printing buttons
            hiddenPrintable: false
            
        }
    }    

    componentDidMount() {
        ReactGA.pageview(this.props.history.location.pathname);

        const etfCode = this.props.etfCode ? String(this.props.etfCode).toUpperCase() : null;
        if((!this.props.etf && etfCode) || (this.props.etf && this.props.etf.etfDetails.url === this.props.match.params.code)) {
            this.props.startLoadingEtf(etfCode, this.props.match.params.country);
            this.props.startLoadingSubFunds(etfCode, this.props.match.params.country);
            this.props.startLoadingHoldings(etfCode);
            this.props.startLoadingPortfolioCharacteristics(etfCode);
            this.props.startLoadingFundPerformanceGraph(etfCode);
            this.props.startLoadingInvestorReturns(etfCode);
            this.props.startLoadingDividends(etfCode);
            this.props.startLoadingMarketAnnouncements(etfCode);
            
        }
        
        else {
            this.props.setEtfCodeFromUrlsParams(this.props.match.params.code, this.props.match.params.country);
        }
        
        window.scrollTo(0,0);
    }

    componentDidUpdate(prevProps, prevState) {
        const etfCode = this.props.etfCode ? String(this.props.etfCode).toUpperCase() : null;

        if(this.props.etfCode && localStorage.getItem('etfCode').toUpperCase() !== this.props.etfCode 
            || prevProps.match.params.country !== this.props.match.params.country 
            || prevProps.match.params.code !== this.props.match.params.code) {
            this.props.setEtfCodeFromUrlsParams(this.props.match.params.code, this.props.match.params.country);
        }

        if(prevProps.etfCode !== this.props.etfCode)  {
            this.props.startLoadingEtf(etfCode, this.props.match.params.country);
            this.props.startLoadingSubFunds(etfCode, this.props.match.params.country);
            this.props.startLoadingHoldings(etfCode);
            this.props.startLoadingPortfolioCharacteristics(etfCode);
            this.props.startLoadingFundPerformanceGraph(etfCode);
            this.props.startLoadingInvestorReturns(etfCode);
            this.props.startLoadingDividends(etfCode);
            this.props.startLoadingMarketAnnouncements(etfCode);
            
            window.scrollTo(0,0);
        }
    }

    render() {
        if(this.props.etfCode && this.props.etfCode.status)
            throw new Error('404 Page Not Found');
            
        
        return (
            /* For visibility control, inline styling required due to its overriding ability
            Require: Hidden layer: absolute position div with lower z-index
            Shown layer: relative position div with higher z-index */
            <div className="etf-position" style={{position: 'relative'}}>
                <div  style={{zIndex:'1', position: 'absolute', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'white'}}>
                    <div id="typeOfFunds-printable"  style={{position: 'relative'}}>

                        {this.state.etf &&
                            <PrintableHeader
                                etf = {this.state.etf}>
                            </PrintableHeader>
                        }

                        {this.state.stock && 
                            <FundDetails 
                                stock={this.state.stock}
                                hiddenPrintable={true}>
                            </FundDetails>
                        }

                        {this.state.etf && 
                            this.state.etf.etfDetails && 
                            this.state.etf.etfDetails.keyBenefits && 
                            <Key_Benefits 
                                keyBenefits={this.state.etf.etfDetails.keyBenefits}>
                            </Key_Benefits>
                        }

                        {this.state.holdings.holdings && 
                            <Top_10_Holdings 
                                holdings={this.state.holdings}
                                hiddenPrintable={true}>
                            </Top_10_Holdings>
                        }

                        {this.state.portfolioCharacteristics.portfolioCharacteristics && 
                            <Portfolio_Characteristics 
                                portfolioCharacteristics={this.state.portfolioCharacteristics}>
                            </Portfolio_Characteristics>}

                        {this.state.fundPerformanceGraph && 
                            <Fund_Performance 
                                fundPerformanceGraph={this.state.fundPerformanceGraph} 
                                investorReturns={this.state.investorReturns} 
                                hiddenPrintable={true}
                                etfCode={!this.props.etfCode ? localStorage.getItem('etfCode') : this.props.etfCode}>
                            </Fund_Performance>
                        }
                        
                    </div>
                </div>
                <div style={{zIndex:'10', position: 'relative', backgroundColor: 'white'}}>
                    {this.state.etf && 
                        <HeroComponent 
                            etf={this.state.etf}>
                        </HeroComponent>
                    }

                    {this.state.stock && 
                        <FundDetails 
                            stock={this.state.stock}>
                        </FundDetails>
                    }

                    {this.state.etf && 
                        this.state.etf.etfDetails && 
                        this.state.etf.etfDetails.keyBenefits && 
                        <Key_Benefits 
                            keyBenefits={this.state.etf.etfDetails.keyBenefits}>
                        </Key_Benefits>
                    }

                    {this.state.holdings.holdings && 
                        <Top_10_Holdings 
                            holdings={this.state.holdings}>
                        </Top_10_Holdings>
                    }

                    {this.state.portfolioCharacteristics.portfolioCharacteristics && 
                        <Portfolio_Characteristics 
                            portfolioCharacteristics={this.state.portfolioCharacteristics}>
                        </Portfolio_Characteristics>}

                    {this.state.fundPerformanceGraph && 
                        <Fund_Performance 
                            fundPerformanceGraph={this.state.fundPerformanceGraph} 
                            investorReturns={this.state.investorReturns}
                            etfCode={!this.props.etfCode ? localStorage.getItem('etfCode') : this.props.etfCode}>
                        </Fund_Performance>
                    }

                    <Disclaimer
                        asAtDate={this.state.investorReturns.asAtDate} 
                        investorReturns={this.state.investorReturns} 
                        {...this.props}>
                    </Disclaimer>

                    {this.props.dividends.length !== 0 && 
                        <Dividends_Table 
                            dividends={this.state.dividends}>
                        </Dividends_Table>
                    }

                    <MarketAnnouncements
                        marketAnnouncements={this.state.marketAnnouncements}>
                    </MarketAnnouncements>

                    {this.state.etf && 
                        <SubFunds 
                        etf={this.state.etf} 
                            subFunds={this.state.subFunds} 
                            span={this.state.span}>
                        </SubFunds>
                    }

                    <InvestButton
                    >
                    </InvestButton>
                </div>
              
            </div>
        )
    }
}

export default ETF;