/**
 * Component for Top 10 Holdings table in ETF Page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Popover, OverlayTrigger, Row, Table } from 'react-bootstrap';
import moment from 'moment';

class Top_10_Holdings extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            holdings: null,
            asAtDate: null,
            hiddenPrintable: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const holdings = props.holdings.holdings;
        const asAtDate = moment(props.holdings.asAtDate).format('DD MMMM YYYY');
        const hiddenPrintable = props.hiddenPrintable;

        return {
            holdings: holdings,
            asAtDate: asAtDate,
            hiddenPrintable: hiddenPrintable
        }
    }

    render() {
        return(
            <Container className="top-10">
                 <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="spcae-bottom">
                        <h3 className="h1 dark-blue">TOP 10 HOLDINGS</h3>
                        <p>{`The table shows holdings as at ${this.state.asAtDate && this.state.asAtDate}. Updated monthly.`}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className defaultValue='520'>
                            <Table striped className="fund-businesses">
                                <thead>
                                    <tr>
                                        <th >
                                        {(this.state.hiddenPrintable)? <span>TICKER</span>:
                                            <OverlayTrigger trigger='hover' placement='right'  overlay= {
                                                <Popover id='popover-basic' className="in right" >
                                                    <Popover.Title as='h3'>Code/Ticker</Popover.Title>
                                                    <Popover.Content>A unique code to identify each listing on a stock exchange, such as the NZX Main Board.</Popover.Content>
                                                </Popover>
                                            }><span data-toggle='popover'>TICKER</span></OverlayTrigger> 
                                        }   
                                        </th>
                                        <th className="left">ISSUER</th>
                                        <th className="left">INDUSTRY SECTOR</th>
                                        <th>WEIGHTING</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.holdings && this.state.holdings.map((holding, index) => 
                                            <tr key={index} className={index%2 === 0 ? "odd" : ""}>
                                                <td>{holding.ticker}</td>
                                                <td className="left">{holding.issuer}</td>
                                                <td className="left">{holding.industrySector}</td>
                                                <td>{Number(holding.weighting).toFixed(2)}%</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="disclaimer" style={{textAlign: "center"}}></div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row> */}

            </Container>
        )
    }
}

export default Top_10_Holdings;