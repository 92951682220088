/**Fund Details Component in ETF */

/**Import dependencies */
import domToPdf from 'dom-to-pdf';

import React, { Component } from 'react';
import { Container, Row, Col, Button, Popover, OverlayTrigger} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as CurrencyFormat from 'react-currency-format';
import moment from 'moment';

class FundDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stock: null,
            isPopoverOpen: false,
            showModal: false,
            clicked: false,
            downloading:false,
            hiddenPrintable: true
        };
    }

    static getDerivedStateFromProps(props, state) {
        const stock = props.stock;
        const hiddenPrintable = props.hiddenPrintable;
        return {
            stock: stock,
            clicked: false,
            hiddenPrintable: hiddenPrintable
        }
    }

    printDocument() {
        var element = document.getElementById('typeOfFunds-printable');

        const options = {
            filename: this.state.stock.stock+"_fact_sheet.pdf",
			compression: 'FAST',
			overrideWidth: 1250,
        };

        return domToPdf(element, options, () => {
            // callback function
           console.log('downloading');
           this.setState({downloading:false});
        });
    }

    render() {

        return (
            <Container className="fund-details">
                <Row>
                    <Col md="12" className="text-center">
                        <h3 className="h1 dark-blue">FUND DETAILS:</h3>
                    </Col>
                </Row>
                <Row>
                    <div className="details">
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>INCEPTION DATE:</b></h6>
                            <p className="data">{this.state.stock && moment(this.state.stock.inceptionDate).format('DD MMMM YYYY')}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>INDEX TRACKED:</b></h6>
                            <p className="data">{this.state.stock && this.state.stock.indexTracked}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>DIVIDEND PAYMENT DATES:</b></h6>
                            <p className="data">{this.state.stock && this.state.stock.dividendPaymentDates}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>ASSET CLASS:</b></h6>
                            <p className="data">{this.state.stock && this.state.stock.assetClass}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>GEOGRAPHIC FOCUS:</b></h6>
                            <p className="data">{this.state.stock && this.state.stock.geographicFocus}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue">
                                {(this.state.hiddenPrintable)? <b>CODE:</b>:
                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                        <Popover id='popover-basic' className="in top arrow">
                                            <Popover.Title as='h3'>Code/Ticker</Popover.Title>
                                            <Popover.Content>A unique code to identify each listing on a stock exchange, such as the NZX Main Board.</Popover.Content>
                                        </Popover>
                                    }>
                                        <b data-toggle='popover'>CODE:</b>
                                    </OverlayTrigger>
                                }
                            </h6>
                            <p className="data">{this.state.stock && this.state.stock.stock}</p>
                        </Col>
                    </div>
                </Row>
                
                <Row style={{ marginTop: '25px' }}>
                    <div className="details">
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>AS AT:</b></h6>
                            <p className="data">{this.state.stock && this.state.stock.date && moment(this.state.stock.date).format('DD/MM/YYYY')}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>MARKET PRICE:</b></h6>
                            <p className="data">${this.state.stock && this.state.stock.price}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue">
                                {(this.state.hiddenPrintable)? <b>NTA:</b>:
                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                        <Popover id='popover-basic' className='in top'>
                                            <Popover.Title as='h3'>Net tangible assets (NTA)</Popover.Title>
                                            <Popover.Content>The value of the underlying assets held by the ETF on a per unit basis.</Popover.Content>
                                        </Popover>
                                    }>
                                        <b data-toggle='popover'>NTA:</b>
                                    </OverlayTrigger>
                                }
                            </h6>
                            <p className="data">${this.state.stock && this.state.stock.nta}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue">
                                {(this.state.hiddenPrintable)? <b>GROSS DIVIDEND YIELD:</b>:
                                    <OverlayTrigger trigger='hover' placement='top' overlay={
                                        <Popover id='popover-basic' className='in top'>
                                            <Popover.Title as='h3'>Gross dividend yield</Popover.Title>
                                            <Popover.Content>Calculated using dividends paid by the ETF on a rolling 12 month period, including imputation credits, divided by the current market price.</Popover.Content>
                                        </Popover>
                                    }>
                                        <b data-toggle='popover'>GROSS DIVIDEND YIELD:</b>
                                    </OverlayTrigger>
                                }
                            </h6>
                            <p className="data">{this.state.stock && this.state.stock.grossYield !==0 ? `${Number(this.state.stock.grossYield).toFixed(2)}%` : '-'}</p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>TOTAL FUND VALUE:</b></h6>
                            <p className="data">
                                <CurrencyFormat value={Number(this.state.stock && this.state.stock.marketcap).toFixed(0)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'} />
                            </p>
                        </Col>
                        
                        <Col sm="2" className="col-xs-6">
                            <h6 className="light-blue"><b>FUND CHARGES:</b></h6>
                            <p className="data">{this.state.stock && Number(this.state.stock.fundCharges).toFixed(2)}%</p>
                        </Col>
                    </div>
                </Row>
                
                <div id={(this.state.hiddenPrintable)? "":"download-modal"} className="modal fade" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <Button className="close" aria-modal="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span>
                                </Button>
                                <h5>Please wait while your PDF downloads...</h5>
                            </div>
                        </div>
                    </div>
                </div>
                
                {(this.state.hiddenPrintable) ? 
                null :
                <Row>
                    <Col md="10" className="col-md-push-1 text-center">
                        <p>&nbsp;</p>
                        <p className="light-blue space-bottom"></p>
                        <p>
                        {/*<Link to="/download-funds/new-zealand-shares/nzg" rel="nofollow">*/}
                            <Button className="btn download disablePrint" onClick={()=>{this.setState({downloading:true}); this.printDocument()}}>
                                {this.state.downloading ? "Downloading..." : "Download"}
                            </Button>

                        {/*</Link>*/}
                            <span className="hidden-xs disablePrint">
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;  </span>
                            <span className="visible-xs disablePrint"><br></br></span>
                            <Link to="/fund-investor-report"><Button className="btn">Funds Summary</Button></Link>
                        </p>
                    </Col>
                </Row>}
                {/* <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row> */}

            </Container>
        )
    }
}

export default FundDetails;