/**
 * Component for Videos Section in About Page and NZ Core page.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';

/**
 * import components and css
 */

import '../css/Videos.css'

class VideoLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            videoLibrary: [],
            heading: null,
        }
        this._setWidth = this._setWidth.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        let videoLibrary = [];
        const heading = props.heading;
        props.videoLibrary.map(video => videoLibrary.push(video));
        return {
            videoLibrary: videoLibrary,
            heading: heading,
        }
    }


    componentDidMount() {
        this._setWidth()
        window.addEventListener('resize', this._setWidth)
    }

    _setWidth(){
        const windowWidth = window.innerWidth;
        let slickTrackWidth = 0;

        if(windowWidth >= 91 && windowWidth <= 767) {
            if((windowWidth-88)%3 === 0) {
                slickTrackWidth = windowWidth - 88;
            }
            else if((windowWidth-89)%3 === 0) {
                slickTrackWidth = windowWidth - 89;
            }
            else if((windowWidth-90)%3 === 0) {
                slickTrackWidth = windowWidth - 90;
            }
        }

        else if(windowWidth >= 768 && windowWidth <= 991) {
            slickTrackWidth = 660;
        }

        else if(windowWidth >=992 && windowWidth <= 1199) {
            slickTrackWidth = 882;
        }
        else if(windowWidth >= 1200) {
            slickTrackWidth = 885;
        }

        document.getElementsByClassName('slick-track').item(0).style.width = `${slickTrackWidth}px`;
        document.getElementsByClassName('slide slick-slide slick-active').item(0).style.width = `${slickTrackWidth/3}px`;
        document.getElementsByClassName('slide slick-slide slick-active').item(1).style.width = `${slickTrackWidth/3}px`;
        document.getElementsByClassName('slide slick-slide slick-active').item(2).style.width = `${slickTrackWidth/3}px`;
    }


    render() {
        return (
            <Container id="video-library" className="text-center">
                <h2 className="upper light-blue">
                    <b>{this.state.heading}</b>
                </h2>
                {/* <Container className="space-bottom-full"> */}
                    <Row className="space-bottom-full">
                        <Col lg="10" className="col-lg-push-1">
                            <Slider className="video-library carousel">
                                <div className="slick-track" style={{opacity: 1, transform: "translate3d(0px, 0px, 0px)"}}>
                                    {
                                        this.state.videoLibrary.map((video, index) => 
                                            <div key={index} className="slide slick-slide slick-active"  
                                                data-slick-index={index} aria-hidden="false">
                                                <a className="popup-youtube" href={video.videoLink}>
                                                    <img src={video.thumbnail} width="242"/>
                                                </a>
                                            </div>
                                        )
                                    }
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                {/* </Container> */}
            </Container>
        )
    }
}

export default VideoLibrary;