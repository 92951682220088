/**
 * Component for Risk Section in Benefits Page
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Col, Collapse, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

class Risks extends Component {
     constructor(props) {
         super(props);
         this.state = { loading: true };
     }
     
     render() {
         return (
             <div className="hero-component hero-component--lean">
                 <ScrollTo name="#risks" className="anchor has-sub-nav"></ScrollTo>
                 <div className="slide" style={{backgroundImage: "url('../images/bg-transparency.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-cube-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large light-blue">Risks</h3>
                                <div><b></b></div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="white single">
                                            <h3 className="slide__heading slide__heading--large light blue">Risks</h3>
                                            <p>Purchasing Smartshares ETFs does not guarantee a profit or assure against losses.</p>
                                            <p>Price of units can go up and down and units can trade at a discount or a premium, which means the purchase price of a unit can differ from the value of the underlying fund assets.</p>
                                            <p>A more comprehensive description of the risks in this type of investment can be found in the 
                                                <Link to="/legal-documents">{' Product Disclosure Statement'}</Link>.
                                            </p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                 </div>
             </div>
         )
     }
}

export default Risks;