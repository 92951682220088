/**All the disclaimer statements are returned from here */

/**Import dependencies */
import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import moment from 'moment';

class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        //Disclaimer under Fund Performance Table of ETF
        if (this.props.match && this.props.match.params && this.props.match.params.code) {
            let disclaimerStatement = null;
            const grossReturn = this.props.investorReturns && 
                this.props.investorReturns.returns && 
                this.props.investorReturns.returns.filter(r => r.returnType === 'Gross').map(r => r);
            disclaimerStatement = grossReturn && grossReturn[0] && grossReturn[0].disclaimer
            
            return (
                <Container className="disclaimer space-bottom">
                    <Row>
                        <Col md="12">
                            <ol className="sub-heading" style={{ listStyle: "none" }}>
                                {/* {(this.props.investorReturns.asAtDate)? */}
                                <li>
                                    <small>{disclaimerStatement && disclaimerStatement}  {this.props.investorReturns.asAtDate && "Returns as at " + moment(this.props.investorReturns.asAtDate).format('DD MMMM YYYY') + "."}</small>
                                </li>
                                {/* : <></>} */}
                            </ol>
                        </Col>
                    </Row>
                </Container>
            )
        }

        //Disclaimer at the bottom of NZ Core page
        else if (this.props && this.props.history && this.props.history.location.pathname.includes('nz-core')) {
            return (
                <div className="disclaimer light text-left smaller space-bottom-full">
                    {'Smartshares Limited is the issuer of the NZ Core Equity Trust. The Product Disclosure Statement for the NZ Core Equity Trust is available '}
                    <a href="/legal-documents">here</a>.
                </div>
            )

        }

        //Disclaimer at the bottom of Returns page.
        else {
            return (
                <Container className="space-bottom hide-print">
                    <Row>
                        {/* <Col md="12" className="text-center">
                            <p> */}
                            {/* <Link to="https://smartshares.co.nz/fund-investor-report?pdf=run" rel="nofollow"> */}
                                {/* <Button className="btn download" onClick={() => alert('Service Currently Unavailable')}> */}
                                    {/* <PDFDownloadLink document={this._creatPDFDocument()} fileName="Fund_Investor_Report.pdf">
                                        {({loading }) => (loading ? 'Loading document...' : 'Download now!')}
                                        </PDFDownloadLink> */}
                                    {/* Download
                                </Button> */}
                                {/* </Link> */}
                            {/* </p>
                        </Col> */}
                        <Col md="12" style={{ margin: "20px 0px", color: "#555967", fontSize: "12px" }}>
                            <b>DISCLAIMER</b>
                            <p style={{ lineHeight: 1.4 }}>The Zenith Fundsource Awards were issued 3 October 2019 by Zenith Investment Partners NZ Limited (NZBN 9429047462953 FSP 671331) and are determined using proprietary methodologies. The Fund Awards are solely statements of opinion and do not represent recommendations to purchase, hold or sell any securities or make any other investment decisions. The Fund Awards have been determined without taking into consideration the objectives, financial situation or needs of any specific person. Investors should seek their own independent financial advice before making any investment decision and should consider the appropriateness of any advice. Investors should obtain a copy of and consider any relevant PDS or offer document before making any investment decisions. Past performance is not an indication of future performance. Fund Awards are current for 12 months from the date awarded and are subject to change at any time. Fund Awards for previous years are referenced for historical purposes only.</p>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default Disclaimer;