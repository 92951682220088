/**
 * Component for International Shares in Types of Funds and Legal Documents pages.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Link as ScrollTo } from 'react-scroll';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares';

class InternationalShares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files: [
                {
                    code: 'EUF',
                    label: 'Europe ',
                    fileName: 'europe',
                    url: 'europe'
                },
                {
                    code: 'APA',
                    label: 'Asia Pacific ',
                    fileName: 'asia_pacific',
                    url: 'asia-pacific'
                },
                {
                    code: 'EMF',
                    label: 'Emerging Markets ',
                    fileName: 'emerging_markets',
                    url: 'emerging-markets'
                },
                {
                    code: 'TWF',
                    label: 'Total World ',
                    fileName: 'total_world',
                    url: 'total-world'
                },
                {
                    code: 'TWH',
                    label: 'Total World (NZD Hedged) ',
                    fileName: 'total_world_(nzd_hedged)',
                    url: 'twh'
                }
            ],
            
            fileAttributes: {
                shares: "INS",
                folder: "/document-library/fund-updates/international-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },

            existingFiles: [] 
        }
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.ins_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', etfCode);
        // this.props.setEtfCode(etfCode);
        smartshares._setEtfCode(etfCode);
    }

    render() {
        //Displays on Types of Funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="col-md-push-7 text-center space-bottom">
                            <img src="../images/chch.jpg"></img>
                        </Col>
                        <Col md="7" className="col-md-pull-5 text-center international-shares">
                            <ScrollTo name="#international-shares" className="anchor"></ScrollTo>
                            <img src="../images/SmartIncome_130.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">INTERNATIONAL SHARES</span>
                            </h3>
                            <p>Our International Shares ETFs offer low cost exposures to regions and countries around the world, all listed on the NZX and priced in New Zealand dollars.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue"><b>THERE ARE FIVE FUNDS TO CHOOSE FROM:</b></h3>
                            <p>&nbsp;</p>
                            <Row>
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code} sm="6">
                                            <p className="fund-movement text-left">
                                                <span className="country row"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/international-shares/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>{`${file.label}ETF (${file.code})`}</b>
                                                </Link>
                                            </p>  
                                        </Col>  
                                    )
                                }
                            </Row>
                        </Col> 
                    </Row>
                </Container>
            );

        //Displays on Legal Documents Screen 
        else if(new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs5" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">International shares</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/international-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            );
            
        // Other Screens
        else return (<div></div>);
    }
}

export default InternationalShares;