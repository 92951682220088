/**Flexibiliti Component in Benefits */

/**Import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

class Flexibility extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true};
    }

    render() {
        return (
            <div className="hero-component hero-component--lean">
                <ScrollTo name="#flexibility" className="anchor has-sub-nav"></ScrollTo>
                <div className="slide" style={{backgroundImage: "url('../images/bg-flexibility.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-flexibility-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large dark-blue">Flexibility</h3>
                                <div className="dark">
                                    <b>Smartshares units trade like individual shares. From as little as $500 up front and  $50 per month, our regular savings plan is a simple and affordable way to accumulate units.</b>
                                </div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="single">
                                            <h3 className="slide__heading slide__heading--large dark-blue">Key Benefits</h3>
                                            <p className="dark">It’s easy to sign up and apply. Buy units in Smartshares via your NZX Participant, or directly with Smartshares.</p>
                                            <p className="dark">Participate in the Smartshares regular savings plan – payments can be from as little as $50 a month and can be started or stopped at any time. </p>
                                            <p className="dark">The regular savings plan allows you to benefit from dollar cost averaging. This lessens the risk of investing a large amount in a single investment at the wrong time.</p>
                                            <p className="dark">Where distributions are paid, you have the option of taking the income or reinvesting in the same fund for no additional cost.									</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Flexibility;