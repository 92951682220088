/**
 * Component for KeyBenefits Section ETF
 */
/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Key_Benefits extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            keyBenefits: [] 
        };
    }

    static getDerivedStateFromProps(props, state) {
        let keyBenefits = [];
        props.keyBenefits.map(keyBenefit => keyBenefits.push(keyBenefit));

        return {
            keyBenefits: keyBenefits
        }
    }

    render() {
        return (
            <Container>
                 <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="space-bottom">
                        <h3 className="h1 dark-blue">Key Benefits</h3>
                        <p></p>
                           <ul>
                               {
                                   this.state.keyBenefits && this.state.keyBenefits.map(keyBenefit => 
                                        <li>{keyBenefit}</li>)
                               }
                           </ul>
                    </Col>
                </Row>
                {/* <hr></hr> */}
            </Container>
        )
    }
}

export default Key_Benefits;