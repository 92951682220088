/**Funds Image Slider Component in Home Page */

/**Import dependencies */
import React, {Component} from 'react';
import {Col, Container, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';

class Fund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, 
            width: window.innerWidth
        }
    }

    componentDidMount() {
        window.addEventListener('resize', ()=>this.setState({width: window.innerWidth}) )
    }

    _findOutMore() {
        ReactGA.event({
            category: 'Homepage Type of Funds Slider',
            action: 'Find out more',
            label: this.props.fund.link,
        }, ['newTracker', 'existingTracker'])
    }

    render(){
        return(
            <div className="slide slick-slide slick-active" 
                style={{backgroundImage: "url('/images/slide-bg-2.jpg')", width: this.state.width }} 
                data-slick-index={this.props.fund.id} aria-hidden="false">
                <Container>
                    <Row>
                        <Col md="10" xs="8" className="col-md-push-1 col-xs-push-2">
                            <h3 className="h1 slide__heading slide__heading--large light-blue">TYPES OF FUNDS</h3>
                            <div className="slide__blurb slide__blurb--large">There are 40 Smartshares Exchange Traded Funds to choose from.</div>
                            <div className="slide__icon movement-icon"><img src= {`/images/${this.props.fund.image}`}></img></div>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick white">{this.props.fund.brand_title.part1}</span>
                                <span className="brand-title-component__thin blue">{this.props.fund.brand_title.part2}</span>
                            </h3>
                            <p className="slide__blurb slide__blurb--large">
                                {this.props.fund.desc}
                                <br></br>
                            </p>
                            <Link to={`/types-of-funds${this.props.fund.link}`}>
                                <Button className="btn s157-25" onClick={() => this._findOutMore()}>
                                    <span>Find out more</span>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Fund;