/**Financial Advice Component in Legal Documents */

/**Import dependencies */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

class FinancialAdvice extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    };

    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#financial-advice" className="anchor has-sub-nav"></ScrollTo>
                    <h2 className="heading light-blue upper">
                        Financial Advice Provider Disclosure Statement
                        <br></br>
                    </h2>
                    <hr></hr>
                    <div id="SOI" className="link-wrapper">
                        <p>
                            <Link to="document-library/product-disclosure/financial-advice-provider-disclosure-statement.pdf" target="_blank" className="pdf">
                                Financial Advice Provider Disclosure Statement
                                <span>(111kb)</span>
                            </Link>
                        </p>
                        <p>
                            <Link to="document-library/product-disclosure/complaints-disclosure-statement.pdf" target="_blank" className="pdf">
                                Complaints Disclosure Statement
                                <span>(134kb)</span>
                            </Link>
                        </p>
                    </div>
            </div>
        )
    }
}

export default FinancialAdvice;