/**Authorized Financial Advisor Component */

/**Import Dependencies */
import React, {Component} from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

class Advisor extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm="10" className="col-sm-push-1">
                        <ScrollTo name="#advisor" className="anchor has-sub-nav"></ScrollTo>
                        <Row>
                            <Col sm="7" className="text-center">
                                <img src="/images/icon-lge-advisor-lge.png" alt="icon-lge-advisor-lge.png"></img>
                                <h2 className="big dark-blue">
                                    FINANCIAL ADVISER {/**Donna's change */}
                                    {/* AUTHORISED FINANCIAL ADVISER  */}
                                    {/* <span className="thin blue">{' (AFA)'}</span> */} {/**Donna's change */}
                                </h2>
                                <p>
                                    {/* Donna's Change */}
                                    You can also ask your financial adviser about Smartshares funds, as many advisers have arrangements for buying these products either through Smartshares or an NZX Participant.
                                    {/* You can also ask your financial adviser about Smartshares funds, as many AFAs have arrangements for buying these products either through Smartshares or an NZX Participant. */}
                                    <br></br>
                                    <br></br>
                                    Call Smartshares on 
                                    <ScrollTo> 0800 80 87 80 </ScrollTo>
                                    for further information.
                                </p>
                                {/* GTA89715  change the link from <a href="/adviser"> to contact us */}
                                <a href="/contact-us">
                                     <Button className="btn large onlight">
                                        Get in touch
                                    </Button>
                                <br></br>
                                <br></br>
                                </a>
                            </Col>
                            <Col sm="5">
                                <img src="/images/invest-photo-2.jpg" alt="invest-photo-2.jpg"></img>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Advisor;