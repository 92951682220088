/**
 * Component for Other_Material_Information 
 * documents section in Legal Documents Page.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

class Other_Material_Information extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    };

    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#other-material-information" className="anchor has-sub-nav"></ScrollTo>
                    <h2 className="heading light-blue upper">
                        Other Material Information
                    </h2>
                    <hr></hr>
                    <div id="PDS" className="link-wrapper">
                        <p>
                            <Link to="document-library/other-material-information/smartshares-exchange-traded-funds-other-material-information.pdf"
                                target="_blank" className="pdf">Other Material Information - Smartshares Exchange Traded Funds
                                <span>(330kb)</span>
                            </Link>
                        </p>
                        <p>
                            <Link to="document-library/other-material-information/nz-core-equity-trust-other-material-information.pdf"
                                target="_blank" className="pdf">Other Material Information - NZ Core Equity Trust
                                <span>(228kb)</span>
                            </Link>
                        </p>
                    </div>
            </div>
        )
    }
}

export default Other_Material_Information;