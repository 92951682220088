/**Core Series Fund Component in Types of Funds and Legal Docs */

/**Import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares';

class CoreSeriesFunds extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', etfCode);
        // this.props.setEtfCode(etfCode);
        smartshares._setEtfCode(etfCode);
    }

    render() {

        // Displays on Type of Funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="col-md-push-7 text-center space-bottom">
                            <img src="../images/1-opt.jpg"></img>
                        </Col>
                        <Col md="7" className="col-md-pull-5 text-center thematic-shares">
                            <ScrollTo name="#core-series" className="anchor"></ScrollTo>
                            <img src="../images/icon-smart-large-lge.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">CORE SERIES</span>
                            </h3>
                            <p>The Core Series is our low cost range of ETFs and provides investors the core building blocks for their portfolios.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue">
                                <b>THERE ARE TEN FUNDS TO CHOOSE FROM:</b>
                            </h3>
                            <p>&nbsp;</p>
                            <Row>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/new-zealand-shares/nzg" 
                                            onClick={e => this._setEtfCode('nzg')}>
                                            <b>
                                                S{'&'}P/NZX 50 ETF
                                                <span> (NZG)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/australian-shares/aus"  
                                            onClick={e => this._setEtfCode('aus')}>
                                            <b>
                                                S{'&'}P/ASX 200 ETF
                                                <span> (AUS)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/us-shares/us-500"  
                                            onClick={e => this._setEtfCode('usf')}>
                                            <b>
                                                US 500 ETF
                                                <span> (USF)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/us-shares/us-500-hedged"  
                                            onClick={e => this._setEtfCode('ush')}>
                                            <b>
                                                US 500 (NZD HEDGED) ETF 
                                                <span> (USH) </span>
                                                <sup>NEW</sup> 
                                                
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/international-shares/total-world" 
                                            onClick={e => this._setEtfCode('twf')}>
                                            <b>
                                                TOTAL WORLD ETF
                                                <span> (TWF)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/international-shares/twh"  
                                            onClick={e => this._setEtfCode('twh')}>
                                            <b>
                                                TOTAL WORLD (NZD HEDGED) ETF
                                                <span> (TWH)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/bonds-and-cash/ngb"  
                                            onClick={e => this._setEtfCode('nzb')}>
                                            <b>
                                                S{'&'}P/NZX NZ GOVERNMENT BOND ETF
                                                <span>  (NGB)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/bonds-and-cash/global-government-bond"  
                                            onClick={e => this._setEtfCode('ggb')}>
                                            <b>
                                                GLOBAL GOVERNMENT BOND ETF
                                                <span> (GGB) </span>
                                                <sup>NEW</sup> 
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/bonds-and-cash/global-aggregate-bond"  
                                            onClick={e => this._setEtfCode('agg')}>
                                            <b>
                                                GLOBAL AGGREGATE BOND ETF
                                                <span> (AGG)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p className="fund-movement text-left">
                                        <span className="country row"></span>
                                        <Link className="background-arrow" 
                                            to="/types-of-funds/bonds-and-cash/nzcashtrust"  
                                            onClick={e => this._setEtfCode('nzc')}>
                                            <b>
                                                NZ CASH ETF
                                                <span> (NZC)</span>
                                            </b>
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )
        
        // Displays on Legal Documents Screen
        else if(new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs-1" className="grid-item" style={{display: "none"}}>
                    <h3 className="bran-title-comonent" style={{display: "none"}}>
                        <span className="brand-title-component__thick dark-blue">Core Series</span>
                    </h3>
                </div>
            )
            
        //Other Screens        
        else return(<div>Core Series Funds</div>);
    }
}

export default CoreSeriesFunds;