/**FAQs and Glossary Component in About */

/**Import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll'

/**import components and css */
import Faqs from './FAQs';
import Glossary from './Glossary';
import '../css/SubMenu.css';
// import * as images from '../data/images.json'


class FaqsAndGlossary extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            key: null,
            faqs: [],
            qa:
            [
                {
                    faqId:0,
                    question:"Smartshares is now the manager of the ASB Superannuation Master Trust. How will this affect Smartshares and SuperLife members?",
                    answer:<p>The acquisition of ASB Superannuation Master Trust does not affect existing Smartshares or SuperLife members. We will continue as usual to service all members of the different schemes we manage. What will be changing is that we now have an additional retirement savings scheme and members that we will be welcoming into our fold.</p>
                },
                {
                    faqId:1,
                    question:"Why did Smartshares acquire the management of another superannuation scheme?",
                    answer:<p>We are always looking for opportunities to grow our business. The ASB Superannuation Master Trust was an ideal match for our investment values and management approach. This acquisition has strengthened our position as a leading provider of financial well-being solutions in the market.</p>
                },
                {
                    faqId:2,
                    question:"What will change for employers of existing superannuation schemes managed by Smartshares with the acquisition of the ASB Superannuation Master Trust?",
                    answer:<p>Employers with group schemes managed by Smartshares under the SuperLife brand will not be affected in anyway. We have a dedicated team looking after all our employers in our workplace savings scheme, and will continue our excellent relationship with them. <br /> FAQs for ASB Superannuation Master Trust members and employer groups can be found <a href='https://www.asb.co.nz/superannuation-master-trust'>here</a></p>
                }
            ],
            glossary: [],
          };
    }

    static getDerivedStateFromProps(state, props) {
        let faqs = [];
        let glossary = [];
        props.faqs.map(faq => faqs.push(faq));
        props.glossary.map(gloss => glossary.push(gloss));
        
        return {
            faqs: faqs,
            glossary: glossary
        }
    }

    componentDidMount() {
        if(this.state.key) 
            document.getElementById('search-term').style.background = "center center no-repeat";
        else 
            document.getElementById('search-term').style.background = "url(../images/input-bg-search-term.gif) center center auto 100% no-repeat";

    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.key && this.state.key.length >= 3 && prevState.key !== this.state.key)
            this.props.startLoadingFaqs(this.state.key);

        if(prevProps.faqs !== this.props.faqs)
            this.setState({faqs: this.props.faqs});
            
        if(prevProps.glossary !== this.props.glossary)
            this.setState({glossary: this.props.glossary});
    }

    _removePlaceHolder(event) {
        event.preventDefault();
        (event.target.style.background = "center center no-repeat");        
    }

    _displayPlaceHolder(event) {
        event.preventDefault();
        if(!this.state.key) {
            event.target.style.background = "url(../images/input-bg-search-term.gif) center center no-repeat";
            event.target.style.backgroundSize = "auto 100%";
        } 
    }

    _setSearchValue(event) {
        event.preventDefault();
        this.setState({key: event.target.value});
    }

    render(){
        return(
            <Container className="faq-component space-bottom-full">
                <Row>
                    <ScrollTo className="anchor has-sub-nav" name="#faqs"></ScrollTo>
                    <Col md="12" className="text-center">
                        <div className="full-width faqs">
                            <div className="text-center">
                               <h2 className="big light-blue"><b>FAQS AND GLOSSARY</b></h2>
                               <Row>
                                   <Col sm="12">
                                       <h3 class="upper dark-blue">Frequently Asked Questions</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">NZX/Smartshares’ taking over the management of ASB Superannuation Master Trust</Col>
                                </Row>
                               <Row className="space-bottom">
                                   <Faqs faqs={this.state.qa} staticQA={true}></Faqs>
                               </Row>
                        
                               <p>Looking for something in particular or want to find a definition? Start entering your search criteria to find the answer.</p>
                               <input id="search-term" className="form-control search-term" 
                                    style={{background: 'url(../images/input-bg-search-term.gif) center center no-repeat', 
                                        backgroundSize: 'auto 100%'}} 
                                    onClick={e => this._removePlaceHolder(e)}
                                    onBlur={e => this._displayPlaceHolder(e)}
                                    onChange={e => this._setSearchValue(e)}/>
                                <div className="caption" style={{display: 'block'}}>Start typing to search FAQS and Glossary</div>
                            </div>
                            {
                                this.state.key && this.state.key.length >= 3 &&
                                    <Row className="faq-results">
                                        <Faqs faqs={this.state.faqs} searchKey={this.state.key}></Faqs>
                                        <Glossary glossary={this.state.glossary} searchKey={this.state.key}></Glossary>
                                    </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
                
            
        )
    }
}

export default FaqsAndGlossary;