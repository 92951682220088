/**
 * Component for NZCore in Types of Funds and Legal Documents
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class NZCore extends Component {
    constructor(props){
        super(props);
        this.state={ 
            loading: true,
            files: [
                {
                    code: 'NZCore',
                    label: 'NZ Core Equity Trust',
                    fileName: 'nz_core_equity_trust'
                }
            ],
            fileAttributes: {
                shares: "NZ_CORE",
                folder: "/document-library/fund-updates/nz-core",
                filePrefix:"nz_core_equity_trust_fund_update",
                fileSuffix:".pdf"
            },
            existingFiles: []     
        };
    }
    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.nz_core_existingFiles;
        
        return {
            existingFiles: existingFiles
        }
    }
    
    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    render() {
        return (
            <div id="qs-9" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                <h3 className="brand-title-component" style={{display: "block"}}>
                    <span className="brand-title-component__thick dark-blue">NZ Core</span>
                </h3>
                {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}><Link to={`document-library/fund-updates/nz-core/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/nz_core_equity_trust_fund_update_${file.file.fileName}.pdf`}
                        target="_blank" className="pdf">
                        {`${file.file.label} - ${this.props.documentsDate}`}
                        <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                    </Link></p>)
                    }
            </div>
        )
    }
}

export default NZCore;