import * as fundsJson from "../data/funds.json";
import axios from 'axios';
import byteSize from 'byte-size';

const apiUrl = process.env.REACT_APP_SMARTSHARES_SERVICE_URL;
//const apiUrl = "https://dev-service.smartshares.co.nz";

// export function startLoadingImages(){
//     return (dispatch) => {
//         return fetch("https://devwebsm.smartshares.co.nz/jsonapi/node/images")
//             .then(response => {
//                 if(response.status === 200)
//                     return response.json()
//                 else return {status: response.status, message: response.statusText}
//             }).then(response => response.data)
//             .then(images => dispatch(getImages(images)))
//             .catch(err => console.log(err))
//     }
// }
// export function startLoadingPages(){
//     return (dispatch) => 
//         {
//             return fetch("https://devwebsm.smartshares.co.nz/jsonapi/node/about")
//                 .then(response => {
//                     if(response.status === 200)
//                         return response.json();
//                     else
//                         return {status: response.status, message:response.statusText}
//                 }).then(response => response.data)
//                 .then(pages => dispatch(getPagesData(pages)))
//                 .catch(err => console.log(err))
//         }
// }
//Returns the details of ETF given etfCode and country
export function startLoadingEtf(etfCode, country) {
    let countryArray = [];
    let funds = [];
    let etfs = [];
    let country1 = '';
    let fund = null;
    let etf = null;
    (!country || String(country).includes('smart')) && (country = startLoadingCountryOfEtf(etfCode))
    countryArray = String(country).split('-');
    countryArray.map(coun => country1 = country1.concat(`${coun} `));
    fundsJson.default.map(fund => funds.push(fund));
    fund = funds.filter(fund => String(fund.shares).toLowerCase() === country1.trim()).map(fund => fund)[0]
    fund.etfs.map(etf => etfs.push(etf));
    etf = etfs.filter(etf => etf.code === etfCode).map(etf => etf)[0]
    return (dispatch => dispatch(getEtf(fund.shares, fund.image, etf)))
}

//Returns ETF Category given etfCode
export function startLoadingCountryOfEtf(etfCode) {
    let funds = [];
    let fundDetails = null;
    fundsJson.default.map(fund => funds.push(fund));
    funds.map(fund => {
        fund.etfs.map(etf => {
            etf.code === etfCode && (fundDetails = fund)
        })
        return fundDetails;
    })
   return String(fundDetails.shares).split(' ').join('-').toLowerCase();
}

export function setCountryOfEtf(etfCode) {
    return (dispatch => dispatch(getCountryOfEtf(startLoadingCountryOfEtf(etfCode))))

}

//Returns all ETFs
export function startLoadingAllEtfs() {
    let funds = [];
    let etfs = []
    fundsJson.default.map(fund => funds.push(fund));
    funds.map(fund => etfs = etfs.concat(fund.etfs))
    return etfs;
    // return (dispatch => dispatch(getAllEtfs(etfs)))
}

export function setAllEtfs() {
    const allEtfs = startLoadingAllEtfs();
    return (dispatch => dispatch(getAllEtfs(allEtfs)))
}

//NZ Exposure 

export function startLoadingNzExposureEtfs(){
    const allEtfs = startLoadingAllEtfs();
    let nzExposureEtfs = []
    allEtfs.filter(etf => !etf.nzExposureUrl === false).map(etf => nzExposureEtfs.push(etf))

    return (dispatch => dispatch(getNzExposureEtfs(nzExposureEtfs)))
}

//Returns ETFs in a Category
export function startLoadingAllEtfsInCountry(country) {
    let funds = [];
    let etfsInCountry = [];
    let span = null;
    fundsJson.default.map(fund => funds.push(fund));
    funds.filter(fund => String(fund.shares).toLowerCase() === country).map(fund => {
        span = fund.span;
        fund.etfs.map(etf => etfsInCountry.push(etf));
        return {span, etfsInCountry}
    })
    return {etfsInCountry, span};
}

export function startLoadingSubFunds(currentEtfCode, country) {
    let etfs = []; 
    let subFunds = [];
    // const country1 = (!country || String(country).includes('smart')) && startLoadingCountryOfEtf(currentEtfCode);
    const span = startLoadingAllEtfsInCountry(String(country).split('-').join(' ')).span
    etfs = startLoadingAllEtfsInCountry(String(country).split('-').join(' ')).etfsInCountry;
    etfs.filter(etf => etf.code !== currentEtfCode).map(etf => subFunds.push(etf));
    return (dispatch => dispatch(getSubFunds(subFunds, span)));
}

export function startLoadingStocks(){
    return (dispatch) => {
        return fetch(`${apiUrl}/stocks`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
                else 
                    return {status: response.status, messgae: response.message}
            })
            .then(stocks => dispatch(getStocks(stocks)))
            .catch(err => console.log(err))
    }
}

export function startLoadingHoldings(etfCode){
    return (dispatch) => {
        return fetch(`${apiUrl}/holdings?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200){
                    
                    return response.json();

                }
                else 
                    return null
            })
            .then(holdings => dispatch(getHoldings(holdings)))
            .catch(err => console.log(err))
    }
}

export function startLoadingPortfolioCharacteristics(etfCode) {
    return (dispatch) => {
        return fetch(`${apiUrl}/portfolioCharacteristics?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
                else 
                    return {status: response.status, message: response.statusText}
            }).then(portfolio => dispatch(getPortfolioCharacteristics(portfolio)))
                .catch(err => console.log(err))
    }
}

export function startLoadingFundPerformanceGraph(etfCode) {
    return (dispatch) => {
        return fetch(`${apiUrl}/graph?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200)
                    return response.text();
                else 
                    return {status: response.status, message: response.statusText}
            }).then(graph => dispatch(getFundPerformanceGraph(graph)))
            .catch(err => console.log(err))
    }
}

export function startLoadingInvestorReturns(etfCode) {
    return (dispatch) => {
        return fetch(`${apiUrl}/returns?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
                else
                    return {status: response.status, message: response.statusText}
            }).then(investorReturns => dispatch(getInvestorReturns(investorReturns)))
            .catch(err => console.log(err))
    }
}

export function startLoadingDividends(etfCode) {
    return (dispatch) => {
        return fetch(`${apiUrl}/dividends?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
                else 
                    return null;
            }).then(dividends => dispatch(getDividends(dividends)))
            .catch(err => console.log(err))
    }
}

export function startLoadingMarketAnnouncements(etfCode) {
    return (dispatch) => {
        return fetch(`${apiUrl}/marketAnnouncements?stock=${etfCode}`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
            }).then(marketAnnouncements => dispatch(getMarketAnnouncements(marketAnnouncements)))
            .catch(err => console.log(err))
    }
}

export function filesExisting(files, fileAttributes, year, quarter){
    const axiosRequestsArray = makeAxiosRequestsArray(files, fileAttributes, year, quarter);
    let existingFiles = []
    return (dispatch) => {
        axios
            .all(axiosRequestsArray)
            .then(axios.spread((...responses) => {
                responses.map(response => {
                    if(response!==null) {
						if(response.data.type == 'application/pdf') {
							existingFiles.push({file: response.config.headers.file, fileSize: byteSize(response.data.size, 'KB')});
						}
					}
				})  
                return existingFiles
            })).then(existingFiles => dispatch(getExistingFiles(existingFiles, fileAttributes.shares)))
            .catch(err => console.log(" "))
    }
}


export function makeAxiosRequestsArray(files, fileAttributes, year, quarter){
    const folder = fileAttributes.folder
    const filePrefix = fileAttributes.filePrefix
    const fileSuffix = fileAttributes.fileSuffix
    let axiosRequests = []
	
    files.map(file => {

		let obj = axios(`${folder}/${year}${(fileAttributes.shares === 'FS' || fileAttributes.shares === 'AR') ? `/` : `/Q${quarter}/`}${filePrefix}_${file.fileName}${fileSuffix}`, {
            method: 'GET',
            responseType: "blob",
            headers : {
                file: file,
            }            
        }).catch(err => {			
			return null
		})
		
		if(obj!=null) {
			axiosRequests.push(obj)
		}
    })
	
    return axiosRequests;
}

export function startLoadingFaqs(key) {
    return (dispatch) => {
        return fetch(`${apiUrl}/faqs?key=${key}`)
            .then(response => {
                if(response.status === 200)
                    return response.json();
                else return {status: response.status, message: response.statusText}
            }).then(faqs => dispatch(getFaqs(faqs.Faqs, faqs.Glossary)))
            .catch(err => console.log(err))
    }
}

export function setSelectedYear(selectedYear) {
   return (dispatch => dispatch(getSelectedYear(selectedYear)))
}

export function setSelectedQuarter(quarter) {
    return (dispatch => dispatch(getSelectedQuarter(quarter)))
}

export function setDocumentsDate(documentsDate) {
    return (dispatch => dispatch(getDocumentsDate(documentsDate)))
}

export function setWindowWidth() {
    return (dispatch => dispatch(getWindowWidth(window.innerWidth)))
}

export function setEtfCode(etfCode) {
    return(dispatch => dispatch(getEtfCode(etfCode)))
}


export function setEtfCodeFromUrlsParams(code, country) {
    const allEtfs = startLoadingAllEtfs();
    const etfCode = allEtfs.filter(etf => etf.url === code).map(etf => etf.code)[0];
    const etfCountry = startLoadingCountryOfEtf(etfCode);
   
    if(etfCountry === country)
        return (dispatch => dispatch(getEtfCode(String(etfCode).toUpperCase())))
    else return (dispatch => dispatch(getEtfCode({status: 404, message: "Not found"})))
}


//Dispatch actions

export function getFaqs(faqs, glossary) {
    return {
        type: 'GET_FAQS',
        faqs,
        glossary
    }
}

export function getEtfCode(etfCode) {
    return {
        type: 'ETF_CODE',
        etfCode
    }
}

export function getInvestorReturns(investorReturns) {
    return {
        type: 'GET_INVESTOR_RETURNS',
        investorReturns
    }
}

export function getPortfolioCharacteristics(portfolio) {
    return {
        type: 'GET_PORTFOLIO_CHARACTERISTICS',
        portfolio
    }
}

export function getCountryOfEtf(shareName) {
    return {
        type: 'GET_SHARE_NAME',
        shareName
    }
}

export function getAllEtfs(etfs) {
    return {
        type: 'GET_ALL_ETFS',
        etfs
    }
}

export function getNzExposureEtfs(nzExposureEtfs) {
    return {
        type: 'GET_NZ_EXPOSURE_ETFS',    
        nzExposureEtfs
    }
}

export function getSubFunds(subFunds, span) {
    return {
        type: 'GET_OTHER_ETFS',
        subFunds,
        span
    }
}

export function getEtf(shares, imageUrl, etf) {
    return {
        type: 'GET_ETF',
        etfDetails: {
            share: shares,
            image: imageUrl,
            etfDetails: etf

        }
    }
}

export function getMarketAnnouncements(marketAnnouncements) {
    return {
        type: 'GET_MARKET_ANNOUNCEMENTS',
        marketAnnouncements
    }
}

export function getDividends(dividends) {
    return {
        type: 'GET_DIVIDENDS',
        dividends
    }
}

export function getFundPerformanceGraph(graph) {
    return {
        type: 'GET_FUND_PERFORMANCE_GRAPH',
        graph
    }
}

export function getHoldings(holdings) {
    return {
        type: 'GET_HOLDINGS',
        holdings

    }
}

export function getStocks(stocks) {
    return {
        type: 'GET_STOCKS',
        stocks
    }
}

export function getExistingFiles(existingFiles, shares){
    return {
        type: `GET_${shares}_EXISTING_FILES`,
        existingFiles,
        
    }
}

export function getDocumentsDate(documentsDate) {
    return {
        type: "GET_DOCUMENTS_DATE",
        documentsDate
    }
}

export function getSelectedQuarter(quarter) {
    return {
        type: "GET_SELECTED_QUARTER",
        quarter
    }
}

export function getSelectedYear(selectedYear) {
    return {
        type: "GET_SELECTED_YEAR",
        selectedYear
    }
}

export function getPagesData(pages){
    return {
        type: "GET_PAGES_DATA",
        pages
    }
}

export function getImages(images) {
    return {
        type: "GET_IMAGES",
        images
    }
}

export function getWindowWidth(windowWidth) {
    return {
        type: 'WINDOW_WIDTH',
        windowWidth
    }
}
