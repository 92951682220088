/**ESG Shares Component in Types of Funds and Legal Documents */

/**Import dependencies */
import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

/**import components and css */
import * as smartshares from './_smartshares';

class ESGShares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files: [
                
                {
                    code: 'ESG',
                    label: 'Global Equities ESG ',
                    fileName: 'global_equities_esg',
                    url: 'global-equities'
                },
                {
                    code: 'AUE',
                    label: 'Australian Equities ESG ',
                    fileName: 'australian_equities_esg', //TBU
                    url: 'aue'
                },
                {
                    code: 'USA',
                    label: 'US Equities ESG ',
                    fileName: 'us_equities_esg',
                    url: 'us-enquities'
                },
                {
                    code: 'JPN',
                    label: 'Japan Equities ESG ',
                    fileName: 'japan_equities_esg',
                    url: 'japan-equities'
                },
                {
                    code: 'EMG',
                    label: 'Emerging Markets Equities ESG ',
                    fileName: 'emerging_markets_equities_esg',
                    url: 'emerging-markets-equities'
                },
                {
                    code: 'EUG',
                    label: 'Europe Equities ESG ',
                    fileName: 'europe_equities_esg',
                    url: 'europe-equities'
                }
            ],
            
            fileAttributes: {
                shares: "ESG",
                folder: "/document-library/fund-updates/esg-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },
            
            existingFiles: [] 
        };
    }
    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.esg_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', etfCode);
        // this.props.setEtfCode(etfCode);
        smartshares._setEtfCode(etfCode);
    }

    render() {
        //Displays on Types of Funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return(
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="col-md-push-7 text-center space-bottom">
                            <img src="../images/types-photo-dividend.jpg"></img>
                        </Col>
                        <Col md="7" className="col-md-pull-5 text-center esg-shares">
                            <ScrollTo name="#esg-shares" className="anchor"></ScrollTo>
                            <img src="../images/icon-flexibility-lge (1).png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">ESG SHARES</span>
                            </h3>
                            <p>Get socially responsible international equity exposure through our diverse range of ESG Shares ETFs.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue"><b>THERE ARE SIX FUNDS TO CHOOSE FROM:</b></h3>
                            <p>&nbsp;</p>
                            <Row>
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code} sm="6">
                                            <p className="fund-movement text-left">
                                                <span className="country row"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/esg-shares/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>
                                                        {`${file.label}ETF (${file.code})`}
                                                        {file.code === 'AUE' ? <sup>NEW</sup>:<></>}
                                                    </b>
                                                </Link>
                                            </p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )

        //Displays on Legal Documents Screen 
        else if(new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs6" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">ESG shares</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/esg-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            )
        //Other Screens
        else return(<div></div>)
    }
}

export default ESGShares;