/**
 * Component for Privacy Policy Page
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Import components and css.
 */
import * as smartshares from './_smartshares';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    componentDidUpdate() {
        window.scrollTo(0,0);
    }

    _goToLink(event) {
        smartshares._openExternalLink(event.target.href, event.target.target)
    }

    render() {
        return (
            <Container className="position">
                <Row>
                    <Col sm="12" className="text-center">
                        <h1 className="dark-blue">Privacy Policy</h1>
                        <hr></hr>
                    </Col>
                </Row>
                <Row className="contact space-bottom-full">
                    <Col cm="10" className="col-sm-push-1">
                        <p>This policy sets out how Smartshares handles the information we collect about you. If you use our websites, you agree that Smartshares may collect, use, disclose and store your information in accordance with this policy.</p>
                        <p>In this policy, “<b>Smartshares</b>”, “<b>we</b>”, “<b>our</b>” and “<b>us</b>” mean Smartshares Limited.</p>
                        <p>We may change this policy at any time by posting an updated policy on our websites.</p>
                        <h3>Collection of your information</h3>
                        <p>We collect information about you when you use our websites, when you apply for a product or service, and during our relationship with you.</p>
                        <p>We may also collect information about you from third parties when you have authorised this or the information is publicly available.</p>
                        <p><p>The information we collect may include:</p></p>
                        <ul>
                            <li>Your name, address (postal and email), location and telephone number(s).</li>
                            <li>The date and time you use our websites and the URLs of the pages you visit.</li>
                            <li>The IP address for your computer – your IP address is the identifier for your computer when you are using the internet – and the domain name from which you are accessing the internet.</li>
                            <li>The operating system and browser your computer uses, and any search engine you are using.</li>
                        </ul>
                        <p>If the information you provide us includes information about another person, you confirm that they have authorised you to provide us that information.</p>
                        <h3>Use of your information</h3>
                        <p>We may use your information for the following purposes:</p>
                        <ul>
                            <li>Verifying your identity and address.</li>
                            <li>Providing you our products and services, and managing our relationship with you.</li>
                            <li>Providing you with information about our products and services (including promotional material and news) and the products and services of other members of the NZX Group.</li>
                            <li>Improving our products and services.</li>
                            <li>Conducting market research and statistical analysis.</li>
                            <li>Monitoring and improving the security of our websites, systems and networks.</li>
                        </ul>
                        <p>We may also use your information for any other purpose authorised by you, or as otherwise required or permitted by law.</p>
                        <h3>Disclosure of your information</h3>
                        <p>We may disclose your information to the following third parties:</p>
                        <ul>
                            <li>Anyone that provides us services that relate to a purpose listed above (including third parties in New Zealand and overseas).</li>
                            <li>Other members of the NZX Group, which must handle your information in accordance with this policy.</li>
                            <li>Anyone that we need to contact to carry out your instructions (including the recipient of any payment).</li>
                            <li>Government agencies and other third parties where required or permitted by law or any court.</li>
                            <li>Anyone that can help us if your use of our websites is causing us technical issues (including an attack on our websites, systems or networks).</li>
                            <li>Anyone looking to purchase our business or assets.</li>
                        </ul>
                        <p>We may also disclose your information to anyone you authorise us to disclose that information to.</p>
                        <h3>Storage and security of your information</h3>
                        <p>We may store your information ourselves, or with third parties contracted to hold the information for us in New Zealand and overseas. We will take reasonable steps to ensure that your information is protected from unauthorised access, use, modification, or disclosure (or other misuse).</p>
                        <h3>Your rights to access and correct your information</h3>
                        <p>You can ask us to provide you with the information we hold about you and request corrections to it. There may be a charge for providing you this information, but we’ll let you know if this is the case before we provide you the information.</p>
                        <p>If you would like to see the information we hold about you or request a correction, please
                            <Link to="/contact-us"> contact us</Link>.
                        </p>
                        <h3>Cookies</h3>
                        <p>Our websites place a cookie on your computer. A "cookie" is a file which allows us to track your likes, dislikes and interests as you use our websites. We may use this information to help us identify you, to personalise your experience when you visit our websites and to provide you advertisements for our products and services when you visit our and third party websites.</p>
                        <p>You can stop cookies being placed on your computer by changing the settings for your internet browser. However, some of our websites’ features will not work without cookies.</p>
                        <p>In addition, we may use third party cookies from Google Analytics for Display Advertisers. This gives us access to aggregated, anonymous information about the age, gender and interests of visitors to our websites and who has viewed our advertisements on third party websites. We may use this information to improve our websites and to better target our advertisements on our and third party websites.</p>
                        <p>You can opt out of personalised&nbsp;advertisements in your Ads Settings. Information on how to do this is available <Link target="_blank" to={`https://support.google.com/ads/answer/2662856?co=GENIE.Platform%3DAndroid&amp;hl=en`} onClick={e => this._goToLink(e)}>here</Link>.</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default PrivacyPolicy;