/**
 * Component for Mega Menu of Legal Documents.
 * Component for Menu dropdown when hovered on
 * Legal Documents in header menu.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class MegaMenu_Legal extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    _closeMegaMenu(event) {
        document.getElementsByClassName('mega-menu-wrapper-legal').item(0).style.display = 'none';
    }

    render() {
        return(
                <div className="mega-menu-wrapper mega-menu-wrapper-legal" onMouseLeave={e => this._closeMegaMenu(e)}>
                    <div className="inner">
                        <div className="nav-col col-1">
                            <ul>
                                <li className="heading"><h4><Link to="/legal-documents#legal-documents" onClick={e => this._closeMegaMenu(e)}><strong>LEGAL</strong> DOCUMENTS</Link></h4></li>
                                <li><Link to="/legal-documents#product-disclosure-statements" onClick={e => this._closeMegaMenu(e)}>PRODUCT DISCLOSURE STATEMENTS</Link></li>
                                <li><Link to="/legal-documents#fund-updates" onClick={e => this._closeMegaMenu(e)}>FUND UPDATES</Link></li>
                                <li><Link to="/legal-documents#statement-investment-policy" onClick={e => this._closeMegaMenu(e)}>STATEMENTS OF INVESTMENT POLICY AND OBJECTIVES</Link></li>
                                <li><Link to="/legal-documents#financial-advice" onClick={e => this._closeMegaMenu(e)}>FINANCIAL ADVICE PROVIDER DISCLOSURE STATEMENT</Link></li>
                                <li><Link to="/legal-documents#financial-statements" onClick={e => this._closeMegaMenu(e)}>FINANCIAL STATEMENTS</Link></li>
                                <li><Link to="/legal-documents#annual-reports" onClick={e => this._closeMegaMenu(e)}>ANNUAL REPORTS</Link></li>
                                <li><Link to="/legal-documents#other-material-information" onClick={e => this._closeMegaMenu(e)}>OTHER MATERIAL INFORMATION</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
        )
    }
}

export default MegaMenu_Legal