/**
 * Component for Quarters (Q1, Q2, Q3, Q4) buttons 
 * under Fund Updates Section in Legal Documents Page
 */

/**
 * Import dependencies 
 */
import React, {Component} from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';


class LegalDocs_Buttons extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false,
            quarter: "1"
        }
    }

    componentDidMount() {
        this.props.setSelectedQuarter(this.state.quarter);
        const documentsDate = moment().year(Number(this.props.userSelectedYear)).endOf('year').quarter(Number(this.props.userSelectedQuarter)).format('DD MMMM YYYY');
        this.props.setDocumentsDate(documentsDate);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.quarter !== this.state.quarter){
            this.props.setSelectedQuarter(this.state.quarter);
        }
        if(prevProps.userSelectedQuarter !== this.props.userSelectedQuarter || prevProps.userSelectedYear !== this.props.userSelectedYear) {
            const documentsDate = moment().year(Number(this.props.userSelectedYear)).endOf('year').quarter(Number(this.props.userSelectedQuarter)).format('DD MMMM YYYY');
            this.props.setDocumentsDate(documentsDate);
        }
    }

    _handleLegalDocQuarters(event) {
        this.setState({
            quarter: event.target.value
        });
    }

    render() {
        return (
            <Container fluid className="background">
                <Row className="buttons">
                    <Col sm={3} className='col-xs-6' >
                        <Button id="btn-q1" className="btn" value="1"
                            onClick={e => this._handleLegalDocQuarters(e)}
                            active={this.state.quarter === "1"}>Q1</Button>
                        <label className="label">{`1 January ${this.props.userSelectedYear} - 31 March ${this.props.userSelectedYear}`}</label>
                    </Col>
                    <Col className='col-xs-6' sm={3}>
                        <Button id="btn-q1" className="btn" value="2"
                            onClick={e => this._handleLegalDocQuarters(e)}
                            active={this.state.quarter === "2"}
                            >Q2</Button>
                        <label className="label">{`1 April ${this.props.userSelectedYear} - 30 June ${this.props.userSelectedYear}`}</label>
                    </Col>
                    <Col className='col-xs-6' sm={3}>
                        <Button id="btn-q1" className="btn" value="3"
                            onClick={e => this._handleLegalDocQuarters(e)}
                            active={this.state.quarter === "3"}>Q3</Button>
                        <label className="label">{`1 July ${this.props.userSelectedYear} - 30 September ${this.props.userSelectedYear}`}</label>
                    </Col>
                    <Col className='col-xs-6'sm={3}>
                        <Button id="btn-q1" className="btn" value="4"
                            onClick={e => this._handleLegalDocQuarters(e)}
                            active={this.state.quarter === "4"}>Q4</Button>
                        <label className="label">{`1 October ${this.props.userSelectedYear} - 31 December ${this.props.userSelectedYear}`}</label>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default LegalDocs_Buttons;