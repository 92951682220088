/**
 * Component for Invest Page
 */
/**
 * Import dependencies
 */
import React, {Component} from 'react';
/**
 * Import components and css
 */
import SubMenu from './SubMenu';
import '../css/AdviserForm.css';
import { Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'

/** 
 * Out-of-commission following GTA 89715
 */
class AdviserFormPage extends Component {
    constructor(props){
        super(props);
        this.state = {loading: false};
    }

    static getDerivedStateFromProps(props, state){
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount(){
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);

        const script = document.createElement("script");
        script.src = "https://paperform.co/__embed.min.js";
        document.body.appendChild(script);
    }

    componentDidUpdate() {
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    
    render(){
        return(
            <div>
                <SubMenu {...this.props}></SubMenu>
                <div className="invest-position">
                    <Container>
                        <Row className='adviser-form-row'>
                            <h1><b>Looking for smarter ways to build your investment portfolios?</b></h1>

                            <p>
                                We have been helping New Zealanders build flexible, diversified and cost-effective
                                investment portfolios since 1996. Find out how you can partner with Smartshares 
                                to create a solid foundation for your clients’ investment portfolios.
                            </p>

                            <b>Simply fill out the form below and we will be in touch.</b>
                            <br></br>
                        </Row>
                    {/* Paperform embed form */}
                    <div data-paperform-id="smartshares-smarter-portfolio"></div>

                        <Row>
                            <h3><b>Looking for more information?</b></h3>
                            <br></br>
                            <Table className='table-style' borderless={true} responsive="lg">
                                <tbody>
                                    <tr>
                                        <th><a href="/types-of-funds"><u>Explore our funds {'>'}</u></a></th>
                                        <th><a href="/fund-investor-report"><u>Returns {'>'}</u></a></th>
                                        <th><a href="/about-smartshares"><u>Who we are {'>'}</u></a></th>
                                    </tr>
                                </tbody>
                            </Table>

                            <br></br>
                            <b>Things you should know.</b>
                     
                            <p>
                                The Smartshares Exchange Traded Funds are issued by Smartshares Limited. The Product
                                Disclosure Statement is available at <a href="/legal-documents">www.smartshares.co.nz/legal-documents</a>.
                            </p>
                            <p>
                                This form collects personal information needed so that we can provide you with information 
                                about the Smartshares Exchange Traded Funds. All information is being received, collected 
                                and held by Smartshares Limited. You can see and correct this information, subject to the 
                                provisions of the Privacy Act 2020. In providing this information you acknowledge that you 
                                have read and agreed to
                                <a href="/privacy-policy"> Smartshares' Privacy Policy.</a>
                            </p>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default AdviserFormPage;