/**Diversification Component in Benefits */

/**Import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

/**Import components and css */
import '../css/Benefits.css';
import '../css/Main.css';

class Diversification extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }
    render() {
        return (
            <div className="hero-component hero-component--lean">
                <ScrollTo name="#diversify" className="anchor has-sub-nav"></ScrollTo>
                <div className="slide" style={{backgroundImage: "url('../images/bg-diversify.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-diversify-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large dark-blue">DIVERSIFICATION</h3>
                                <div className="dark">
                                    <b>With one simple NZD purchase you get an investment in a range of securities, such as listed companies or corporate bonds, spreading your risk more broadly.</b>
                                </div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="single">
                                            <h3 className="slide__heading slide__heading--large dark-blue">Key Benefits</h3>
                                            <p className="dark">
										        With one simple purchase you get an investment in a range of financial products, such as listed companies or government bonds, spreading your risk more broadly. Increased diversification aims to smooth out volatility in your investment portfolio</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Diversification;