/**Error Boundary Component */

/**Import Dependencies */
import React, { Component } from 'react';

/**Import Components and CSS*/
import '../css/ErrorBoundary.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    render() {
        if (!this.state.hasError) {
            return  this.props.children;
        }
        else return (
            <div className="errorBoundary">
                <h1 className="errorBoundary">
                    404 Page Not Found
                </h1>
                <p className="errorBoundary" style={{fontSize: '13px'}}>
                    The page you requested was not found
                </p>
            </div>
       )

    }
}

export default ErrorBoundary;