/**
 * Component for US shares in Types of Funnds and Legal Documents.
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo} from 'react-scroll';

/**
 * import components and css
 */
import * as smartshares from './_smartshares';

class USShares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            
            files: [
                {
                    code: 'USF',
                    label: 'US 500 ',
                    fileName: 'us_500',
                    url: 'us-500'
                }, {
                    code: 'USH',
                    label: 'US 500 (NZD Hedged) ',
                    fileName: 'us_500_(nzd_hedged)', // TBU
                    url: 'us-500-hedged'
                },{
                    code: 'USG',
                    label: 'US Large Growth ',
                    fileName: 'us_large_growth',
                    url: 'us-large-growth'
                },{
                    code: 'USV',
                    label: 'US Large Value ',
                    fileName: 'us_large_value',
                    url: 'us-large-value',
                }, {
                    code: 'USM',
                    label: 'US Mid Cap ',
                    fileName: 'us_mid_cap',
                    url: 'us-mid-cap'
                }, {
                    code: 'USS',
                    label: 'US Small Cap ',
                    fileName: 'us_small_cap',
                    url: 'us-small-cap'
                }
            ],
            
            fileAttributes: {
                shares: "US",
                folder: "/document-library/fund-updates/us-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },
            
            existingFiles: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.us_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount() {
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userSelectedQuarter !== prevProps.userSelectedQuarter || this.props.userSelectedYear !== prevProps.userSelectedYear)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', String(etfCode).toLowerCase());
        // this.props.setEtfCode(String(etfCode).toLowerCase());
        smartshares._setEtfCode(etfCode)
    }

    render() {
        //Displays on Types of Funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row className="space-bottom">
                        <Col md="5" className="text-center space-bottom">
                            <img src="../images/types-photo-property.jpg"></img>
                        </Col>
                        <Col md="7" className="text-center us-shares">
                            <ScrollTo name="#us-shares" className="anchor"></ScrollTo>
                            <img src="../images/icon-smart-large-lge.png"></img>
                            <h3 className="h1 brand-title-component">
                                <span className="brand-title-component__thick dark-blue">US SHARES</span>
                            </h3>
                            <p>Our US Shares ETFs offer low cost access to a broad range of listed US companies through New Zealand listed funds.</p>
                            <p>&nbsp;</p>
                            <h3 className="dark-blue"><b>THERE ARE SIX FUNDS TO CHOOSE FROM:</b></h3>
                            <p>&nbsp;</p>
                            <Row> 
                                {
                                    this.state.files.map(file => 
                                        <Col key={file.code} sm="6">
                                            <p className="fund-movement text-left">
                                                <span className="country row"></span>
                                                <Link className="background-arrow" 
                                                    to={`/types-of-funds/us-shares/${String(file.url).toLowerCase()}`} 
                                                    onClick={e => this._setEtfCode(file.code)}>
                                                    <b>
                                                        {`${String(file.label).toUpperCase()}ETF (${file.code})`}
                                                        {file.code === 'USH' ? <sup>NEW</sup>:<></>}
                                                    </b>
                                                </Link>
                                            </p>
                                        </Col>    
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            );

        //Displays on Legal Documents Screen
        else if (new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs4" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">US shares</span>
                    </h3>
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/us-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            );

        //Other Screens
        else return(<div></div>);
    }
}

export default USShares;