/**Apply Online Component in Invest */

/**Import dependencies */
import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import ReactGA from 'react-ga';

/**Import Components and CSS */
import * as smartshares from './_smartshares';
import '../css/Invest.css';

class ApplyOnline extends Component {
    
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    _openApplication(event) {
        // smartshares._openExternalLink(event.target.parentElement.href, event.target.parentElement.target)
        ReactGA.pageview('/linkinvestorservices')
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm="12" className="text-center">
                        <p>&nbsp;</p>
                        <h1 className="dark-blue">Choose from one of four easy investment options.</h1>
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <ScrollTo name="#apply-online" className="anchor has-sub-nav"></ScrollTo>
                    <Col md="12">
                        <Row>
                            <Col sm="10" className="col-sm-push-1">
                                <Row>
                                    <Col sm="4">
                                        <Col className="img">
                                            <img className="invest-img" src="../images/businessman-laptop.jpg"></img>
                                            <p>&nbsp;</p>
                                        </Col>
                                    </Col>
                                    <Col sm="8" className="text-center">
                                        <ScrollTo name="smart-large" className="anchor has-sub-nav"></ScrollTo>
                                        <img src="../images/icon-lge-online-lge.png"></img>
                                        <h2 className="big dark-blue">APPLY ONLINE</h2>
                                        <p>
                                            Invest now in your choice of ETF. If you are a new investor, you can put as little as $500 in each Smartshares ETF. If you like a more regular savings approach, you can put aside as little as $50 each month to build your ETF investment.
                                            <br></br>
                                            <br></br>
                                            The set-up fee is $30 for a new investor. You don’t pay fees for subsequent contributions. However, if you buy units in Smartshares ETFs in the market through an NZX participant, or a financial adviser, you may be charged a fee for their services.
                                            <br></br>
                                            <br></br>
                                            To learn more about what you are investing in, please read and understand the
                                            <Link to="/legal-documents"> Product Disclosure Statement</Link>
                                            . Click on the Apply Online link and submit your completed form.
                                            <br></br>
                                            <br></br>
                                            Please note: the offer set out in the
                                            <Link to="/legal-documents"> Product Disclosure Statement</Link>
                                            is only available to New Zealand residents.
                                        </p>
                                        <a href="https://smartshares.linkinvestorservices.co.nz/forms/app" 
                                            target="_blank"
                                            onClick={() => this._openApplication()}>
                                            <Button className="btn large onlight">
                                                Apply online
                                            </Button>
                                        </a>
                                        <hr></hr>
                                        <p>
                                            Smartshares Limited is a member of the Financial Services Complaints Limited Scheme (our supervisor, Public Trust, is also a member), which is an independent dispute resolution scheme.
                                            <br></br>
                                            <br></br>
                                            If you make a complaint to us (or the supervisor), and the complaint cannot be resolved, you may refer it to Financial Services Complaints Ltd (FSCL) - A Financial Ombudsman Service. FSCL is our independent external ombudsman and dispute resolution service. 
                                            <br />
                                            <br />
                                            Financial Services Complaints Limited 
                                            <br />
                                            PO Box 5967 Wellington 6140 
                                            <br />
                                            Telephone: 0800 347 257
                                            <br /> 
                                            Email: complaints@fscl.org.nz
                                            <br></br>
                                            <br></br>
                                            The Financial Services Complaints Limited Scheme will not charge you a fee to investigate or resolve a complaint.                            
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ApplyOnline;