/**
 * Component for Terms of Use page.
 */
/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <Container className="position">
                <Row>
                    <Col sm="12" className="text-center">
                        <h1 className="dark-blue">Website Terms of Use</h1>
                        <hr></hr>
                    </Col>
                </Row>
                <Row className="contact space-bottom-full">
                    <Col sm="12">
                        <p>These terms set out the rules that apply to the use of this website. If you use this website, you agree that these terms and our <Link to="/privacy-policy">Privacy Policy</Link> apply.</p>
                        <p>In these terms, “<b>Smartshares</b>”, “<b>we</b>”, “<b>our</b>” and “<b>us</b>” mean Smartshares Limited.</p>
                        <p>We may change these terms at any time by posting updated terms on this website.</p>
                        <h3>New Zealand products and services</h3>
                        <p>The information on this website has been prepared under New Zealand law for the supply of products and services in New Zealand only.</p>
                        <h3>Not investment advice</h3>
                        <p>The information on this website is of a general nature and does not take into account your particular financial situation or goals. The information is not investment advice.</p>
                        <h3>Website content</h3>
                        <p>We have taken reasonable steps to ensure that the information on this website is accurate and up-to-date. However, before you rely on any information on this website you should independently verify its relevance for your purpose. We do not accept any responsibility for any error or omission or for any loss resulting from the use of this information, except to the extent required by law.</p>
                        <p>We may change the information on this website at any time.</p>
                        <p>To the fullest extent permitted by law, Smartshares makes no warranties or representations (express or implied), and is not liable (in negligence, tort, breach of contract, or otherwise) for any loss or damage (including direct or indirect loss, or special or consequential loss), arising out of or in connection with the availability or your use of this website.</p>
                        <h3>Intellectual property</h3>
                        <p>We own all intellectual property rights in this website (including all information, data, text, graphics, artwork, photographs, logos, icons, sound recordings and videos).</p>
                        <p>You may not use, copy or modify this website for any purpose other than to view information about our products and service and Smartshares.</p>
                        <h3>Links</h3>
                        <p>This website includes links to websites operated by third parties which are not under our control. We provide these links for your convenience only and are not responsible for the information on any linked website.</p>
                        <p>You may not link to this website without our consent. If you would like to link to this website, please <Link to="/contact-us">contact us</Link>.</p>
                        <h3>Security</h3>
                        <p>You must not do anything that may compromise the security of this website or our systems and networks (including introducing any virus, worm, Trojan horse, timebomb, keystroke logger, spyware or other similar feature).</p>
                        <h3>Governing law</h3>
                        <p>This website is governed by, and is to be interpreted in accordance with, New Zealand law.</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default TermsOfUse;