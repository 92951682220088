import React, {Component, useRef} from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Headings from './Headings';
import { Link as ScrollTo} from 'react-scroll';
import Dropdown from 'react-dropdown';

import Product_Disclosure_Statements from './Product_Disclosure_Statements';
import Statement_Investment_Policy from './Statement_Investment_Policy';

import '../css/LegalDocs.css';
import FinancialStatements from './FinancialStatements';
import AnnualReports from './AnnualReports';
import LegalDocs_Buttons from './LegalDocs_Buttons';
import NZShares from './NZShares';
import CoreSeriesFunds from './CoreSeriesFunds';
import AUSShares from './AUSShares';
import USShares from './USShares';
import InternationalShares from './InternationaShares';
import ESGShares from './ESGShares';
import ThematicShares from './ThematicShares';
import BondsCash from './BondsCash';
import NZCore from './NZCore';
import FinancialAdvice from './FinancialAdvice';
import PropertyShares from './PropertyShares';
import Other_Material_Information from './Other_Material_Information';

class LegalDocs extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false, 
            selectedYear: "2024", //GTA-80033
            hashID: null
           };
    }

    static getDerivedStateFromProps(props, state) {
        const hashID = props.history.location.hash;
        return {
            hashID: hashID,
        }
    }

    componentDidMount() {
        this.props.setSelectedYear(this.state.selectedYear);
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.selectedYear !== this.state.selectedYear){
            this.props.setSelectedYear(this.state.selectedYear)
        }
    }

    _onSelect(event){
        this.setState({selectedYear: event.value})
    }

    render(){
        const options = [
            {
                label: "2024",
                value: "2024",
                className: this.state.selectedYear === "2024" ? "dropdown-options" : "dropdown-options-padding"

            },  
            {
                label: "2023",
                value: "2023",
                className: this.state.selectedYear === "2023" ? "dropdown-options" : "dropdown-options-padding"

            },  
            //GTA-8033 - starts    
			{
                label: "2022",
                value: "2022",
                className: this.state.selectedYear === "2022" ? "dropdown-options" : "dropdown-options-padding"

            }, 
	        //GTA-70266 - starts    
			{
                label: "2021",
                value: "2021",
                className: this.state.selectedYear === "2021" ? "dropdown-options" : "dropdown-options-padding"

            }, 
	        //GTA-70266 - ends    
            {
                label: "2020",
                value: "2020",
                className: this.state.selectedYear === "2020" ? "dropdown-options" : "dropdown-options-padding"
            },
            {
                label: "2019",
                value: "2019",
                className: this.state.selectedYear === "2019" ? "dropdown-options" : "dropdown-options-padding"
            },
            {
                label: "2018",
                value: "2018",
                className: this.state.selectedYear === "2018" ? "dropdown-options" : "dropdown-options-padding"

            },
            {
                label: "2017",
                value: "2017",
                className: this.state.selectedYear === "2017" ? "dropdown-options" : "dropdown-options-padding"
            },
            {
                label: "2016",
                value: "2016",
                className: this.state.selectedYear === "2016" ? "dropdown-options" : "dropdown-options-padding"
            },
        ]
        const defaultOption = options[0];

        return(
            <div id="legalDocuments" className="legal-documents legalDocuments-position">
                <Container>
                        <Row>
                            <Col md="12" className="text-center">
                                <ScrollTo name="#legal-documents" className="text-center"></ScrollTo>
                                    <Headings {...this.props}></Headings>
                                    <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            <div className="row-height">
                                <Col md="6" className="col-md-height col-md-top">
                                    <Product_Disclosure_Statements></Product_Disclosure_Statements>
                                </Col>
                                <Col md="6" className="col-md-height col-md-top">
                                    <Statement_Investment_Policy></Statement_Investment_Policy>
                                </Col>
                            </div>
                        </Row>
                        
                        <Row className="space-bottom">
                            <div className="row-height">
                            <Col md="6" className="col-md-height col-md-top">
                                    <Other_Material_Information></Other_Material_Information>
                            </Col>
                            <Col md='6' className="col-md-height col-md-top">
                                <div className="smartshares-forms">
                                    <ScrollTo name="#forms" className="anchor has-sub-nav"></ScrollTo>
                                    {/* <hr></hr> */}
                                    <FinancialAdvice></FinancialAdvice>
                                    {/* <div className="link-wrapper row"></div> */}
                                </div>
                            </Col>
                            </div>
                        </Row>
                        <Row>
                            <Col md="12" className="controls text-center">
                                <Container fluid className="background">
                                    <Dropdown id="yeardd"
                                        className="dropdowns btn-group bootstrap-select"
                                        controlClassName="btn dropdown-toggle btn-default"
                                        placeholderClassName= "filter-option pull-left"
                                        arrowClassName="caret" 
                                        menuClassName="dropdown-menu dropdown-menu-style"                                        
                                        options={options} 
                                        value={this.state.selectedYear} 
                                        onChange={e => this._onSelect(e)} placeholder="Select an option" />
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <div className="row-height">
                                <Col md="6" className="col-md-height col-md-top">
                                    <FinancialStatements {...this.props}></FinancialStatements>
                                </Col>
                                <Col md="6" className="col-md-height col-md-top">
                                    <AnnualReports {...this.props}></AnnualReports>
                                </Col>
                            </div>
                        </Row>
                        <Row>
                            <Col md="12" className="text-center">
                                <ScrollTo name="#fund-updates" className="anchor has-sub-nav"></ScrollTo>
                                <h2 className="heading light-blue upper title">Fund Updates</h2>
                                {/* <hr></hr> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="controls text-center">
                                <LegalDocs_Buttons {...this.props}></LegalDocs_Buttons>
                            </Col>
                        </Row>
                        <Row className="grid">
                            <Col sm="3">
                                <CoreSeriesFunds {...this.props}></CoreSeriesFunds>
                                <NZShares {...this.props}></NZShares>
                                <PropertyShares {...this.props}></PropertyShares>
                            </Col>
                            <Col sm="3">
                               <AUSShares {...this.props}></AUSShares>
                               <USShares {...this.props}></USShares>
                            </Col>
                            <Col sm="3">
                               <InternationalShares {...this.props}></InternationalShares>
                               <ESGShares {...this.props}></ESGShares>
                            </Col>
                            <Col sm="3">
                               <ThematicShares {...this.props}></ThematicShares>
                               <BondsCash {...this.props}></BondsCash>
                               <NZCore {...this.props}></NZCore>
                            </Col>
                        </Row>
                    </Container>

            </div>
        )
    }
}

export default LegalDocs;