/**
 * Component for NZ Core Fund Page
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

/**
 * Import components and css
 */
import Top_10_Holdings from './Top_10_Holldings';
import Disclaimer from './Disclaimer';
import VideoLibrary from './VideoLibrary';
import '../css/NZCoreFunds.css'

class NZCoreFunds extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            heading: "Videos",
            videos: [
                {
                    videoLink: "//www.youtube.com/watch?v=OhQKZnnpPj0",
                    thumbnail: "/images/youtube-thumbnail-OhQKZnnpPj0.jpg"
                },
                {
                    videoLink: "//www.youtube.com/watch?v=Rggu7Tq9_74",
                    thumbnail: "/images/youtube-thumbnail-Rggu7Tq9_74.jpg"
                },
                {   
                    videoLink: "//www.youtube.com/watch?v=nS6HAzOEJgM",
                    thumbnail: "/images/youtube-thumbnail-nS6HAzOEJgM.jpg"
                }
            ],
            holdings: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let holdings = props.holdings;

        return {
            holdings: holdings,
        }
    }

    componentDidMount() {
        this.props.startLoadingHoldings('NZ*');
    }

    render() {
        return (
            <div className="etf-position">
            <div className="hero-component hero-component--carousel">
                <div className="slide" style={{backgroundImage:'url(/images/nz-core-header.jpg)'}}>
                    <Container>
                        <Row>
                            <Col md='12'>
                                <div className="blue-box">
                                    <h1 className="brand-title-component">
                                        <span className="brand-title-component__thick white">NZ </span>
                                        <span className="brand-title-component__thick dark-blue">CORE</span>
                                    </h1>
                                    <h3><b>EQUITY TRUST</b></h3>
                                    <p className="bigger">The NZ Core Fund provides long-term capital growth by gaining exposure
                                        <br></br>to a diversified portfolio of equity securities listed in New Zealand.
                                    </p>
                
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    </div>
                </div>
            <Container>
                <Row className="space-bottom">
                    <Col md='12' className="text-center">
                        <p>
                            The NZ Core Equity Trust aims to provide investors with the benefits of long-term capital growth by investing in a diversified portfolio of financial products listed in New Zealand, with increased exposure to small companies and value companies relative to a market weighted portfolio.
                            <br></br> 
                            <br></br>
                            
                            Value companies are companies with a low price relative to various financial measures, including book value, cash flow and earnings.
                            <br></br>
                            <br></br>
                            DFA Australia Limited is the investment manager for NZ Core Equity Trust. DFA Australia Limited is the Australian subsidiary of Dimensional Fund Advisors LP, which is based in the United States and was formed in 1981.
                        </p>
                    </Col>    
                </Row>
                <Row>
                    <VideoLibrary heading={this.state.heading} videoLibrary={this.state.videos}></VideoLibrary>
                </Row>
                <Row>
                    <Col md='12'>
                        <hr></hr>
                        <Row>
                            <Col md='12' className="text-center" style={{verticalAlign: 'top'}}>
                                <a className="nz-core-pdf-link first-child multi-line pdf" 
                                    href="/document-library/nz-core/NZ_Core_Equity_Trust_Unit_Prices.pdf" target="_blank"> {/**GTA-79813*/}
                                    NZ Core Unit Prices
                                </a>
                                <a className="nz-core-pdf-link multi-line pdf" 
                                    href="/document-library/nz-core/NZ_Core_Distribution_History.pdf" target="_blank"> {/**GTA-79813*/}
                                    Distribution History
                                </a>
                                <a className="nz-core-pdf-link pdf" 
                                    href="/document-library/nz-core/NZ_Core_Equity_Trust_Fact_Sheet.pdf" target="_blank"> {/**GTA-79813*/}
                                    Fact Sheet
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Top_10_Holdings holdings={this.state.holdings}></Top_10_Holdings>
                </Row>
                <Row>
                    <Col md='12'>
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' className="text-center">
                        <hr></hr>
                        <h3 className="h1 dark-blue upper">How To Apply</h3>                    
                        <p>You can only invest in the NZ Core Equity Trust through a custodial service provider authorised by us. The custodial service provider will be the registered owner of your units in the NZ Core Equity Trust, while you will be the beneficial owner.

                            To invest in the NZ Core Equity Trust, talk to your financial adviser, as many financial advisers have arrangements with custodial service providers authorised by us that allow their clients to invest.
                        </p>
                        <hr></hr>
                        <Disclaimer {...this.props}></Disclaimer>
                    </Col>
                </Row>
            </Container>    
            </div>
        )
    }
}

export default NZCoreFunds;