/**
 * Component for Transparency/Convinience Section in Benefits Page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';

/**
 * Import components and css
 */
import * as smartshares from './_smartshares';

class Transparency extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    _goToNzx(event) {
        smartshares._openExternalLink(event.target.parentElement.href, event.target.parentElement.target);
    }

    render() {
        return (
            <div className="hero-component hero-component--lean">
                <ScrollTo name="#transparency" className="anchor has-sub-nav"></ScrollTo>
                <div className="slide" style={{ backgroundImage: "url('../images/bg-transparency.jpg')" }}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-cube-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large light-blue">Convenience</h3>
                                <div><b>Build a global portfolio in NZD.</b></div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="white single">
                                            <h3 className="slide__heading slide__heading--large light-blue">Key Benefits</h3>
                                            <p>The funds enable you to build a global portfolio in NZD, without having to worry about the complexity of managing foreign currencies or overseas tax. </p>
                                            <p>Distributions are paid in NZD and automatically reinvested for you, unless you choose to receive them in cash.</p>
                                        </Col>
                                        <div className="button-wrapper">
                                            <a href="http://nzx.com" target="_blank">
                                                <Button className="btn large" onClick={e => this._goToNzx(e)}>NZX.com</Button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default Transparency;