/**
 * Component for ETF list displayed at the
 * bottom of every etf page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SubFunds extends Component {
    constructor (props){
        super(props);
        this.state = { 
            loading: true, 
            subFunds: [], 
            span: '' 
        };
    }

    static getDerivedStateFromProps(props, state) {
        let subFunds = [];
        const span = props.span;
        props.subFunds.map(fund => subFunds.push(fund));
        return {
            subFunds: subFunds,
            span: span
        }
    }

    render() {
        return (
            <Container className="sub-funds space-bottom">
                 <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="text-center">
                        <h2 className="brand-title-component space-bottom">
                            <span className="brand-title-component__thick dark-blue">{this.props.etf.share}</span>
                            <span className="brand-title-component__thin light-blue"></span>
                        </h2>
                        <Row>
                            <Col sm="10" md="8" className="col-sm-push-1 col-md-push-2 text-left">
                                <Row>
                                    {
                                        this.state.subFunds.map(subFund => 
                                            <Col sm="6">
                                                <p className="fund-movement">
                                                    <span className={this.state.span}></span>
                                                    <Link className="background-arrow" 
                                                        to={subFund.url}>
                                                        {String(subFund.fund).toUpperCase()}
                                                    </Link>
                                                </p>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default SubFunds;