/**Annual Reports Component in Legal Documents */

/**Import Dependencies */
import React, {Component} from 'react';
import { Link as ScrollTo } from 'react-scroll';
import { Link } from 'react-router-dom';

class AnnualReports extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            files: [
                {
                    label: 'Annual Report - NZ Core Equity Trust',
                    fileName: 'nz_core_equity_trust'
                },
                {
                    label: 'Annual Report - Smartshares Exchange Traded Funds',
                    fileName: 'smartshares_exchange_traded_funds'
                },
                {
                    label: 'Annual Report - Smartshares KiwiSaver Scheme',
                    fileName: 'smartshares_kiwisaver_scheme'
                }
            ],
            fileAttributes : {
                shares: "AR",
                folder: "/document-library/annual-reports",
                filePrefix:"annual_report",
                fileSuffix:".pdf"
            },
            existingFiles: [],
            year: null            
        };
    }
    
    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.ar_existingFiles;
        const year = props.userSelectedYear;

        return {
            existingFiles: existingFiles,
            year: year
        }
    }

    componentDidMount(){
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userSelectedYear !== this.props.userSelectedYear || prevProps.userSelectedQuarter !== this.props.userSelectedQuarter)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }
    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#annual-reports" className="anchor has-sub-nav"></ScrollTo>
                <h2 className="heading light-blue upper">Annual Reports</h2>
                <hr></hr>
                <div id="AR" className="link-wrapper">
                    {						
                        //GTA-70092 - starts
                        this.state.existingFiles.length === 0 ? 
                            <p>The {this.state.year} annual reports for Smartshares Exchange Traded Funds and NZ Core Equity Trust will be available by 30 June {this.state.year}</p>
                        //GTA-70092 - ends
                        : this.state.existingFiles.map((file, index) => 
                                <p key={index}>
                                    <Link to={`document-library/annual-reports/${this.props.userSelectedYear}/annual_report_${file.file.fileName}.pdf`}
                                        target="_blank" 
                                        className="pdf">
                                        {file.file.label} {this.state.year}
                                        <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                    </Link>
                                </p>
                            )
                    }
                </div>
            </div>
        )
    }
}

export default AnnualReports;