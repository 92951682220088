/**Benefits Component */

/**Import Dependencies */
import React, {Component} from 'react';

/**Import Components and CSS */
import Diversification from './Diversification';
import Flexibility from './Flexibility';
import Headings from './Headings';
import InvestButton from './InvestButton';
import LowerFees from './LowerFees';
import Risks from './Risks';
import SubMenu from './SubMenu';
import Sustainable from './Sustainable';
import Transparency from './Transparency';

class Benefits extends Component {
    constructor(props){
        super(props);
        this.state = { loading: false };
    }
    
    static getDerivedStateFromProps(props, state) {
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount(){
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate() {
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    render(){
        return(
            <div>
                {/* <SubMenu {...this.props}></SubMenu> */}
                <div className="benefits-position">
                    <Headings {...this.props}></Headings>
                    <Diversification></Diversification>
                    <LowerFees></LowerFees>
                    <Flexibility></Flexibility>
                    <Transparency></Transparency>
                    <Sustainable></Sustainable>
                    <Risks></Risks>
                    <InvestButton></InvestButton>
                </div>
            </div>
           
        )
    }
}

export default Benefits;