/**Features Component in HomePage */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class ResearchIP extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        return (
            <div class="container">
            <Container className="researchIP">
                <div className="text-center">
                    <h3>Build the future you want with our award-winning team</h3>
                </div>
                <div className="col4 text-center">
                    
                        <img src="/images/Research-IP-FMOY-2022-Fund-Manager-of-the-Year.png"></img>
                    
                        <img src="/images/Research-IP-FMOY-2022-Australian-Equities.png"></img>

                        <img src="/images/Research-IP-FMOY-2022-Global-Equities.png"></img>
                    
                        <img src="/images/Research-IP-FMOY-2022-Longevity-Award.png"></img>
                    
                </div>
                <div className="text-center">
                    <p>Fund Manager of the Year Awards were announced by Research IP on 16 November 2022. These awards should not be read as a recommendation by Research IP.
                        <br></br>For further advice on the relevance of this award to your personal situation, please consult your authorised financial adviser, or visit  
                        <a href='https://research-ip.com/awards/' title="research-ip.com/awards" target="_blank"> research-ip.com/awards.</a></p>
                </div>
            </Container>
            </div>
        )
    }
}

export default ResearchIP;