/**Component for Home Page of Smartshares */

/**import dependencies*/
import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactGA from 'react-ga'

/**Import Components and css */
import Features from './Features';
import FundSlides from './FundSlides';
import ImageSlides from './ImageSlides';
import Quote_Component from './Quote_Component';
import HowtogetSmartshares from './HowtogetSmartshares';
import KiwiSaver from './KiwiSaver';

import '../css/Slick_Dots.css';
import ResearchIP from './ResearchIP';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }
    
    componentDidMount() {
        ReactGA.pageview(this.props.history.location.pathname);
        window.scrollTo(0,0);
    }

    render() {
        return( 
            <div className="homePage">
                <ImageSlides {...this.props}></ImageSlides>
                <Features></Features>
                <Container><Row><hr></hr></Row></Container>
                <Quote_Component></Quote_Component>
                <Container><Row><hr></hr></Row></Container>
                <ResearchIP></ResearchIP>
                <FundSlides></FundSlides>
                <HowtogetSmartshares></HowtogetSmartshares>
                <KiwiSaver {...this.props}></KiwiSaver>
            </div>
        )
    }
}

export default HomePage;