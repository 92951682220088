/**Contact Component */

/**Import Dependencies */
import React, {Component} from 'react';

/**Import Components and CSS */
import ContactForm from './ContactForm';
import Headings from './Headings';

import '../css/Forms.css'

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {loading: true}
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render(){
        return(
            <div className="contactus-position">
                <Headings {...this.props}></Headings>
                <ContactForm></ContactForm>
            </div>
        )
    }
}

export default Contact;