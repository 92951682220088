/**Returns Table */

/**Import dependencies */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table } from 'react-bootstrap';
import * as CurrencyFormat from 'react-currency-format';

class Fund_Investor_Report_Table extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            stocks: [],
            allEtfs: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        let stocks = [];
        const allEtfs = props.allEtfs;
        props.stocks.forEach(stock => {
            return stocks.push(stock)
        })
        return {
            stocks: stocks,
            allEtfs: allEtfs
        }
    }

    _goToEtf(etfCode) {
        this.props.setCountryOfEtf(etfCode); //sets the country of etf and Redirects to ETF Page.
    }

    render() {
        return (
            <Container>
                <Row className="space-bottom">
                    <Col md="12">
                        <Table responsive striped className="fund-investor-report-table table">
                            <thead>
                                <tr>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>FUND NAME</th>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>Fund Charges</th>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>NTA</th>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>Dividend Payment Date</th>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>Gross Dividend Yield</th>
                                    <th className="blue" style={{verticalAlign: 'bottom'}}>Total Fund Value</th>
                                    <th style={{verticalAlign: 'bottom'}}>1 month</th>
                                    <th style={{verticalAlign: 'bottom'}}>3 months</th>
                                    <th style={{verticalAlign: 'bottom'}}>1 year</th>
                                    <th style={{verticalAlign: 'bottom'}}>3 years annualised</th>
                                    <th style={{verticalAlign: 'bottom'}}>5 years annualised</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.stocks.length!==0 && 
                                        this.state.stocks.map((stock, index) => 
                                            <tr key={index}>
                                                <td>
                                                    {this.state.allEtfs.filter(etf => etf.code === stock.stock).map(etf => {
                                                        //Object to store fund name and url page
                                                        //New funds will need to have a new object manually created in here so that new tab links work
                                                        let funds = {
                                                            //New Zealand Shares
                                                            fnz : {
                                                                fund: 'fnz',
                                                                url: 'new-zealand-shares/fnz'
                                                            },
                                                            tnz : {
                                                                fund: 'tnz',
                                                                url: 'new-zealand-shares/tnz'
                                                            },
                                                            mdz : {
                                                                fund: 'mdz',
                                                                url: 'new-zealand-shares/mdz'
                                                            },
                                                            div : {
                                                                fund: 'div',
                                                                url: 'new-zealand-shares/nz-dividend'
                                                            },
                                                            npf : {
                                                                fund: 'npf',
                                                                url: 'property-shares/nzpropertytrust'
                                                            },
                                                            nzg : {
                                                                fund: 'nzg',
                                                                url: 'new-zealand-shares/nzg'
                                                            },
                                                            //Australian Shares
                                                            ozy : {
                                                                fund: 'ozy',
                                                                url: 'australian-shares/ozy'
                                                            },
                                                            mzy : {
                                                                fund: 'mzy',
                                                                url: 'australian-shares/mzy'
                                                            },
                                                            asd : {
                                                                fund: 'asd',
                                                                url: 'australian-shares/asd'
                                                            },
                                                            asp : {
                                                                fund: 'asp',
                                                                url: 'property-shares/asp'
                                                            },
                                                            asr : {
                                                                fund: 'asr',
                                                                url: 'australian-shares/au-resources'
                                                            },
                                                            asf : {
                                                                fund: 'asf',
                                                                url: 'australian-shares/au-financials'
                                                            },
                                                            aus : {
                                                                fund: 'aus',
                                                                url: 'australian-shares/aus'
                                                            },
                                                            //US Shares
                                                            usf : {
                                                                fund: 'usf',
                                                                url: 'us-shares/us-500'
                                                            },
                                                            usv : {
                                                                fund: 'usv',
                                                                url: 'us-shares/us-large-value'
                                                            },
                                                            usg : {
                                                                fund: 'usg',
                                                                url: 'us-shares/us-large-growth'
                                                            },
                                                            uss : {
                                                                fund: 'uss',
                                                                url: 'us-shares/us-small-cap'
                                                            },
                                                            usm : {
                                                                fund: 'usm',
                                                                url: 'us-shares/us-mid-cap'
                                                            },
                                                            //International Shares
                                                            euf : {
                                                                fund: 'euf',
                                                                url: 'international-shares/europe'
                                                            },
                                                            apa : {
                                                                fund: 'apa',
                                                                url: 'international-shares/asia-pacific'
                                                            },
                                                            emf : {
                                                                fund: 'emf',
                                                                url: 'international-shares/emerging-markets'
                                                            },
                                                            twf : {
                                                                fund: 'twf',
                                                                url: 'international-shares/total-world'
                                                            },
                                                            twh : {
                                                                fund: 'twh',
                                                                url: 'international-shares/twh'
                                                            },
                                                            //ESG Shares
                                                            emg : {
                                                                fund: 'emg',
                                                                url: 'esg-shares/emerging-markets-equities'
                                                            },
                                                            eug : {
                                                                fund: 'eug',
                                                                url: 'esg-shares/europe-equities'
                                                            },
                                                            esg : {
                                                                fund: 'esg',
                                                                url: 'esg-shares/global-equities'
                                                            },
                                                            jpn : {
                                                                fund: 'jpn',
                                                                url: 'esg-shares/japan-equities'
                                                            },
                                                            usa : {
                                                                fund: 'usa',
                                                                url: 'esg-shares/us-enquities'
                                                            },
                                                            //Thematic Shares
                                                            bot : {
                                                                fund: 'bot',
                                                                url: 'thematic-shares/automation-and-robotics'
                                                            },
                                                            liv : {
                                                                fund: 'liv',
                                                                url: 'thematic-shares/healthcare-innovation'
                                                            },
                                                            //Bonds and Cash Shares
                                                            nzb : {
                                                                fund: 'nzb',
                                                                url: 'bonds-and-cash/nzbondtrust'
                                                            },
                                                            nzc : {
                                                                fund: 'nzc',
                                                                url: 'bonds-and-cash/nzcashtrust'
                                                            },
                                                            gbf : {
                                                                fund: 'gbf',
                                                                url: 'bonds-and-cash/globalbondtrust'
                                                            },
                                                            agg : {
                                                                fund: 'agg',
                                                                url: 'bonds-and-cash/global-aggregate-bond'
                                                            },
                                                            ngb : {
                                                                fund: 'ngb',
                                                                url: 'bonds-and-cash/ngb'
                                                            },
                                                            ggb : {
                                                                fund: 'ggb',
                                                                url: 'bonds-and-cash/global-government-bond'
                                                            },
                                                            //Other Shares
                                                            ush : {
                                                                fund: 'ush',
                                                                url: 'us-shares/us-500-hedged'
                                                            },
                                                            aue : {
                                                                fund: 'aue',
                                                                url: 'esg-shares/aue'
                                                            },
                                                            inf : {
                                                                fund: 'inf',
                                                                url: 'thematic-shares/global-infrastructure'
                                                            },
                                                            gpr : {
                                                                fund: 'gpr',
                                                                url: 'property-shares/gpr'
                                                            },
                                                        }

                                                        //Loop through Object to populate links
                                                        for (let key in funds) {
                                                            if (funds.hasOwnProperty(key)) {
                                                              const innerObject = funds[key];
                                                              const fund = innerObject.fund;
                                                              const url = innerObject.url;
                                                              console.log(`Fund: ${fund}, URL: ${url}`); 
                                                              if (fund === etf.code.toLowerCase()) {
                                                                return (
                                                                    <Link className="background-arrow" key={etf.code} to={`types-of-funds/${url}`}
                                                                        //Commented out this code so that CTRL + click doesn't scroll to top
                                                                        //onClick={e => this._goToEtf(etf.code)} 
                                                                        >
                                                                        {String(etf.fund).substr(0,3) === 'AUS' 
                                                                        ? String(etf.fund).replace('AUS', 'Australian') 
                                                                        : etf.fund} ({etf.code})
                                                                    </Link>
                                                                )
                                                              }
                                                            }
                                                          }
                                                }

                                                    // Original code for Shares links
                                                    // <Link className="background-arrow" key={etf.code} to={`find-fund/${etf.code}`}
                                                    //     onClick={e => this._goToEtf(etf.code)}>
                                                    //     {String(etf.fund).substr(0,3) === 'AUS' 
                                                    //         ? String(etf.fund).replace('AUS', 'Australian') 
                                                    //         : etf.fund} ({etf.code})
                                                    // </Link>
                                                
                                                )}
                                                </td>
                                                <td>{stock.fundCharges && (stock.fundCharges !== 0 && `${Number(stock.fundCharges).toFixed(2)}%`)}</td>
                                                <td>{stock.nta && (stock.nta !== 0 && `$${Number(stock.nta).toFixed(2)}`)}</td>
                                                <td>{stock.dividendPaymentDates && stock.dividendPaymentDates}</td>
                                                <td>{stock.grossYield ? (stock.grossYield !== 0 ? `${Number(stock.grossYield).toFixed(2)}%` : '-') : '-'}</td>
                                                <td>{stock.marketcap && stock.marketcap !== 0 && 
                                                    <CurrencyFormat value={Number(stock.marketcap).toFixed(0)}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />}
                                                </td>

                                                {/* Change GTA 88505 - 20/03/2023 */}
                                                <td>{typeof(stock.return1Month)==="number" && `${Number(stock.return1Month).toFixed(2)}%`}</td>
                                                <td>{typeof(stock.return3Month )==="number" && `${Number(stock.return3Month).toFixed(2)}%`}</td>
                                                <td>{typeof(stock.return1Year )==="number" && `${Number(stock.return1Year).toFixed(2)}%`}</td>
                                                <td>{typeof(stock.return3Year )==="number" && `${Number(stock.return3Year).toFixed(2)}%`}</td>
                                                <td>{typeof(stock.return5Year )==="number" && `${Number(stock.return5Year).toFixed(2)}%`}</td>

                                                {/* <td>{stock.return1Month && stock.return1Month !== 0 && `${Number(stock.return1Month).toFixed(2)}%`}</td>
                                                <td>{stock.return3Month && stock.return3Month !== 0 && `${Number(stock.return3Month).toFixed(2)}%`}</td>
                                                <td>{stock.return1Year && stock.return1Year !== 0 && `${Number(stock.return1Year).toFixed(2)}%`}</td>
                                                <td>{stock.return3Year && stock.return3Year !== 0 && `${Number(stock.return3Year).toFixed(2)}%`}</td>
                                                <td>{stock.return5Year && stock.return5Year !== 0 && `${Number(stock.return5Year).toFixed(2)}%`}</td> */}
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Fund_Investor_Report_Table