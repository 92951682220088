/**
 * Component for Portfolio Characteristics Table
 * in ETF Page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import moment from 'moment';

class Portfolio_Characteristics extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            portfolioCharacteristics: [],
            asAtDate: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        const portfolioCharacteristics = props.portfolioCharacteristics.portfolioCharacteristics;
        const asAtDate = props.portfolioCharacteristics.asAtDate;

        return {
            portfolioCharacteristics: portfolioCharacteristics,
            asAtDate: asAtDate
        }
    }

    render() {
        let portfolioCharacteristics = [];
        let characteristics = {
            detail: null,
            fund: null,
            benchmark: null
        }

        this.state.portfolioCharacteristics && 
            this.state.portfolioCharacteristics.length !== 0 && 
                this.state.portfolioCharacteristics.map(portfolio => {
                    characteristics = {
                        detail: portfolio.characteristic,
                        fund: portfolio.fund,
                        benchmark: portfolio.benchmark
                    }
                    portfolioCharacteristics.push(characteristics);
                    if(portfolio.details) {
                        portfolio.details.map(detail => {
                            characteristics = {
                                detail: detail.detail,
                                fund: detail.fund,
                                benchmark: detail.benchmark
                            }
                            portfolioCharacteristics.push(characteristics);
                        })
                    }
                    return portfolioCharacteristics;
                })

        return (
            <Container>
                <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="space-bottom">
                        <h3 className="h1 dark-blue">PORTFOLIO CHARACTERISTICS</h3>
                        <p>As at {moment(this.state.asAtDate).format('DD MMMM YYYY')}.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Table striped className="fixed-income">
                            <thead>
                                <tr>
                                    <th width="33%">PORTFOLIO CHARACTERISTICS</th>
                                    <th width="33%">FUND</th>
                                    <th width="33%">BENCHMARK</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    portfolioCharacteristics.length !== 0 &&
                                        portfolioCharacteristics.map((portfolio, index) => {
                                            return (
                                                <tr key={index} className={index%2 === 0 ? 'odd' : ''}>
                                                    <td>{portfolio.detail}</td>
                                                    <td>{portfolio.fund}</td>
                                                    <td>{portfolio.benchmark}</td>
                                                </tr> 
                                            )
                                        })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Portfolio_Characteristics;
