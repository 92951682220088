/**Hero Component displays the image at the top of every ETF */

/**Import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HeroComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,
            sharesCategory: '',
            image: '',
            etf: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        const sharesCategory = props.etf && props.etf.share;
        const image = props.etf && props.etf.image;
        const etf = props.etf && props.etf.etfDetails

        return {
            sharesCategory: sharesCategory,
            image: image,
            etf: etf
        }
    }

    render() {
        return (
            <div className="hero-component hero-component--carousel">
                <div className="slide" style={{backgroundImage: "url('/images/slide-bg-2.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src={this.state.image}></img>
                                <h3 className="brand-title-component">
                                    <span className="brand-title-component__thick blue">{String(this.state.sharesCategory).toUpperCase()}</span>
                                    <span className="brand-title-component__thin white"></span>
                                </h3>
                                <h1 className="h1 slide__heading light-blue">{`${String(this.state.etf.fund).toUpperCase()} (${this.state.etf.code})`}</h1>
                                <div>
                                    {
                                        this.state.etf.desc.part1
                                    }
                                    {
                                        this.state.etf.desc.part2 && 
                                            <div>
                                                <br></br>
                                                {this.state.etf.desc.part2}
                                            </div>
                                    }
                                    {
                                        this.state.etf.desc.part3 && 
                                        <div>
                                            <br></br>
                                            {this.state.etf.desc.part3}
                                        </div>
                                    }
                                </div>
                                <p>&nbsp;</p>
                                <Link to="/invest-now"><Button className="btn large onlight">Invest Now</Button></Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default HeroComponent;