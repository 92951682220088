/** About Page Component */

/**Import Dependencies */
import React, {Component} from 'react';
import ReactGA from 'react-ga';

/**Import Components */
import FaqsAndGlossary from './FaqsAndGlossary';
import HowtogetSmartshares from './HowtogetSmartshares';
import InvestButton from './InvestButton';
import SmartsharesETFs from './SmartsharesETFs';
import SubMenu from './SubMenu';
import VideoLibrary from './VideoLibrary';
import WhoWeAre from './WhoWeAre';
import News from './News';

class About extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            loading: true
            //GTA 85779 - remove the video
            /**
            heading: 'Video Library',
            videos: [
                {
                    videoLink: "//www.youtube.com/watch?v=5kPagNBXYOI",
                    thumbnail: "//img.youtube.com/vi/5kPagNBXYOI/mqdefault.jpg"
                },
                {
                    videoLink: "//www.youtube.com/watch?v=t2opQL7dj6Q",
                    thumbnail: "//img.youtube.com/vi/t2opQL7dj6Q/mqdefault.jpg"
                },
                {
                    videoLink: "//www.youtube.com/watch?v=rM_jo1gq3cg",
                    thumbnail: "//img.youtube.com/vi/rM_jo1gq3cg/mqdefault.jpg"
                }
            ]
            */
        }
    }

    static getDerivedStateFromProps(props, state) {
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount() {
        // ReactGA.pageview(this.props.history.location.pathname);
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate() {
        this.state.hashID && document.getElementsByName(this.state.hashID).item(0).scrollIntoView() 
    }

    render(){
        return(
            <div>
                {/* <SubMenu {...this.props}></SubMenu> */}
                <div className="about-position">
                    <SmartsharesETFs></SmartsharesETFs>
                    <WhoWeAre></WhoWeAre>
                    {/* <VideoLibrary heading={this.state.heading} videoLibrary={this.state.videos} {...this.props}></VideoLibrary> */}
                    <FaqsAndGlossary {...this.props}></FaqsAndGlossary>
                    <HowtogetSmartshares></HowtogetSmartshares>
                    <News></News>
                    <InvestButton></InvestButton>
                </div>
            </div>
        )
    }
}

export default About;

