import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class SmartsharesNews extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    render() {
        return (
            <div className="about-position">
            <Container>
                <Col md="12" >
                <h1 className="dark-blue text-center">We're making futures smarter, together</h1>
                
                    <p>22 February 2024</p>
                    <Row>
                    <p>
                        We're super excited to announce that we're partnering with Auckland Young Professionals! 
                        Together, we're looking forward to building better professional and financial futures for young and ambitious 
                        Aucklanders.
                    </p>
                    </Row>
                    <Row>
                    <p>
                        Smartshares is driven by helping their investor community make their financial dream become reality, no matter
                         what their level of financial education is. By partnering with AYP, they'll be able to continue lowering the 
                         educational barrier to investing, especially for young Aucklanders who are already working towards financial 
                         goals of their own. 
                    </p>
                    </Row>
                    <Row>
                    <p>
                        AYP hosts a range of events that upskill members and build strong networks. Partnering with Smartshares will 
                        allow their network to grow further, by expanding their access to speakers with sought-after expertise and 
                        growing their network for members.
                    </p>
                    </Row>
                    <Row>
                    <p>
                        Putting the best possible resources in the hands of Auckland's next generation of leaders is a goal we both share,
                         and we couldn't be happier to be doing that at events across Auckland, together.
                    </p>
                    </Row>
                    <Row>
                    <p>
                        See what AYP has coming soon <a href='https://ayp.co.nz/events/'>here</a>, and how Smartshares can help make your future 
                        happen <a href='http://smartshares.co.nz/'>here</a>. 
                    </p>
                    </Row>
                    
                
                    <hr></hr>
                </Col>
                </Container>
            </div>
        )
    }
}

export default SmartsharesNews;