/**Contact Form Component in Contact */

/**Impor Dependencies */
import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {Form, FormControl, FormGroup, FormLabel, FormText} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

class ContactForm extends Component {
    constructor(props){
        super(props);
        
        this.state = { 
            name: '',
            email: '',
            subject: 'Core Series',
            message: '',
            recaptcha: '',
            response: ''
        }

        this._onNameChange = this._onNameChange.bind(this);
        this._onEmailChange = this._onEmailChange.bind(this);
        this._onSubjectChange = this._onSubjectChange.bind(this);
        this._onMessageChange = this._onMessageChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._resetForm = this._resetForm.bind(this);
        this._onRecaptchaChange = this._onRecaptchaChange.bind(this)
    }   

    _onNameChange(event) {
        this.setState({name: event.target.value})
    }

    _onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    _onSubjectChange(event) {
        this.setState({subject: event.target.value})
    }

    _onMessageChange(event) {
        this.setState({message: event.target.value})
    }

    _onRecaptchaChange(value) {
        this.setState({recaptcha: value})
    } 

    _handleSubmit(event) {
        event.preventDefault();
        axios('https://service.smartshares.co.nz/email', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "captcha-response": this.state.recaptcha
            }, 
            mode: 'cors',
            data: JSON.stringify({name: this.state.name, email: this.state.email, subject: this.state.subject, message: this.state.message})
        }).then(response => {
            if(response.status === 200)
                return response = 'Email Sent';
            else    
                return response = '';
        }).then(response => this.setState({response: response}))
        .catch(error => console.log(' '))
    }

    _resetForm() {
        this.setState({
            name: '', 
            subject: "CoreSeriesFunds",
            email: '',
            message:'',
            recaptcha: '',
            response: ''})
    }
    
     
    render(){
        return(
            <Container>
                <Row className="contact space-bottom-full">
                    <Col sm="6" className="col-sm-push-3">
                        <Row>
                            <Col sm="12" className="text-center">
                                <h2>Contact Us</h2>
                            </Col>
                        </Row>

                        <Form onSubmit={e => this._handleSubmit(e)} method="post" id="contact-form" className="contact-form">
                            <FormGroup>
                                <FormLabel>FULL NAME:</FormLabel>
                                <FormControl 
                                    type="text" 
                                    placeholder="Name here" 
                                    name="name" 
                                    id="name"  
                                    className="default"
                                    required 
                                    value={this.state.name} 
                                    onChange={e => this._onNameChange(e)}>
                                </FormControl>
                            </FormGroup>
                            
                            <FormGroup>
                                <FormLabel>EMAIL:</FormLabel>
                                <FormControl 
                                    type="email" 
                                    placeholder="Email here" 
                                    name="email" 
                                    id="email" 
                                    className="default"
                                    required 
                                    value={this.state.email} 
                                    onChange={e => this._onEmailChange(e)}>
                                </FormControl>
                            </FormGroup>
                            
                            <FormGroup>
                                <FormLabel>SUBJECT:</FormLabel>
                                <FormControl 
                                    as="select" 
                                    id="select" 
                                    title="Subject" 
                                    name="select" 
                                    required 
                                    value={this.state.subject} 
                                    onChange={e => this._onSubjectChange(e)}>
                                    <option value="Core Series" defaultChecked={this.state.subject}>Core Series</option>
                                    <option value="New Zealand shares" defaultChecked={this.state.subject}>New Zealand shares</option>
                                    <option value="Australian shares" defaultChecked={this.state.subject}>Australian shares</option>
                                    <option value="US shares" defaultChecked={this.state.subject}>US shares</option>
                                    <option value="International shares" defaultChecked={this.state.subject}>International shares</option>
                                    <option value="Bonds and Cash" defaultChecked={this.state.subject}>Bonds and Cash</option>
                                    <option value="ESG shares" defaultChecked={this.state.subject}>ESG shares</option>
                                    <option value="Thematic shares" defaultChecked={this.state.subject}>Thematic shares</option>
                                    <option value="Property shares" defaultChecked={this.state.subject}>Property shares</option>
                                    <option value="Other" defaultChecked={this.state.subject}>Other</option>
                                </FormControl>
                                <FormText className="help-block">Select a subject or fund you wish to enquire about</FormText>
                            </FormGroup>
                            
                            <FormGroup>
                                <FormLabel>MESSAGE:</FormLabel>
                                <FormControl 
                                    as="textarea" 
                                    name="message" 
                                    id="message" 
                                    className="default" 
                                    placeholder="Your enquiry here"
                                    required 
                                    value={this.state.message} 
                                    onChange={e => this._onMessageChange(e)}>
                                </FormControl>
                                <input type="reset" className="reset-form-btn" onClick={e => this._resetForm(e)}></input>
                            </FormGroup>

                            <FormGroup className="text-center">
                                <ReCAPTCHA 
                                    className="g-recaptcha" 
                                    sitekey="6LdqOTwaAAAAAMJg_gidrHvJoo22uTxi1p_Z2PuD" 
                                    onChange={this._onRecaptchaChange}/>
                                <span id="captcha_unhappy" className="unhappyMessage" role="alert">You must prove you're not a robot!</span>
                            </FormGroup>
                            
                            <FormGroup className="text-center">
                                <Button 
                                    id="submit" 
                                    type="submit" 
                                    className="btn large onlight ladda button" 
                                    data-style="expand-left">
                                <span className="ladda-label">Submit</span>
                                </Button>
                                <input type="hidden" name="form_id" value="1"/>
                            </FormGroup>
                        </Form>
                        
                        <div id="send-message" className="send-message" style={!this.state.response ? {display: 'none'}: {display: 'block'}}>
                            <div className="inner">{this.state.response}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ContactForm;