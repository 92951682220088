/**Footer Component */

/**import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**Import Components and css */
import '../css/Banner.css';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
        this.banner = React.createRef(); 
    }
    
    componentDidUpdate() {
        const element = this.banner.current;

        if (String(this.props.location.pathname) === "/about-smartshares" ) {
                element.setAttribute('style', 'top: 125px;border-top:1px solid #B9BABC;'); 
        } else if (String(this.props.location.pathname) === "/benefits" ){
            element.setAttribute('style', 'top: 125px;border-top:1px solid #B9BABC;'); 
        } else if (String(this.props.location.pathname) === "/invest-now" ){
            element.setAttribute('style', 'top: 125px;border-top:1px solid #B9BABC;'); 
        }else {
            element.setAttribute('style', 'top: 80px'); 
        }
    }
    render() {
        return(
            <div className="banner" ref={this.banner}>
                <div>
                    {/* <p>We're currently receiving high call volumes, and the wait time to speak to one of our team will be longer than usual. 
                        If your query isn't urgent, you can also get help via our <u><a href='/contact-us'>contact us form</a></u> and we'll get back to you as soon as possible. 
                        We apologise for the inconvenience and are looking forward to helping you soon.</p> */}
                    <h3>We're rebranding </h3>
                    <p class="slide__blurb">Stay tuned for the exciting reveal!</p>
                </div>
            </div>
        )
    }
}

export default Banner;