/**Financial Statement Component in Legal Documents */

/**Import dependencies */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import moment from 'moment';
import { set } from 'react-ga';

class FinancialStatements extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true,

            files: [
                {
                    label: 'Financial Statements - NZ Core Equity Trust',
                    fileName: 'nz_core_equity_trust'
                },
                {
                    label: 'Financial Statements - Smartshares Exchange Traded Funds',
                    fileName: 'smartshares_exchange_traded_funds'
                },
                {
                    label: 'Financial Statements - Smartshares KiwiSaver Scheme',
                    fileName: 'smartshares_kiwisaver_scheme'
                }
            ],

            fileAttributes : {
                shares: "FS",
                folder: "/document-library/financial-statements",
                filePrefix:"financial_statement",
                fileSuffix:".pdf"
            },

            existingFiles: [],
            startDate: null,
            endDate: null            
        };
    }
    
    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.fs_existingFiles;
        //GTA92354 -- Change the start/end date algorithm
        // const startDate = moment(props.documentsDate).subtract(1, 'year').add(1, 'day').format('D MMMM YYYY');
        // const endDate = props.documentsDate;
        const startDate = moment(new Date(props.userSelectedYear - 1, 3, 1)).format('D MMMM YYYY');
        const endDate = moment(new Date(props.userSelectedYear, 2, 31)).format('D MMMM YYYY');
        console.log(startDate)
        return {
            existingFiles: existingFiles,
            startDate: startDate,
            endDate: endDate
        }
    }

    componentDidMount(){
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userSelectedYear !== this.props.userSelectedYear || prevProps.userSelectedQuarter !== this.props.userSelectedQuarter)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }
    
    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#financial-statements" className="anchor has-sub-nav"></ScrollTo>
                <h2 className="heading light-blue upper">Financial Statements</h2>
                <hr></hr>
                <div id-="FS" className="link-wrapper">
                    {
                        //GTA-70092 - starts
                        this.state.existingFiles.length == 0 ?
                            <p>The {this.props.userSelectedYear} financial statements for Smartshares Exchange Traded Funds and NZ Core Equity Trust will be available by 30 June {this.props.userSelectedYear}</p>
                        //GTA-70092 - ends
                            : this.state.existingFiles.map((file, index) => 
                                <p key={index}>
                                    <Link to={`document-library/financial-statements/${this.props.userSelectedYear}/financial_statement_${file.file.fileName}.pdf`}
                                        target="_blank" className="pdf">
                                        {file.file.label} {this.props.userSelectedYear !== '2021' && `(${this.state.startDate} - ${this.state.endDate})`}
                                        <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                    </Link>
                                </p>)
                    }
                </div>
            </div>
        )
    }
}

export default FinancialStatements;