/**
 * This component consists of code for sliding images under the Main menu on smartshares home page.
 */
/**
 * Import dependencies
 */
import React, { Component } from 'react';
import Image from './Image';
import Slider from 'react-slick';

class ImageSlides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            images: [
                
                {
                    "id": 0,
                    /* GTA 92279 - original files prior to this update are kept as comments in this section */
                    // "image": "news-slide.jpg",
                    "image": "news-slide-5etfs.jpg",
                    "heading": {
                        "part1": <strong style={{textTransform: "none"}}>Five new funds are here</strong>,
                        // "part1": <strong style={{textTransform: "none"}}>KiwiSaver with Smartshares</strong>,
                        "part2": ""
                    },
                    // "desc": "Smartshares, through the SuperLife KiwiSaver Scheme, is proud to be one of six government appointed default providers.",
                    "desc": "Smartshares has launched all-new ETFs, giving you more options to build your future.",
                    // "desc2":"Choose a low fees KiwiSaver provider with over 40 investment funds, including many of the Smartshares ETFs and the lowest cost KiwiSaver default balanced fund.",
                    "desc2":"With five new international ETFs that includes an Australian ESG fund, you can invest in what matters to you.",
                    "btnTxt":"Explore now",
                    "externalLink":true,
                    "link": "/types-of-funds",
                    "disclaimer":<p className="small">Smartshares Limited is the issuer of the Smartshares Exchange Traded Funds.<br/>
                    The disclosure statement is available
                    <a href="/legal-documents" style={{color:"#FFFFFF"}}><strong> here</strong></a>
                    </p>
                }
            ],
            imagesList: [],
            windowWidth: '1903px'
        }
    }

    static getDerivedStateFromProps(props, state) {
        const imagesList = state.imagesList.length === 0 ? Object.keys(state.images).map(key => state.images[key]) : state.imagesList;
        if (state.imagesList.length === 0) {
            return {
                imagesList: imagesList,
                windowWidth: props.windowWidth
            }
        }
        return state;
    }

    componentDidMount() {
        this.props.setWindowWidth();
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: false,
            autoplaySpeed: 5000,
            pauseOnFocus: true
          }; 
        return (
            <Slider className="hero-component hero-component--carousel dots"  {...settings}>
                    {
                        this.state.imagesList.map((image, index) => 
                            <Image key={index} image={image} width={this.state.windowWidth} {...this.props}></Image>
                        )
                    }
            </Slider>
        )
    }
}

export default ImageSlides;

