/**
 * Component for Invest Page
 */
/**
 * Import dependencies
 */
import React, {Component} from 'react';

/**
 * Import components and css
 */
import Advisor from './Advisor';
import ApplyOnline from './ApplyOnline';
import KiwiSaver from './KiwiSaver';
import NZXParticipants from './NZXParticipants';
import SubMenu from './SubMenu';

class Invest extends Component {
    constructor(props){
        super(props);
        this.state = {loading: false};
    }

    static getDerivedStateFromProps(props, state){
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount(){
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate() {
        this.state.hashID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    render(){

        return(
            <div>
                {/* <SubMenu {...this.props}></SubMenu> */}
                <div className="invest-position">
                    <ApplyOnline></ApplyOnline>
                    <NZXParticipants {...this.props}></NZXParticipants>
                    <Advisor></Advisor>
                    <KiwiSaver {...this.props}></KiwiSaver>
                </div>
            </div>
        )
    }
}

export default Invest;