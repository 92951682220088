/**Fund Slider Component in Home Page */

/**Import dependencies */
import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

/**Import components and css */
import Fund from './Fund';

class FundSlides extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true ,
            
            funds : [
                {
                    "id": 0,
                    "image": "icon-smart-large-lge-white.png",
                    "brand_title": {
                        "part1": "CORE SERIES",
                        "part2": ""
                    },
                    "desc":"The Core Series is our low cost range of ETFs and provides investors the core building blocks for their portfolios.",
                    "link": "#core-series"
                },
                {
                    "id": 1,
                    "image": "icon-smart-large-lge-white.png",
                    "brand_title": {
                        "part1": "NEW ZEALAND SHARES",
                        "part2": ""
                    },
                    "desc":"Our New Zealand Shares ETFs provide a comprehensive range of low cost funds investing into companies listed in New Zealand.",
                    "link": "#new-zealand-shares"
                },
                {
                    "id": 2,
                    "image": "SmartMedium_130x130.png",
                    "brand_title": {
                        "part1": "AUSTRALIAN SHARES",
                        "part2": ""
                    },
                    "desc":"Our Australian Shares ETFs cover the main Australian indices as well as specific Australian sectors such as Resources and Financials, all through funds listed on the NZX and priced in New Zealand dollars.",
                    "link": "#australian-shares"
                },
                {
                    "id": 3,
                    "image": "icon-smart-large-lge-white.png",
                    "brand_title": {
                        "part1": "US SHARES",
                        "part2": ""
                    },
                    "desc":"Our US Shares ETFs offer low cost access to a broad range of listed US companies through New Zealand listed funds.",
                    "link": "#us-shares"
                },
                {
                    "id": 4,
                    "image": "SmartSector_White.png",
                    "brand_title": {
                        "part1": "INTERNATIONAL SHARES",
                        "part2": ""
                    },
                    "desc":"Our International Shares ETFs offer low cost exposures to regions and countries around the world, all listed on the NZX and priced in New Zealand dollars.",
                    "link": "#international-shares"
                },
                {
                    "id": 5,
                    "image": "SmartDividend_130x130.png",
                    "brand_title": {
                        "part1": "BONDS AND CASH",
                        "part2": ""
                    },
                    "desc":"Our Bonds and Cash ETFs offer both actively managed and fully passive ETFs made up of government and corporate bonds, interest bearing assets and other fixed interest securities across New Zealand and global markets.",
                    "link": "#bonds-and-cash"
                },
                {
                    "id": 6,
                    "image": "icon-flexibility-lge (1).png",
                    "brand_title": {
                        "part1": "ESG SHARES",
                        "part2": ""
                    },
                    "desc":"Get socially responsible international equity exposure through our diverse range of ESG Shares ETFs.",
                    "link": "#esg-shares"
                },
                {
                    "id": 7,
                    "image": "SmartSector_White.png",
                    "brand_title": {
                        "part1": "Thematic Shares",
                        "part2": ""
                    },
                    "desc":"Global trends such a technological and healthcare innovation, as well as a dedicated infrastructure fund drive our Thematic Shares ETFs.",
                    "link": "#thematic-shares"
                },
                {
                    "id": 8,
                    "image": "icon-smart-large-lge-white.png",
                    "brand_title": {
                        "part1": "Property Shares",
                        "part2": ""
                    },
                    "desc":"Our Property ETFs provide exposure to local and global property companies.",
                    "link": "#property-shares"
                },
            ],

            fundsList: []
        };
    }

    static getDerivedStateFromProps(props, state){
        const fundsList = Object.keys(state.funds).map(key => state.funds[key]);
        return {
            fundsList: fundsList
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000
          };
        return(
            <Slider className="hero-component hero-component--carousel carousel dots funds" {...settings}>
                {
                    this.state.fundsList.map((fund, index) => 
                        <Fund key={index} fund={fund}></Fund>
                    )
                }
            </Slider>
        )
    }
}

export default FundSlides;