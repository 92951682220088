/**Headings Component returns heading for all other components */

/**Import dependencies */
import moment from 'moment';
import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Headings extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        //For Types of Funds Page
        if (String(this.props.history.location.pathname).includes("types-of-funds"))
            return (
                <Container>
                    <Row>
                        <Col sm="12" className="text-center">
                            <h1 className="dark-blue">There are 40 Smartshares Exchange Traded Funds to choose from.</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="10" className="col-md-push-1 text-center">
                            <p>Build a portfolio of funds that invests across New Zealand, Australia and a broad range of global stock exchanges. Click the fund names below for in-depth detail including benefits, holdings, value, fees and performance.</p>
                            <hr></hr>
                        </Col>
                    </Row>
                </Container>
            );
        
        //For Returns Page 
        else if(String(this.props.history.location.pathname).includes("fund-investor-report"))
                return (
                    <Container>
                        <Row>
                            <Col md="3">
                                <h1 className="dark-blue">ETFs Summary</h1>
                                <p>
                                    <b>As at: </b>
                                    {moment(this.props.asAtDate).format('DD/MM/YYYY')}
                                </p>
                            </Col>
                            <Col md="4">
                                {/* This was previously a mini gif slideshow showcasing Smartshares awards and achievements */}
                                {/* <img className="img-responsive my-2" src="../images/Smartshares-tile-InvestmentNews-Oct 2019-300x100.gif"
                                    style={{marginTop: "22px", marginBottom: "11px"}}></img> */}
                            </Col>
                            <Col xs="xs-5">
                                <p style={{marginTop: "22px"}}>
                                    <small>
                                        <b className="light-blue upper">Investor Returns</b>
                                        <br></br>
                                        Returns are as at {moment(this.props.investorReturnsDate).format('DD MMMM YYYY')} {/**GTA-69832 */} and are calculated on NTA movement, assuming distributions are reinvested on ex-date, and are after tax at 28% and fund charges.
                                    </small>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                );
        
        //For Benefits Page
        else if (String(this.props.history.location.pathname).includes("benefits"))
            return (
                <Container>
                    <Row>
                        <Col sm="12" className="text-center">
                            <p>&nbsp;</p>
                            <h1 className="dark-blue">Build your investment portfolio with Smartshares products and gain exposure to a range of global and domestic investment possibilities. Easy to invest. Simple to follow your investment.</h1>
                            <p>&nbsp;</p>
                            <Link to="/types-of-funds">
                                <Button className="btn large onlight">
                                    View types of funds
                                </Button>
                            </Link>
                            <hr></hr>
                        </Col>
                    </Row>
                </Container>
            );
            
        //For Contact Page
        else if(String(this.props.history.location.pathname).includes("contact-us"))
                return (
                    <Container>
                        <Row>
                            <Col sm="12" className="text-center">
                                <p>&nbsp;</p>
                                <h1 className="dark-blue">
                                    Getting in touch is easy. Simply fill out the form 
                                    <br></br>    
                                    below and we will be in touch shortly.
                                </h1>
                                <hr></hr>
                            </Col>
                        </Row>
                    </Container>
                );

        //For Legal Documents
        else if (String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <h1 className="big light-blue upper title">Legal Documents</h1>
            );
        
        //For others
        else return (
            <div></div>
        )
    }
}

export default Headings;