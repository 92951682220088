/**Fund Performance Component in ETF */

/**Import dependencies */
import React, { Component } from 'react';
import { Col, Container, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import Highcharts from 'highcharts';
import moment from 'moment';

class Fund_Performance extends Component {
     constructor(props) {
         super(props);
         this.state = { 
             loading: true,
             data: null,
             options: null,
             investorReturns: [],
             chartID: "highchart1",
             hiddenPrintable: false
            };
     }

     static getDerivedStateFromProps(props, state) {
      let fundPerformanceGraph;
        try{
         fundPerformanceGraph = JSON.parse(props.fundPerformanceGraph);
        } catch (e){
          fundPerformanceGraph = [];
        }
         const investorReturns = props.investorReturns.returns;
         //Distinguish between the pdf-printable version and web display version
         var chartNum = (props.hiddenPrintable) ? 1 : 2;
         const chartID = "highchart"+chartNum; 
         let data = []
         let graphData = []
         const hiddenPrintable = props.hiddenPrintable;
         fundPerformanceGraph && fundPerformanceGraph.length !== 0 && fundPerformanceGraph.map(fundPerformance => {
            graphData = [
                Date.UTC(moment(fundPerformance.date).toDate().getFullYear(), moment(fundPerformance.date).toDate().getMonth(), moment(fundPerformance.date).toDate().getDate()),
                Number(fundPerformance.value)
            ]
            data.push(graphData)
         });
         return {
           investorReturns: investorReturns,
           data: data,
           hiddenPrintable: hiddenPrintable,
           chartID: chartID,
           options: {
             credits: {
               enabled: false
             },
             chart: {
               type: 'line',
               borderWidth: 0,
               spacingLeft: 1,
               spacingRight: 8,
               spacingBottom: 5,
               spacingTop: 11,

             },
             exporting: {
               filename: `${String(props.etfCode).toUpperCase()} NTA Chart`,
               scale: 1,
               sourceWidth: 940,
               sourceHeight: 470,
               buttons: {
                 contextButton: {
                   text: 'Export'
                 }
               }
             },
             navigation: {
               buttonOptions: {
                 symbolSize: 15,
               }
             },
             title: {
               text: ''
             },
             legend: {
               enabled: false
             },
             xAxis: {
               type: 'datetime',
               labels: {
                 formatter: function () {
                   var monthStr = Highcharts.dateFormat('%e %b %y', this.value);
                   var firstLetter = monthStr.substring(0, 1);
                   return monthStr; /* above line is sample code to return the first letter of the day of week. instead, just return the formatted date */
                 },
               },
               title: {
                 text: 'Date'
               }

             },
             yAxis: {
               title: {
                 text: 'Net Tangible Assets per Share ($)'
               },
               alternateGridColor: '#FFFFFF',

             },
             tooltip: {
               enabled: (hiddenPrintable)? false:true,
               headerFormat: '<b>{series.name}</b><br>',
               pointFormat: '{point.x:%e %b %Y}: ${point.y:.5f}'
             },
             plotOptions: {
               series: {
                 marker: {
                   radius: 1,
                   states: {
                     hover: {
                       enabled: (hiddenPrintable)? false:true,
                       radius: 4,
                       lineWidthPlus: 0
                     }
                   }
                 },
                 states: {
                   hover: {
                     enabled: (hiddenPrintable)? false:true,
                     lineWidthPlus: 0
                   }
                 }
               }
             },
             series: [{
               name: `${String(props.etfCode).toUpperCase()} NTA`,
               color: '#3397D2',
               animation: true,
               lineWidth: 2,
               // Define the data points.  
               // For more than one series have a dummy year of XXXX in order to be compared on the same x axis (and dont display year on the tooltip) 
               // Note that in JavaScript, months start at 0 for January, 1 for February etc.
               data: data,
             }]
           }
         }
        }
    
      componentDidMount() {
        if (this.state.data !== null && this.state.data.length !== 0)  Highcharts.chart(this.state.chartID, this.state.options);    
      }

      componentDidUpdate(prevProps, prevState) {
        if (this.state.data !== null && this.state.data.length !== 0) Highcharts.chart(this.state.chartID, this.state.options);
      }
     
      render(){
        require("highcharts/modules/exporting")(Highcharts);

        /* Assign different chart heights to pdf and web view
        true: pdf view
        false: web view */
        var chartHeight = (this.props.hiddenPrintable)? "270px" : "470px";

        return(
          <Container className="fund-more-details" id="testingCharts">
            <Row>
              <Col md="12"><hr></hr></Col>
            </Row>
            <Row>
              <Col md="12" className="space-bottom">
                <h3 className="h1 dark-blue upper">Fund Performance</h3>
                {(this.state.data !== null && this.state.data.length !== 0)?
                <h3 className="light-blue upper">
                  <b>
                    {(this.state.hiddenPrintable)? <span>Net Tangible Assets (NTA)</span>:
                      <OverlayTrigger trigger='hover' placement='top' overlay= {
                        <Popover id='popover-basic' className='in top'>
                          <Popover.Title as='h3'>Net tangible assets (NTA)</Popover.Title>
                          <Popover.Content>The value of the underlying assets held by the ETF on a per unit basis.</Popover.Content>
                        </Popover>
                      }>
                        <span data-toggle='popover'>Net Tangible Assets (NTA)</span>
                      </OverlayTrigger>
                    }
                    {' per unit Performance'}
                  </b>
                </h3>:<></>
                }
              </Col>
            </Row>
            <Row>
              {(this.state.data !== null && this.state.data.length !== 0)?
              <Col md="12">
                  <div id={this.state.chartID} className="graph space-bottom" style={{maxWidth: "940px", height: {chartHeight}, margin: "0 auto"}}></div>
              </Col>:<></>}
            </Row>
            {
              this.state.investorReturns && this.state.investorReturns.length !== 0 &&
                <Row>
                  <Col md="12">
                    <Table striped className="fund-performance">
                      <thead>
                        <tr>
                          <th width="400"></th>
                          <th>1 Month</th>
                          <th>3 Months</th>
                          <th>1 Year</th>
                          <th>3 Years Annualised</th>
                          <th>5 Years Annualised</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.investorReturns.map(returns => 
                            <tr key={returns.returnType} className="odd">
                              <td className="left">{returns.description}</td>
                              <td>
                                <span className={`arrow-icon ${!returns.return1Month ? 'neutral' 
                                          : returns.return1Month < 0 ? 'negative' : 'positive'}`}>
                                    {returns.return1Month && `${Number(returns.return1Month).toFixed(2)}%`}
                                </span>
                              </td>
                              <td>
                                <span className={`arrow-icon ${!returns.return3Month ? 'neutral' 
                                          : returns.return3Month < 0 ? 'negative' : 'positive'}`}>
                                      {returns.return3Month && `${Number(returns.return3Month).toFixed(2)}%`}
                                </span>
                              </td>
                              <td>
                                <span className={`arrow-icon ${!returns.return1Year ? 'neutral' 
                                          : returns.return1Year < 0 ? 'negative' : 'positive'}`}>
                                      {returns.return1Year && `${Number(returns.return1Year).toFixed(2)}%`}
                                </span>
                              </td>
                              <td>
                                <span className={`arrow-icon ${!returns.return3Year ? 'neutral' 
                                          : returns.return3Year < 0 ? 'negative' : 'positive'}`}>
                                      {returns.return3Year && `${Number(returns.return3Year).toFixed(2)}%`}
                                </span>
                              </td>
                              <td>
                                <span className={`arrow-icon ${!returns.return5Year ? 'neutral' 
                                          : returns.return5Year < 0 ? 'negative' : 'positive'}`}>
                                      {returns.return5Year && `${Number(returns.return5Year).toFixed(2)}%`}
                                </span>
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
            }
          </Container>
             
         )
     }
}

export default Fund_Performance;