import {combineReducers} from 'redux';

function getPagesReducer(state=[], action) {
    switch(action.type) {
        case "GET_PAGES_DATA": return {
            ...state,
            pages: action.pages
        }
        default : return {
            state
        }
    }
}

function getImagesReducer(state=[], action) {
    switch(action.type) {
        case 'GET_IMAGES': return {
            ...state,
            images: action.images
        }
        default: return state
    }
}

function getUserSelectionsReducer(state=[], action) {
    switch (action.type) {
        case 'GET_SELECTED_YEAR': return {
            ...state,
            selectedYear: action.selectedYear
        }
        case 'GET_SELECTED_QUARTER': return {
            ...state,
            selectedQuarter: action.quarter
        }
        case 'GET_DOCUMENTS_DATE': return {
            ...state,
            documentsDate: action.documentsDate
        }
        default : return state
    }
}

function existingFilesReducer(state=[], action) {
    switch(action.type) {
        case 'GET_FS_EXISTING_FILES': return {
            ...state,
            fs_existingFiles: action.existingFiles
        }
        case 'GET_AR_EXISTING_FILES': return {
            ...state,
            ar_existingFiles: action.existingFiles
        }
        case 'GET_NZ_EXISTING_FILES': return {
            ...state,
            nz_existingFiles: action.existingFiles
        }
        case 'GET_AUS_EXISTING_FILES': return {
            ...state,
            aus_existingFiles: action.existingFiles
        }
        case 'GET_US_EXISTING_FILES': return {
            ...state,
            us_existingFiles: action.existingFiles
        }
        case 'GET_INS_EXISTING_FILES': return {
            ...state,
            ins_existingFiles: action.existingFiles
        }
        case 'GET_ESG_EXISTING_FILES': return {
            ...state,
            esg_existingFiles: action.existingFiles
        }
        case 'GET_THS_EXISTING_FILES': return {
            ...state,
            ths_existingFiles: action.existingFiles
        }
        case 'GET_BAC_EXISTING_FILES': return {
            ...state,
            bac_existingFiles: action.existingFiles
        }
        case 'GET_NZ_CORE_EXISTING_FILES': return {
            ...state,
            nz_core_existingFiles: action.existingFiles
        }
        case 'GET_PPT_EXISTING_FILES': return {
            ...state,
            ppt_existingFiles: action.existingFiles
        }
        default: return state
    }
}

function getStocksReducer(state=[], action) {
    switch(action.type) {
        case 'GET_STOCKS': return {
            ...state,
            investorReturnsDate: action.stocks.returnsDate, //GTA-69832
            stocks: action.stocks.stocks    //GTA-69832
        }
        default: return state
    }
}

function typesOfFundsReducer(state = [], action) {
    switch(action.type) {
        case 'GET_HOLDINGS': return {
            ...state,
            holdings: action.holdings
        }
        case 'GET_FUND_PERFORMANCE_GRAPH': return {
            ...state,
            fundPerformanceGraph: action.graph
        }
        case 'GET_INVESTOR_RETURNS': return {
            ...state,
            investorReturns: action.investorReturns
        }
        case 'GET_PORTFOLIO_CHARACTERISTICS': 
            return {
            ...state,
            portfolioCharacteristics: action.portfolio
        }
        case 'GET_DIVIDENDS': return {
            ...state,
            dividends: action.dividends
        }
        case 'GET_MARKET_ANNOUNCEMENTS': return {
            ...state,
            marketAnnouncements: action.marketAnnouncements
        }
        default: return state
    }
}

function getEtfReducer(state = [], action) {
    switch(action.type) {
        case 'ETF_CODE': return {
            ...state,
            etfCode: action.etfCode
        }
        case 'GET_ETF': return {
            ...state,
            etf: action.etfDetails
        }
        case 'GET_ALL_ETFS': return {
            ...state,
            allEtfs: action.etfs
        }
        case 'GET_NZ_EXPOSURE_ETFS': return {
            ...state,
            nzExposureEtfs: action.nzExposureEtfs
        }
        case 'GET_SHARE_NAME': return {
            ...state,
            shareName: action.shareName
        }
        case 'GET_OTHER_ETFS': return {
            ...state,
            subFunds: action.subFunds,
            span: action.span
        }
        default: return state
    }
}

function windowPropertiesReducer(state = [], action) {
    switch(action.type) {
        case 'WINDOW_WIDTH': return {
            ...state,
            windowWidth: action.windowWidth
        }
        default: return state
    }
}

function getFaqsReducer(state = [], action) {
    switch(action.type) {
        case 'GET_FAQS': return {
            ...state,
            faqs: action.faqs,
            glossary: action.glossary
        }
        default : return state
    }
}

const rootReducer = combineReducers({getPagesReducer, getImagesReducer, getUserSelectionsReducer, existingFilesReducer, getStocksReducer, typesOfFundsReducer, getEtfReducer, windowPropertiesReducer, getFaqsReducer})

export default rootReducer;