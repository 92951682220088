/**
 * Component for Invest Button
 */
/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

/**
 * Import components and css
 */
import '../css/Main.css';
import '../css/SubMenu.css';

class InvestButton extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md="12" className="text-center">
                        <hr></hr>
                        <Link to="/invest-now">
                            <Button className="btn large onlight">Invest Now</Button>
                        </Link>
                        <hr></hr>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default InvestButton