/**Component for Image Slides at the top of HomePage */

/**Import dependencies */
import React, {Component} from "react";
import {Button, Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';


class Image extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            width: window.innerWidth
        }
    }

    componentDidMount() {
        window.addEventListener('resize', ()=>this.setState({width: window.innerWidth}))
    }

    _findOutMore() {
        ReactGA.event({
            category: 'Homepage Slider',
            action: this.props.image.btnTxt,
            label: this.props.image.link
        }, ['newTracker', 'existingTracker'])
    }

    render() {
        return (
            <div className="slide slick-slide slick-active" key={this.props.key}
                style={{ backgroundImage: `url('../images/${this.props.image.image}')`, width: this.state.width }} 
                data-slick-index={this.props.image.id} aria-hidden="false">
                <Container>
                    <Row>
                        <Col md="10" xs="12" className="col-md-push-1">
                            <h1 className="upper">{this.props.image.heading.part1}
                                <br></br> {this.props.image.heading.part2}
                            </h1>
                            <p className="slide__blurb slide__blurb--large">{this.props.image.desc}</p>
                            <p className="slide__blurb slide__blurb--large">{this.props.image.desc2}</p>
                            <p>
                            {this.props.image.externalLink 
                            ? <a href={this.props.image.link}>
                                <Button className="slide__btn btn large" onClick={() => this._findOutMore()}>
                                    <span>{this.props.image.btnTxt}</span>
                                </Button>
                            </a>
                            :<Link to={this.props.image.link}>
                                <Button className="slide__btn btn large" onClick={() => this._findOutMore()}>
                                <span>{this.props.image.btnTxt}</span>
                                </Button>
                            </Link>}
                            </p>
                            {this.props.image.disclaimer}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Image;