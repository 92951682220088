/**
 * Component for Smartshares News Press Release section in About page.
 */

/**
 * Import dependencies
 */
 import React, { Component } from 'react';
 import { Container, Row, Col } from 'react-bootstrap';
 import { Link as ScrollTo } from 'react-scroll';
 import { Link } from 'react-router-dom';
 
 class News extends Component {
     constructor(props) {
         super(props);
         this.state = { 
            files: [
                {
                    label: 'Jun 18 2024 - Catherine Pollock - GM, Business Development & Distribution',
                    fileName: 'smartshares-new-gm-business-development-distribution',
                    type: 'pdf'
                },
                {
                    label: "22 February 2024 - We're making futures smarter, together",
                    fileName: 'cinsf-and-smartshares-joint-release-statement',
                    type: 'link'
                },
                {
                    label: 'Sep 23 2022 - The Cook Islands National Superannuation Fund partners with Smartshares to accelerate implementation of its global investment portfolio',
                    fileName: 'cinsf-and-smartshares-joint-release-statement',
                    type: 'pdf'
                },
                {
                    label: 'Nov 11 2021 - NZX/Smartshares Limited acquires management rights of the ASB Superannuation Master Trust',
                    fileName: 'asb-superannuation-master-trust-acquisition',
                    type: 'pdf'
                }
            ]
            }
     }
     render() {
         return (
             <div>
                 <Container>
                     <hr></hr>
                     <div className="smartshares">
                     <Row>
                         <ScrollTo className="anchor has-sub-nav" name="#news"></ScrollTo>
                         <Col md="12" className="text-center">
                             <h2 className="big">News</h2>
                             <Row className="display-inline-flex space-bottom">
                                 <Col sm="12">
                                    <h5 class="upper dark-blue"><b>Press Release</b></h5>
                                     {this.state.files.map((file, index) => 
                                       <p key={index}>
                                            {file.type === 'link'?
                                            (<Link to="/about-smartshares/news">
                                                {file.label}
                                            </Link>):
                                           (<Link to={`document-library/press-release/${file.fileName}.pdf`}
                                              target="_blank" className="pdf">
                                              {file.label}
                                           </Link>)}
                                        </p>)}

                                </Col>      
                             </Row>
                         </Col>
                     </Row>
                     </div>
                 </Container>
             </div>
                 
         )
     }
 }
 
 export default News;