/**
 * Component for Lower Fees Section in Benefits Page
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {Link as ScrollTo} from 'react-scroll';

class LowerFees extends Component {
    constructor(props){
        super(props);
        this.state = {loading:true};
    }

    render() {
        return (
            <div className="hero-component hero-component--lean">
                <ScrollTo name="#lower-fees" className="anchor has-sub-nav"></ScrollTo>
                <div className="slide" style={{backgroundImage: "url('../images/bg-lower-fees.jpg')"}}>
                    <Container>
                        <Row>
                            <Col md="8" className="col-md-push-2">
                                <img src="../images/icon-money-lge.png"></img>
                                <h3 className="h1 slide__heading slide__heading--large light-blue">Lower Costs</h3>
                                <div><b>The funds keep costs down because for funds that track indices we do not need to make active investment decisions, which may require spending on research and analytical expertise.</b></div>
                                <p>&nbsp;</p>
                                <Row>
                                    <Col md="10" className="col-md-push-1">
                                        <Col className="while single">
                                            <h3 className="slide__heading slide__heading--large light-blue">Key Benefits</h3>
                                            <p>Simple fee structure with no performance based fees.</p>
                                            <p>Option to sign up for our regular savings plan.</p>
                                            <p>Generally lower fees than other New Zealand managed funds.</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default LowerFees;