/**
 * Component for Returns page.
*/
/**
 * Import dependencies
 */
import React, {Component} from 'react';

/**Import components and css */
import Fund_Investor_Report_Table from './Fund_Investor_Report_Table';
import Disclaimer from './Disclaimer';
import Headings from './Headings';
import '../css/Returns.css';

class Returns extends Component {
    constructor(props){
        super(props);
        this.state = {loading: true}
    }
    
    static getDerivedStateFromProps(props, state) {
        const hashID = props.history.location.hash;
        return {
            hashID: hashID
        }
    }

    componentDidMount() {
        this.state.hahsID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    componentDidUpdate() {
        this.state.hahsID ? document.getElementsByName(this.state.hashID).item(0).scrollIntoView() : window.scroll(0,0);
    }

    render(){
        let asAtDate = this.props.stocks.length !==0  ? new Date(this.props.stocks[0].date) : new Date();
        // asAtDate = moment(asAtDate).format('DD/MM/YYYY')
        if(this.props.stocks.length === 0)
            return (
                <div>Loading Returns</div>
            )
        return(
            <div className="returns-position">
                <Headings asAtDate={asAtDate} {...this.props}></Headings>
                <Fund_Investor_Report_Table {...this.props}></Fund_Investor_Report_Table>
                {/* <Disclaimer></Disclaimer> -- Commented out following GTA 91007 */}
            </div>
        )
    }
}

export default Returns;