import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {StrictMode, useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk'
import {Provider} from 'react-redux';
import rootReducer from './Redux/reducer';
import { BrowserRouter} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ErrorBoundary from './Components/ErrorBoundary';
import ReactGA from 'react-ga';


// const tagManagerArgs0 = {
//   gtmId: 'GTM-MHFL4Z',//Existing Website's GTM-ID
// }

const tagManagerArgs1 = {
  gtmId: 'GTM-N3QXFX4' //New Website's GTM-ID
}

// TagManager.initialize(tagManagerArgs0);
TagManager.initialize(tagManagerArgs1)
ReactGA.initialize('UA-45866403-16')

//  ReactGA.initialize(
//    [
//      {
//        trackingId: 'UA-192923207-1',
//        gaOptions: {
//          name: 'SMSTracker'
//        }
//      },
    //  {
    //    trackingId: 'UA-45866403-16',
    //    gaOptions: {
    //      name: 'DDBTracker'
    //    }
    //  }
//    ]
//  );


const store = createStore(rootReducer, 
  window._REDUX_DEVTOOLS_EXTENSION_ && window.REDUX_DEVTOOLS_EXTENSION_(),
  applyMiddleware(thunk));

ReactDOM.render(
  <ErrorBoundary>
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter ><App></App></BrowserRouter>
    </Provider>
    </StrictMode>
    </ErrorBoundary>,
  document.getElementById('App')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
