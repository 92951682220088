/**
 * Component for Statement of Investment Policy 
 * in Legal Documents page.
 */

/**
 * Import dependencies 
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';


class Statement_Investment_Policy extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        return (
            <div className="wrapper">
                <ScrollTo name="#statement-investment-policy" className="anchor has-sub-nav"></ScrollTo>
                <h2 className="heading light-blue upper">
                    Statements of investment 
                    <br className="hidden-xs"></br>
                    Policy and Objectives
                </h2>
                <hr></hr>
                <div id="SOI" className="link-wrapper">
                <p>
                        <Link to="document-library/investment-policy-objectives/smartshares-responsible-investment-policy4.pdf"
                            target="_blank" className="pdf">Smartshares Responsible Investment Policy
                            <span>(342kb)</span>
                            </Link>
                    </p>
                    <p>
                        <Link to="document-library/investment-policy-objectives/smartshares-exchange-traded-funds-statement-of-investment-policy-and-objectives3.pdf"
                            target="_blank" className="pdf">Statement of Investment Policy and Objectives - Smartshares Exchange Traded Funds
                            <span>(1391kb)</span>
                            </Link>
                    </p>
                    <p>
                        <Link to="document-library/investment-policy-objectives/nz-core-equity-trust-statement-of-investment-policy-and-objectives.pdf"
                            target="_blank" className="pdf">Statement of Investment Policy and Objectives - NZ Core Equity Trust
                            <span>(1026kb)</span>
                            </Link>
                    </p>
                </div>
            </div>
        )
    }
}

export default Statement_Investment_Policy;