/**
 * Component for New Zealand Shares in 
 * Types of Funds and LEgal Documents Page.
 */

/**
 * Import dependencies.
 */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
//import fs from 'fs';
/**
 * Import components and css.
 */
import * as smartshares from './_smartshares';


class NZShares extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true ,
            files: [
                {
                    code: 'FNZ',
                    label: 'NZ Top 50 ',
                    fileName: 'nz_top_50',
                    url: 'fnz'
                },
                {
                    code: 'TNZ',
                    label: 'NZ Top 10 ',
                    fileName: 'nz_top_10',
                    url: 'tnz'
                },
                {
                    code: 'MDZ',
                    label: 'NZ Mid Cap ',
                    fileName: 'nz_mid_cap',
                    url:'mdz'
                },
                {
                    code: 'DIV',
                    label: 'NZ Dividend ',
                    fileName: 'nz_dividend',
                    url:'nz-dividend'
                },
                {
                    code: 'NZG',
                    label: 'S&P/NZX 50 ',
                    fileName: 's&p_nzx_50',
                    url: 'nzg'
                },
            ],
            fileAttributes : {
                shares: "NZ",
                folder: "/document-library/fund-updates/nz-shares",
                filePrefix:"smartshares_exchange_traded_funds_fund_update_smartshares",
                fileSuffix:"_etf.pdf"
            },
            existingFiles: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        const existingFiles = props.nz_existingFiles;

        return {
            existingFiles: existingFiles
        }
    }

    componentDidMount(){
        this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userSelectedYear !== this.props.userSelectedYear || prevProps.userSelectedQuarter !== this.props.userSelectedQuarter)
            this.props.filesExisting(this.state.files, this.state.fileAttributes, this.props.userSelectedYear, this.props.userSelectedQuarter);
    }

    _setEtfCode(etfCode) {
        // localStorage.setItem('etfCode', String(etfCode).toLowerCase());
        // this.props.setEtfCode(String(etfCode).toLowerCase());
        smartshares._setEtfCode(etfCode);
    }

    render() {
        // Displays on Type of Funds Screen
        if(new String(this.props.history.location.pathname).includes("types-of-funds"))
        return (
            <Container>
                <Row className="space-bottom">
                    <Col md="5" className="text-center space-bottom">
                        <img src="/images/types-photo-large.jpg"></img>
                    </Col>
                    <Col md="7" className="text-center new-zealand-shares">
                        <ScrollTo name="#new-zealand-shares" className="anchor"></ScrollTo>
                        <img src="/images/icon-smart-large-lge.png"></img>
                        <h3 className="h1 brand-title-component">
                            <span className="brand-title-component__thick dark-blue">NEW ZEALAND SHARES</span>
                        </h3>
                        <p>Our New Zealand Shares ETFs provide a comprehensive range of low cost funds investing into companies listed in New Zealand.</p>
                        <p>&nbsp;</p>
                        <h3 className="dark-blue">
                            <b>THERE ARE FIVE FUNDS TO CHOOSE FROM:</b>
                        </h3>
                        <p>&nbsp;</p>
                        <Row>
                            {
                                this.state.files.map(file => 
                                    <Col key={file.code} sm="6">
                                        <p className="fund-movement text-left">
                                            <span className="country nz"></span>
                                            <Link className="background-arrow" to={`/types-of-funds/new-zealand-shares/${String(file.url).toLowerCase()}`} onClick={e => this._setEtfCode(file.code)}>
                                                <b>{`${String(file.label).toUpperCase()}ETF (${file.code})`}</b>
                                            </Link>
                                        </p>
                                    </Col>
                                )
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        )

        // Displays on Legal Documents Screen
        else if (new String(this.props.history.location.pathname).includes("legal-documents"))
            return (
                <div id="qs-2" className="grid-item" style={{display: this.state.existingFiles.length === 0 ? 'none' : 'block'}}>
                    <h3 className="brand-title-component" style={{display: "block"}}>
                        <span className="brand-title-component__thick dark-blue">New Zealand shares</span>
                    </h3> 
                    {
                        this.state.existingFiles.map((file, index) => 
                            <p key={index}>
                                <Link to={`document-library/fund-updates/nz-shares/${this.props.userSelectedYear}/Q${this.props.userSelectedQuarter}/smartshares_exchange_traded_funds_fund_update_smartshares_${file.file.fileName}_etf.pdf`}
                                    target="_blank" className="pdf">
                                    {`${smartshares._setLabelPrefixSuffix(this.props).labelPrefix && smartshares._setLabelPrefixSuffix(this.props).labelPrefix} 
                                    ${file.file.label} ${smartshares._setLabelPrefixSuffix(this.props).labelSuffix} - ${this.props.documentsDate}`}
                                    <span>({Number(file.fileSize.value).toFixed(0)}{file.fileSize.unit})</span>    
                                </Link>
                            </p>
                        )
                    }
                </div>
            )
            
        //Other Screens
        else return (<div>New Zealand Shares</div>)
    }
}

export default NZShares;