import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class PrizeDrawTC extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true}
    }

    render() {
        return (
            <div className="about-position">
            <Container>
                <Col md="12" >
                <h1 className="dark-blue text-center">Customer Survey Prize Draw Terms & Conditions</h1>
                <ol>
                    <h2>Entry</h2>
                    <Row>
                    <li>
                        By entering this Prize-Draw, entrants agree to be bound by these terms and conditions and 
                        acknowledge the Smartshares Privacy Policy <a href="https://smartshares.co.nz/privacy-policy">
                        https://smartshares.co.nz/privacy-policy</a>. 
                        Information on how to enter this Prize-Draw forms part of these terms and conditions.
                    </li>
                    </Row>
                    <Row>
                    <li>
                        Only Eligible Persons can enter this Prize-Draw. To be an Eligible Person, you must have 
                        completed our Customer Survey.
                    </li>
                    </Row>
                    <Row>
                    <li>
                        Staff of Smartshares Limited and their immediate families are not eligible to enter this Prize-Draw.
                    </li>
                    </Row>


                    <p>&nbsp;</p>
                    <h2>The Prize Draw</h2>
                    <Row>
                    <li>
                    There will be one draw in the Prize-Draw. The winner of the Prize-Draw will be drawn at random 
                    from a pool of all Eligible Persons on 30 November 2023 (<b>Draw Date</b>).
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The first Eligible Person whose name is drawn will be the Prize Winner.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The prize will be one $1,000 Prezzy® Card (<b>Prize</b>).
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The Prize Winner will be contacted by Smartshares using the email address that Smartshares has for the Prize Winner. 
                    </li>
                    </Row>
                    <Row>
                    <li>
                    If Smartshares is unable to contact the Prize Winner after a period of four weeks following the Draw Date, having 
                    made reasonable efforts to do so, that person’s entry will be declared invalid and the Prize will be re-drawn.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The Prize Winner agrees to publication of their name, as the Prize Winner.
                    </li>
                    </Row>

                    <p>&nbsp;</p>
                    <h2>General</h2>
                    <Row>
                    <li>
                    Smartshares reserves the right to disqualify any entrant for tampering with the entry process or attempting to act 
                    in a fraudulent or otherwise dishonest manner, and to disqualify any entry in the Prize-Draw in its sole discretion 
                    without giving reasons.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The Prize will be couriered to the Prize Winner within 21 days of the Prize Draw.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    By using the Prezzy Card, the Prize Winner agrees to the terms and conditions, and the card fees set out at <a
                     href="www.prezzycard.co.nz">www.prezzycard.co.nz</a>. The Prize Winner should refer to <a 
                     href="www.prezzycard.co.nz/terms-conditions">www.prezzycard.co.nz/terms-conditions</a> for detailed Prezzy Card 
                    terms and conditions, including, but not limited to, their use and expiry.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    The Prize is not exchangeable or negotiable. However, if the Prize Winner does not wish to claim their Prize, 
                    they may nominate another person to whom their Prize can be transferred. If it is transferred, the Prize must not
                     be sold or used, donated or given away as part of another promotion.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    Smartshares will not be liable for any loss, claim, cost, expense, liability or injury (<b>Loss</b>) suffered by
                     any participant in any way associated with the competition including as a result of entry into the competition 
                     or winning the Prize, except where such Loss cannot be excluded by law.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    These terms and conditions are governed by and construed in accordance with the laws of New Zealand and are subject
                     to the exclusive jurisdiction of New Zealand courts.
                    </li>
                    </Row>
                    <Row>
                    <li>
                    This competition is run by Smartshares Limited NZBN 9429038512483.
                    </li>
                        <hr></hr>
                    </Row>
                </ol>
                </Col>
                </Container>
            </div>
        )
    }
}

export default PrizeDrawTC;