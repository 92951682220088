/**Footer Component */

/**import dependencies */
import React, {Component} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**Import Components and css */
import '../css/Main.css';
import '../css/SubMenu.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }; 
    }
    render() {
        return(
            <div className="footer-component">
                <Container>
                    <Row>
                        <Col sm="4" lg="3">
                            <div className="map">
                                <img src="/images/map1.jpg"></img>
                            </div>
                        </Col>
                        
                        <Col sm="4" lg="3">
                            <div className="contact">
                                <p>
                                    <b className="heading">Smartshares Limited</b>
                                    <br></br>
                                    Level 15
                                    <br></br>
                                    45 Queen Street
                                    <br></br>
                                    PO Box 105 262
                                    <br></br>
                                    Auckland 1143
                                </p>
                                <p>
                                    Phone:
                                    <b>0800 80 87 80</b>
                                </p>
                                <p>
                                    Email: 
                                    <a href="mailto:smartshares@smartshares.co.nz">smartshares@smartshares.co.nz</a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="12">
                            <div className="footer-menu text-center">
                                <p>
                                    <Link to="/legal-documents#product-disclosure-statements">Product Disclosure Statement </Link>|
                                    <Link to="/website-terms-of-use"> Website Terms of Use </Link>|
                                    <Link to="/privacy-policy"> Privacy Policy </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer;