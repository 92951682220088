/**
 * Sub Menu under the header menu in
 * About, Benefits and Invest Pages
 */

/**
 * Import dependencies
 */
import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Import components and css
 */
import '../css/SubMenu.css';
import '../css/Main.css';
import '../css/Printable.css';

class SubMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false};
    }
    render() {
        //For About page
        if(String(this.props.history.location.pathname).includes("about"))
            return (
                <div className="sub-menu">
                    <Container fluid className="container">
                        <Row>
                            <Col><Link className="link_subMenu" to="/about-smartshares#etfs">Smartshares ETFs</Link></Col>
                            <Col><Link className="link_subMenu" to="/about-smartshares#who">Who We Are</Link></Col>
                            <Col><Link className="link_subMenu" to="/about-smartshares#faqs">FAQs and Glossary</Link></Col>
                            <Col><Link className="link_subMenu" to="/about-smartshares#how-to-get-smartshares">How to get Smartshares</Link></Col>
                            <Col><Link className="link_subMenu" to="/about-smartshares#news">News</Link></Col>
                        </Row>
                    </Container>       
                </div>
            )

        //For Benefits page
        else if (String(this.props.history.location.pathname).includes("benefits"))
            return (
                <div className="sub-menu">
                    <Container fluid className="container">
                        <Row>
                            <Col><Link className="link_subMenu" to="/benefits#diversify">Diversification</Link></Col>
                            <Col><Link className="link_subMenu" to="/benefits#lower-fees">Lower Fees</Link></Col>
                            <Col><Link className="link_subMenu" to="/benefits#flexibility">Flexibility</Link></Col>
                            <Col><Link className="link_subMenu" to="/benefits#transparency">Transparency</Link></Col>
                            <Col><Link className="link_subMenu" to="/benefits#sustainable">Sustainable</Link></Col>
                            <Col><Link className="link_subMenu" to="/benefits#risks">Risks</Link></Col>
                        </Row>
                    </Container>       
                </div>  
            )
        
        //For Invest page
        else if(String(this.props.history.location.pathname).includes("invest-now"))
                return (
                    <div className="sub-menu">
                        <Container fluid className="container">
                            <Row>
                                <Col><Link className="link_subMenu" to="/invest-now#apply-online">Apply Online</Link></Col>
                                <Col><Link className="link_subMenu" to="/invest-now#participants">NZX Participants</Link></Col>
                                <Col><Link className="link_subMenu" to="/invest-now#advisor">
                                    Financial Adviser {/**Donna's change */}
                                    {/* Authorised Financial Advisor */}
                                </Link></Col>
                                <Col><Link className="link_subMenu" to="/invest-now#kiwisaver">KiwiSaver</Link></Col>
                            </Row>
                        </Container>       
                    </div>
                )
        else return <div></div>
    }
}

export default SubMenu;