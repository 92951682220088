/**Features Component in HomePage */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
    }

    render() {
        return (
            <Container className="features">
                <div className="col4 text-center">
                    <Col>
                        <img src="/images/icon-diversify.gif"></img>
                        <h3>Diversification</h3>
                        <p>With one simple purchase you get an investment in a range of securities, such as listed companies or government bonds, spreading your risk more broadly.</p>
                    </Col>
                    
                    <Col>
                        <img src="/images/icon-money.gif"></img>
                        <h3>Lower Fees</h3>
                        <p>The funds keep costs down because for funds that track indices we do not need to make active investment decisions, which may require spending on research and analytical expertise.</p>
                    </Col>
                    
                    <Col>
                        <img src="/images/icon-flexibility.gif"></img>
                        <h3>Flexibility</h3>
                        <p>Smartshares units trade like individual shares. From as little as $500 up front and $50 per month, our regular savings plan is a simple and affordable way to accumulate units. </p>
                    </Col>
                    
                    <Col>
                        <img src="/images/icon-cube.gif"></img>
                        <h3>Convenience</h3>
                        <p>The funds enable you to build a global portfolio in NZD, without having to worry about the complexity of managing foreign currencies or overseas tax. Distributions are paid in NZD and automatically reinvested for you, unless you choose to receive them in cash.</p>
                    </Col>
                </div>
            </Container>
        )
    }
}

export default Features;