/**
 * Component for Market Announcements in ETF Page.
 */

/**
 * Import dependencies
 */
import React, { Component } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import moment from 'moment';

/**
 * Import components and css.
 */
import * as smartshares from './_smartshares';

class MarketAnnouncements extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: true ,
            marketAnnouncements: []
        }
    }

    static getDerivedStateFromProps(props, state){
        const marketAnnouncements = props.marketAnnouncements;

        return {
            marketAnnouncements: marketAnnouncements
        }
    }

    _goToMarketAnnouncements(event) {
        smartshares._openExternalLink(event.target.href, event.target.target);
    }

    render() {
        return(
            <Container>
                <Row>
                    <Col md="12">
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="space-bottom">
                        <h3 className="h1 dark-blue upper">Market Announcements</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        {
                            this.state.marketAnnouncements && this.state.marketAnnouncements.length !== 0 ?
                            <div>
                            <Table striped className="announcements">
                                <thead>
                                    <tr>
                                        <th>CODE</th>
                                        <th className="left">TITLE</th>
                                        <th className="left">DATE</th>
                                        <th>TYPE</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        
                                            this.state.marketAnnouncements.map((marketAnnouncements,index) => 
                                                <tr key={index} className={index%2 === 0 ? "odd" : ''}>
                                                    <td><span className="announceCode">{marketAnnouncements.type} {marketAnnouncements.stock}</span></td>
                                                    <td className="left">
                                                        <a href={marketAnnouncements.url}
                                                            target="_blank">{marketAnnouncements.title} </a>
                                                    </td>
                                                    <td className="left">{moment(marketAnnouncements.asAtDate).format('DD MMM YYYY, hh:mm a')}</td>
                                                    <td>{marketAnnouncements.type}</td>
                                            </tr>
                                            )
                                    }
                                    {/* <tr className="odd">
                                        <td><span className="announceCode">MKTUPDTE NZG</span></td>
                                        <td className="left">
                                            <Link to="https://www.nzx.com/companies/NZG/announcements/364711" 
                                            target="_blank">MKTUPDTE: NZG: NZG NTA 08-12-2020 $2.74970</Link>
                                        </td>
                                        <td className="left">09 Dec 2020, 9:55am</td>
                                        <td>MKTUPDTE</td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </div> : <div className="sub-heading"><p>There are no Market Announcements for this fund yet.</p></div>
                        }
                        
                        {/* <hr></hr> */}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MarketAnnouncements;